<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9999 12.6662H17.9999V14.7774H15.9999V12.6662ZM15.9999 8.444H17.9999V10.5552H15.9999V8.444ZM15.9999 4.22182H17.9999V6.333H15.9999V4.22182ZM0 12.6665H2.00006V14.7776H0V12.6665ZM0 8.44427H2.00006V10.5555H0V8.44427ZM0 4.22209H2.00006V6.33327H0V4.22209ZM15.9999 16.8888H17.9999V19H15.9999V16.8888ZM11.9999 16.8888H14V19H11.9999V16.8888ZM7.99997 16.8888H10V19H7.99997V16.8888ZM4.00003 16.8888H6.00009V19H4.00003V16.8888ZM8.56672e-05 16.8888H2.00014V19H8.56672e-05V16.8888ZM15.9999 0H18V2.11118H15.9999V0ZM12 0H14.0001V2.11118H12V0ZM8.00006 0H10.0001V2.11118H8.00006V0ZM4.00011 0H6.00017V2.11118H4.00011V0ZM0.000171334 0H2.00023V2.11118H0.000171334V0Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#484848',
    },
  },
}
</script>