<template>
  <div>
    <h3 class="font-main text-base" :class="color">
      {{ title }}
    </h3>
    <p class="font-main text-xs text-promy-primary mt-2">
      {{ content }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      String,
    },
    content: {
      String,
    },
    type: {
      String,
    },
  },
  computed: {
    color() {
      //The below code is not working.
      // return `text-promy-actions-${type}`;
      switch (this.type) {
        case 'success':
          return `text-promy-actions-success`
        case 'warning':
          return `text-promy-actions-warning`
        case 'error':
          return `text-promy-actions-danger`
        default:
          return `text-promy-actions-info`
      }
    },
  },
}
</script>

<style scoped>
div {
  color: black;
}
</style>
