<template>
  <validation-provider
    class="flex flex-col space-y-[6px] w-full relative"
    :rules="rules"
    v-slot="{ errors }"
  >
    <label
      v-if="label"
      :for="uniqueId"
      class="flex justify-between font-medium text-promy-gray-200"
    >
      {{ label }} <span v-if="label_value">{{ label_value }}</span>
    </label>
    <div class="relative">
      <div class="absolute inset-y-0 right-0 z-10" v-if="unit">
        <span
          class="h-full rounded-r-full flex items-center bg-promy-gray-background-500 w-10 justify-center font-bold text-promy-gray-400 text-sm"
        >
          {{ unit }}
        </span>
      </div>
      <input
        @click="showRule = true"
        v-on-clickaway="away"
        :id="uniqueId"
        :dir="dir"
        :name="name ? name : label"
        :type="type"
        :placeholder="placeholder ? placeholder : label"
        :value="value"
        @input="handleInput($event.target.value)"
        :disabled="disabled"
        :class="[
          errors[0] ? 'input-error' : '',
          !clearBG ? 'bg-promy-gray-background-300' : 'bg-white',
          unit != null ? 'padding-unit' : '',
          classes,
        ]"
        autocomplete="off"
      />
      <span
        class="absolute top-2 right-3 bg-white pl-1 cursor-pointer"
        v-if="withPrixUnit"
      >
        €
      </span>
      <span v-if="withShowPasswordIcon">
        <img
          :src="[
            type === 'password'
              ? '/images/icons/eye-off-outline.svg'
              : '/images/icons/eye-outline.svg',
          ]"
          class="absolute top-2 right-3 pl-1 cursor-pointer"
          @click="showPassword"
        />
      </span>
    </div>
    <span v-show="errors[0]" class="msg-error">{{ errors[0] }}</span>
    <div
      v-if="type === 'password' && with_rule && showRule && !password_is_valid"
      class="absolute w-full z-20 mt-1 rounded-md border bg-promy-gray-100 px-2 py-3 text-xs top-full"
    >
      <p>
        Votre mot de passe doit contenir 8 caractères 1 minuscule 1 majuscule 1
        nombre et 1 caractère spécial
      </p>
      <div class="mt-1">
        <div class="mt-1">
          <i
            class="fas fa-check-circle"
            :class="
              errorsPassword.eightcharacter
                ? 'text-promy-gray-300'
                : 'text-promy-green-200'
            "
          ></i>
          8 caractères
        </div>
        <div class="mt-1">
          <i
            class="fas fa-check-circle"
            :class="
              errorsPassword.uppercase
                ? 'text-promy-gray-300'
                : 'text-promy-green-200'
            "
          ></i>
          Majuscule
        </div>
        <div class="mt-1">
          <i
            class="fas fa-check-circle"
            :class="
              errorsPassword.lowercase
                ? 'text-promy-gray-300'
                : 'text-promy-green-200'
            "
          ></i>
          Minuscule
        </div>
        <div class="mt-1">
          <i
            class="fas fa-check-circle"
            :class="
              errorsPassword.number
                ? 'text-promy-gray-300'
                : 'text-promy-green-200'
            "
          ></i>
          Nombre
        </div>
        <div class="mt-1">
          <i
            class="fas fa-check-circle"
            :class="
              errorsPassword.specialcase
                ? 'text-promy-gray-300'
                : 'text-promy-green-200'
            "
          ></i>
          Caractères non alphanumériques
        </div>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    with_rule: {
      type: Boolean,
      default: false,
    },
    dir: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: 'py-2',
    },
    label: {
      type: String,
      default: null,
    },
    label_value: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    rules: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    type: {
      type: [String, Number],
      default: 'text',
    },
    unit: {
      type: String,
      default: null,
    },
    clearBG: {
      type: Boolean,
      default: false,
    },
    withShowPasswordIcon: {
      type: Boolean,
      default: false,
    },
    withPrixUnit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showRule: false,
      errorsPassword: {
        uppercase: true,
        lowercase: true,
        specialcase: true,
        number: true,
        eightcharacter: true,
      },
      password_is_valid: false,
    }
  },
  computed: {
    uniqueId() {
      return `input-${this._uid}`
    },
  },
  methods: {
    handleInput(value) {
      if (this.type === 'password') {
        this.inputValidation(value)
        this.shouldSubmitForm()
      }
      this.$emit('input', value)
    },
    away() {
      this.showRule = false
    },
    shouldSubmitForm() {
      this.password_is_valid = Object.values(this.errorsPassword).every(
        (value) => value === false,
      )
      this.$emit('passwordInputIsValidated', this.password_is_valid)
    },
    inputValidation(value) {
      this.errorsPassword.uppercase = /[A-Z]/.test(value) ? false : true
      this.errorsPassword.lowercase = /[a-z]/.test(value) ? false : true
      this.errorsPassword.number = /\d/.test(value) ? false : true
      this.errorsPassword.specialcase = /[#?!@$%^&*-]/.test(value)
        ? false
        : true
      this.errorsPassword.eightcharacter = value.length >= 8 ? false : true
    },
    showPassword() {
      this.$emit('updateType', this.type === 'password' ? 'text' : 'password')
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  @apply rounded-lg px-4 text-promy-primary border-solid border w-full border-promy-gray-150;
}
input:hover {
  @apply border-promy-gray-200;
}
input:focus {
  @apply outline-none bg-white border-promy-green-350;
}
input[disabled] {
  @apply border-promy-gray-100 bg-promy-gray-100;
}
.msg-error {
  @apply rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2;
}
.input-error {
  @apply border-promy-actions-danger;
}
.padding-unit {
  padding-right: 2.8rem !important;
}
</style>
