<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M5.26599 9.765C5.73387 8.34945 6.63685 7.11781 7.84611 6.2458C9.05536 5.37378 10.5091 4.90594 12 4.909C13.69 4.909 15.218 5.509 16.418 6.491L19.91 3C17.782 1.145 15.055 0 12 0C7.26999 0 3.19799 2.698 1.23999 6.65L5.26599 9.765Z"
        :fill="color ? color : '#EA4335'"
      />
      <path
        d="M16.0401 18.0129C14.9501 18.7159 13.5661 19.0909 12.0001 19.0909C10.5151 19.094 9.06686 18.6299 7.8603 17.7643C6.65373 16.8987 5.75 15.6755 5.27706 14.2679L1.23706 17.3349C2.22831 19.3412 3.76233 21.0293 5.66488 22.2075C7.56744 23.3857 9.76227 24.0066 12.0001 23.9999C14.9331 23.9999 17.7351 22.9569 19.8341 20.9999L16.0411 18.0129H16.0401Z"
        :fill="color ? color : '#34A853'"
      />
      <path
        d="M19.834 21C22.029 18.952 23.454 15.904 23.454 12C23.454 11.29 23.345 10.527 23.182 9.81799H12V14.455H18.436C18.119 16.014 17.266 17.221 16.041 18.013L19.834 21Z"
        :fill="color ? color : '#4A90E2'"
      />
      <path
        d="M5.27712 14.268C5.03247 13.5369 4.90819 12.771 4.90912 12C4.90912 11.218 5.03412 10.467 5.26612 9.76502L1.24012 6.65002C0.416494 8.31271 -0.00807994 10.1445 0.000116455 12C0.000116455 13.92 0.445116 15.73 1.23712 17.335L5.27712 14.268Z"
        :fill="color ? color : '#FBBC05'"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" :fill="color ? color : 'white'" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
    },
  },
}
</script>
