<template>
  <div
    class="
      w-max
      rounded-lg
      bg-white
      absolute
      px-5
      py-4
      transition-all
      bottom-[122%]
      duration-500
      drop-shadow-2xl
    "
  >
    <div class="flex justify-between items-center">
      <p class="font-main font-bold">Fonds de carte</p>
      <div
        class="
          bg-[#F4F4F4]
          rounded-lg
          w-7
          h-7
          flex
          justify-center
          items-center
          cursor-pointer
        "
      >
        <CloseIcon @click.native="close" color="#10AAAF" class="w-6 h-6" />
      </div>
    </div>
    <div
      class="
        flex
        items-center
        pt-6
        font-main font-medium
        text-xs
        sm:grid sm:grid-cols-2
      "
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style></style>
