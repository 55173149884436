<template>
  <div>
    <svg
      width="33"
      height="22"
      viewBox="0 0 33 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2H31"
        :stroke="color"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M2 11H31"
        :stroke="color"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M2 20H31"
        :stroke="color"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#10AAAF',
    },
  },
}
</script>

<style></style>
