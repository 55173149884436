<template>
  <div class="relative w-12 h-12 rounded-full" v-click-outside="closeModal">
    <transition name="slide-fade" mode="out-in">
      <MapTypeModal v-if="open" @close="closeModal">
        <div v-for="layer in local_layers" :key="layer.label">
          <img
            @click="changeType(layer)"
            :src="`/images/MapType/${layer.image}`"
            class="mx-4 rounded-lg border-3 cursor-pointer sm:mx-2"
            :class="[
              layer.is_active
                ? 'border-promy-green-350'
                : 'border-promy-gray-100 hover:border-promy-gray-150',
            ]"
          />
          <p
            class="text-center py-2"
            :class="[
              layer.is_active ? 'text-promy-green-350' : 'text-promy-blue-300',
            ]"
          >
            {{ layer.label }}
          </p>
        </div>
        <div v-for="(layer, idx) in layers" :key="idx">
          <component
            :is="layer.component"
            :is_active="layer.is_active"
            :data="layer.data"
          >
            <img
              @click="layer.is_active = !layer.is_active"
              :src="`/images/MapType/${layer.image}`"
              class="mx-4 rounded-lg border-3 cursor-pointer sm:mx-2"
              :class="[
                layer.is_active
                  ? 'border-promy-green-350'
                  : 'border-promy-gray-100 hover:border-promy-gray-150',
              ]"
            />
            <p
              class="text-center py-2"
              :class="[
                layer.is_active ? 'text-promy-green-350' : 'text-promy-blue-300',
              ]"
            >
              {{ layer.label }}
            </p>
          </component>
        </div>
      </MapTypeModal>
    </transition>
    <div
      @mouseover="hovering = true"
      @mouseleave="hovering = false"
      @click="open = !open"
      class="bg-white w-full h-full flex justify-center items-center rounded-full cursor-pointer hover:bg-promy-green-350"
      :class="{ 'border-2 border-promy-green-350': open }"
    >
      <LayersIcon :color="hovering ? 'white' : '#10AAAF'" />
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapGetters } from 'vuex'
import Mapbox from '@/mixins/map/mapbox'
import switchDimension from '@/pages/SectionsGU/Batiment/switchDimension'
export default {
  mixins: [Mapbox],
  components: {
    switchDimension,
  },
  directives: {
    ClickOutside,
  },

  props: {
    layers: {
      type: Array,
      default: () => [],
    },
    data_google_map: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      map: 'map/map',
      active_layer: 'map/active_layer',
    }),
  },

  data() {
    return {
      hovering: false,
      open: false,
      type_map: 'default',
      local_layers: [
        {
          label: 'Plan',
          image: 'Default.svg',
          is_active: false,
          name: 'streets-v11',
        },
        {
          label: 'Satellite',
          image: 'Satellite.svg',
          is_active: true,
          name: 'satellite-streets-v11',
        },
      ],
      savedLayers: [],
      savedSources: [],
    }
  },
  created() {
    this.local_layers.forEach((layer) => {
      layer.is_active = layer.name == this.active_layer
    })
  },
  methods: {
    closeModal() {
      this.open = false
    },

    changeType(layer) {
      if (!layer.is_active) {
        this.local_layers.map((el) => {
          el.is_active = false
        })
        layer.is_active = true
        this.$store.commit('map/ACTIVE_LAYER', layer.name)
        if (this.data_google_map) {
          let layer_map =
            layer.name === 'satellite-streets-v11'
              ? this.data_google_map.google.maps.MapTypeId.HYBRID
              : this.data_google_map.google.maps.MapTypeId.ROADMAP
          this.data_google_map.google_map.setMapTypeId(layer_map) // ROADMAP HYBRID
        } else {
          this.switchStyle()
        }
      }
    },

    switchStyle() {
      this.savedSources = []
      this.savedLayers = []
      this.getAllLayers().forEach((layerGroup) => {
        this.forEachLayer(layerGroup, (layer) => {
          this.savedSources[layer.source] = this.map
            .getSource(layer.source)
            .serialize()
          this.savedLayers.push(layer)
        })
      })
      this.map.on('style.load', this.preserveLayers)
      this.map.setStyle(
        `mapbox://styles/mapbox/${
          this.local_layers.find((layer) => layer.is_active).name
        }?optimize=true`,
      )
    },
    preserveLayers() {
      Object.entries(this.savedSources).forEach(([id, source]) => {
        if (!this.isExist(this.map.getSource(id)))
          this.map.addSource(id, source)
      })
      this.savedLayers.forEach((layer) => {
        if (!this.isExist(this.map.getLayer(layer.id))) this.map.addLayer(layer)
      })
      this.map.off('style.load', this.preserveLayers)
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.off('style.load', this.preserveLayers)
    }
  },
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
