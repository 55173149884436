<template>
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9366 15.6896L19.014 16.6744V2.31185L19.9366 3.29671C20.0648 3.43354 20.2185 3.48831 20.3722 3.48831C20.526 3.48831 20.7054 3.43354 20.8079 3.29671C21.0642 3.02306 21.0642 2.61278 20.8079 2.33911L18.8089 0.205238C18.5526 -0.0684128 18.1683 -0.0684128 17.9119 0.205238L15.913 2.33911C15.6566 2.61276 15.6566 3.02304 15.913 3.29671C16.1693 3.57036 16.5536 3.57036 16.81 3.29671L17.7326 2.31185V16.7018L16.81 15.7169C16.5537 15.4433 16.1693 15.4433 15.913 15.7169C15.6566 15.9906 15.6566 16.4009 15.913 16.6745L17.9119 18.8084C18.0401 18.9452 18.1938 19 18.3475 19C18.5013 19 18.6807 18.9452 18.7832 18.8084L20.7821 16.6745C21.0385 16.4009 21.0385 15.9906 20.7821 15.7169C20.5772 15.4435 20.1927 15.4435 19.9365 15.6896L19.9366 15.6896Z"
      :fill="color"
    />
    <path
      d="M13.4559 0H0.523169C0.230202 0 0 0.312417 0 0.710015V18.29C0 18.6876 0.230202 19 0.523169 19H13.4768C13.7698 19 14 18.6876 14 18.29V0.710015C13.979 0.312417 13.7488 0 13.4558 0H13.4559ZM12.9537 17.58H1.02549V1.39163H12.9328V17.58H12.9537Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#484848',
    },
  },
}
</script>