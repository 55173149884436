const getDefaultState = () => {
  return {
    markers_pc: [],
    markers_dvf: [],
    checked_pc: true,
    checked_dvf: true,
    activated_accordion: null,
    activated_accordion_idx: null,
  }
}
export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    get_markers_pc(state) {
      return state.markers_pc
    },
    get_markers_dvf(state) {
      return state.markers_dvf
    },
    get_activated_accordion(state) {
      return state.activated_accordion
    },
    get_checked_dvf(state) {
      return state.checked_dvf
    },
    get_checked_pc(state) {
      return state.checked_pc
    },
    get_activated_accordion_idx: (state) => state.activated_accordion_idx,
  },
  mutations: {
    SET_MARKERS_PC(state, payload) {
      state.markers_pc.push(payload)
    },
    SET_MARKERS_DVF(state, payload) {
      state.markers_dvf.push(payload)
    },
    SET_ACTIVATED_ACCORDION(state, payload) {
      state.activated_accordion = payload
    },
    SET_CHECKED_PC(state, payload) {
      state.checked_pc = payload
    },
    SET_CHECKED_DVF(state, payload) {
      state.checked_dvf = payload
    },
    SET_ACTIVATED_ACCORDION_IDX(state, payload) {
      state.activated_accordion_idx = payload
    },
  },
  actions: {},
}
