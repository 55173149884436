<template>
  <div>
    <div class="relative" v-click-outside="closeDropdown">
      <span class="inline-block w-full rounded-lg shadow-sm">
        <button
          type="button"
          @click.stop="isOpen ? closeDropdown() : openDropdown()"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          class="
            cursor-pointer
            relative
            w-64
            h-16
            rounded-lg
            border border-promy-gray-300
            pl-4
            sm:pl-2 sm:pr-2
            pr-10
            text-left
            focus:outline-none focus:shadow-outline-blue
            hover:border-promy-gray-200
            focus:border-promy-green-350
            transition
            ease-in-out
            duration-150
            sm:text-sm sm:leading-5
          "
          style="min-height: 36px"
        >
          <div class="flex items-center space-x-3">
            <span
              class="block"
              :class="[isOpen ? 'text-promy-green-350' : 'text-gray-600']"
            >
              {{ getNestedObject(value) }}
            </span>
          </div>
          <div
            class="absolute inset-y-0 right-0 flex items-center pr-4 space-x-4"
          >
            <span class="pointer-events-none">
              <ProIcon
                :name="isOpen ? 'uparrow' : 'downarrow'"
                :color="isOpen ? '#10AAAF' : 'black'"
              />
            </span>
          </div>
        </button>
      </span>

      <!-- Select popover, show/hide based on select state. -->
      <div
        v-show="isOpen"
        class="absolute w-64 pt-2"
        :class="is_top ? 'is-top' : ''"
      >
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          class="
            bg-white
            w-full
            max-h-56
            text-xs text-promy-primary
            leading-6
            overflow-auto
            focus:outline-none
            sm:text-sm sm:leading-5
            rounded-lg
            shadow
          "
        >
          <!--
            Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
          -->
          <li
            tabindex="0"
            @click.stop="select(item)"
            id="listbox-item-0"
            role="option"
            v-for="(item, index) in data"
            :key="index"
            class="
              cursor-pointer
              select-none
              relative
              py-3
              px-6
              focus:outline-none
              hover:bg-gray-50
            "
            :class="isSelected(item) ? 'bg-promy-gray-100' : ''"
          >
            <div class="flex items-center space-x-3">
              <span class="block">
                {{ getNestedObject(item) }}
              </span>
            </div>
          </li>

          <!-- More options... -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
  name: 'VueSelect',
  props: {
    data: Array,
    value: [Object, String],
    args: {
      type: Array,
      default: function () {
        return []
      },
    },
    is_top: {
      type: Boolean,
      default: false,
    },
    is_object: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    isSelected(value) {
      return this.value === this.getNestedObject(value)
    },
    closeDropdown() {
      this.isOpen = false
    },
    openDropdown() {
      this.isOpen = true
    },
    select(value) {
      this.isOpen = false
      let value_selected = this.is_object ? this.getNestedObject(value) : value
      this.$emit('valueSelect', value_selected)
    },
    getNestedObject(obj) {
      return this.args.reduce((obj, level) => obj && obj[level], obj)
    },
  },
  directives: {
    ClickOutside,
  },
}
</script>
<style lang="scss" scoped>
.is-top {
  bottom: 115%;
}
</style>
