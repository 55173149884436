<template>
  <div
    class="
      flex
      items-center
      justify-center
      lg:sticky
      top-0
      w-full
      z-40
      bg-white
      h-20
      px-10
      md:px-6
      pl-10
    "
    style="transition: 0.3s"
  >
    <BurgerMenuIcon
      @click.native="showSideBar"
      class="cursor-pointer md:mr-auto minimized-sidebar:hidden full-hd:hidden"
    />
    <div
      class="
        flex
        space-x-6
        xl:space-x-2 xl:ml-14
        minimized-sidebar:ml-20
        lg:space-x-2 lg:ml-10
        full-hd:ml-0
        ml-18
        md:hidden
      "
    >
      <TabsButton
        is_hidden_text_into_small_screen
        iconComponent="SearchIcon"
        text="Recherche"
        :active="$route.name === 'adresse'"
        @click="enableButton('adresse')"
      />
      <TabsButton
        is_hidden_text_into_small_screen
        iconComponent="HistoryIcon"
        text="Historique"
        :active="$route.name === 'historique'"
        @click="enableButton('historique')"
      />
    </div>
    <p
      class="font-main font-bold text-2xl lg:text-xl m-auto text-promy-primary"
    >
      <portal-target name="navbar-title"></portal-target>
    </p>
    <div class="flex space-x-6 ml-auto">
      <Contact class="lg:hidden" :with_contact_btn="false" />
      <pro-button
        class="px-6 py-3 border-promy-blue-200 rounded-lg sm:hidden"
        btn="primary"
        @click="revelezPotentiel"
      >
        Révélez le potentiel
      </pro-button>
      <div class="relative">
        <button
          @click="toggleProfileMenu"
          class="p-3 w-full h-full rounded-lg bg-promy-gray-50"
          :class="[
            open
              ? 'bg-promy-green-350'
              : 'bg-promy-gray-50 hover:bg-promy-blue-100',
          ]"
        >
          <UserIcon :color="open ? 'white' : '#10AAAF'" />
        </button>
        <div
          v-on-clickaway="away"
          v-if="open"
          class="
            absolute
            right-0
            z-40
            w-56
            mx-auto
            mt-2
            overflow-hidden
            border
            rounded-lg
            font-main
            text-lg
          "
        >
          <div
            @click="enableMenu('profil')"
            class="px-4 py-2 transition-all duration-100 cursor-pointer"
            :class="
              currentRoute === 'profile'
                ? 'bg-promy-green-350 text-white font-semibold'
                : 'bg-white text-promy-primary hover:bg-promy-gray-50'
            "
          >
            <span v-if="user.nom || user.prenom">
              {{ user.prenom }} {{ user.nom }}
            </span>
            <span v-else>Profil</span>
          </div>
          <div
            @click="enableMenu('revelations')"
            class="px-4 py-2 transition-all duration-100 cursor-pointer"
            :class="
              currentRoute === 'revelations'
                ? 'bg-promy-green-350 text-white font-semibold'
                : 'bg-white text-promy-primary hover:bg-promy-gray-50'
            "
          >
            <span>Mes révélations</span>
          </div>
          <div
            class="
              px-4
              py-2
              transition-all
              duration-100
              cursor-pointer
              bg-white
              text-promy-primary
              hover:bg-promy-gray-50
            "
            @click="logout_handle"
          >
            <span>Déconnexion</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'
import { mapGetters, mapMutations } from 'vuex'

export default {
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    ...mapMutations({
      toggleGuestModal: 'auth/TOGGLE_GUEST_MODAL',
    }),
    enableButton(name) {
      if (name == 'historique') {
        this.$store.commit('auth/SET_GUEST_INTENDED_LOCATION', 'historique')
        this.toggleGuestModal()
      }
      if (name == 'adresse') {
        this.$router.push(`/sections-gu/${name}`)
      }
      this.$router.push(
        `/sections-gu/${name}/${this.$route.params.id_address ?? ''}`,
      )
    },
    revelezPotentiel() {
      /*       if (!this.isLoggedIn) {
        this.$store.commit(
          'auth/SET_GUEST_INTENDED_LOCATION',
          'revelationStep1',
        )
        this.toggleGuestModal()
      }
      this.$store.dispatch('auth/me') */
      this.$router.push({
        name: 'RevelationSteps',
        query: { id_address: this.$route.params.id_address },
      })
    },
    enableMenu(name) {
      this.$router.push('/' + name)
      this.away()
    },
    showSideBar() {
      this.$emit('showSideBar', true)
    },
    toggleProfileMenu() {
      if (!this.isLoggedIn) {
        this.$store.commit('auth/SET_GUEST_INTENDED_LOCATION', 'profile')
        this.toggleGuestModal()
      }
      this.open = !this.open
    },
    away() {
      this.open = false
    },
    async logout_handle() {
      try {
        this.$store.dispatch('auth/logout').then(() => {
          this.$store.commit('auth/SET_USER', {})
          this.$store.commit('auth/CLEAR_USER_INTENDED_ACTIONS')
          this.$store.commit('address/ResetStoreGUAddress')
          this.$router.push('/login')
          this.toast(
            'Déconnexion',
            'Vous vous êtes déconnecté avec succès',
            'success',
          )
        })
      } catch (error) {
        error.response.status === 500 &&
          this.toast(
            'Déconnexion',
            'Erreur lors du traitement de la déconnexion',
            'error',
          )
      }
    },
  },
  computed: {
    ...mapGetters({
      open_sideBar: 'sidebar/activated_sibeBar',
      user: 'auth/user',
      isLoggedIn: 'auth/isLoggedIn',
    }),
    currentRoute() {
      return this.$route.name
    },
  },
}
</script>
