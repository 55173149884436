<template>
  <svg
    width="10"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.05273 1.58979L7.75891 7.29596L2.05273 13.0021"
      :stroke="color"
      stroke-width="2.57143"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#B0BAC4',
    },
  },
}
</script>

<style></style>
