<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8333 15.1667C13.2266 15.1667 15.1667 13.2266 15.1667 10.8333C15.1667 8.4401 13.2266 6.5 10.8333 6.5C8.4401 6.5 6.5 8.4401 6.5 10.8333C6.5 13.2266 8.4401 15.1667 10.8333 15.1667Z"
      :fill="color"
    />
    <path
      d="M11.9167 2.24142V0H9.75V2.24142C7.84366 2.48482 6.07205 3.35419 4.71312 4.71312C3.35419 6.07205 2.48482 7.84366 2.24142 9.75H0V11.9167H2.24142C2.48447 13.8232 3.35371 15.595 4.71271 16.954C6.07171 18.313 7.84352 19.1822 9.75 19.4253V21.6667H11.9167V19.4253C13.8232 19.1824 15.5951 18.3132 16.9542 16.9542C18.3132 15.5951 19.1824 13.8232 19.4252 11.9167H21.6667V9.75H19.4252C19.1822 7.84352 18.313 6.07171 16.954 4.71271C15.595 3.35371 13.8231 2.48447 11.9167 2.24142ZM10.8333 17.3333C7.24858 17.3333 4.33333 14.4181 4.33333 10.8333C4.33333 7.24858 7.24858 4.33333 10.8333 4.33333C14.4181 4.33333 17.3333 7.24858 17.3333 10.8333C17.3333 14.4181 14.4181 17.3333 10.8333 17.3333Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
