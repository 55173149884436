<template>
  <component
    :is="iconComponent"
    role="img"
    class="inline-block"
    :style="{ height, width }"
    :color="color"
  />
</template>

<script>
const icons = {
  // social icons
  google: 'google-icon',
  facebook: 'facebook-icon',
  linkedin: 'linkedin-icon',
  apple: 'apple-icon',
  // general icons
  plus: 'plus-icon',
  minus: 'minus-icon',
  uparrow: 'up-arrow-icon',
  downarrow: 'down-arrow-icon',
  close: 'close-icon',
  user: 'user-icon',
  search: 'search-icon',
  minimize: 'minimize-icon',
  layers: 'layers-icon',
  history: 'history-icon',
  enlarge: 'enlarge-icon',
  // guichet unique icons
  send: 'send-icon',
  download: 'download-icon',
  tools: 'tools-icon',
  location: 'location-icon',
  'street-gentle': 'street-gentle-icon',
  marker: 'marker-icon',
  check: 'check-icon',
  bot1: 'bot-icon-d1',
  bot2: 'bot-icon-d2',
  // commodites
  transport: 'transport-icon',
  education: 'education-icon',
  commerce: 'commerce-icon',
  security: 'security-icon',
  health: 'health-icon',
  // sidebar menu
  parcelle: 'parcelle-icon',
  batiments: 'batiments-icon',
  urbanisme: 'urbanisme-icon',
  'etude-commerciale': 'etude-commerciale-icon',
  commodite: 'commodite-icon',
  'street-view': 'street-view-icon',
  commune: 'commune-icon',
  risques: 'risques-icon',
  reseaux: 'reseaux-icon',
  pollution: 'pollution-icon',
  // risque icons
  arrete: 'arrete-icon',
  avalanche: 'avalanche-icon',
  'dangerous-transport': 'dangerous-transport-icon',
  earthquake: 'earthquake-icon',
  fire: 'fire-icon',
  flood: 'flood-icon',
  'gas-mask': 'gas-mask-icon',
  industrial: 'industrial-icon',
  nuclear: 'nuclear-icon',
  radon: 'radon-icon',
  'rupture-barrage': 'rupture-barrage-icon',
  'terrain-mouvement': 'terrain-mouvement-icon',
  'war-vehicle': 'war-vehicle-icon',
  weather: 'weather-icon',
  // parcelle icons
  'ref-cadastrale': 'ref-cadastrale-icon',
  surface: 'surface-icon',
  'zone-pinel': 'zone-pinel-icon',
  // batiment icons
  'surface-sol': 'surface-sol-icon',
  'perimetre-batiment': 'perimetre-batiment-icon',
  wall: 'wall-icon',
  height: 'height-icon',
  'three-layers': 'three-layers-icon',

  actionsMap: 'actions-map-icon',
  ellipse: 'EllipseIcon',
  calendar: 'CalendarIcon',
  // tunnel icons
  owner: 'owner-icon',
  'co-owner': 'co-owner-icon',
  'other-type': 'other-type-icon',
  proche: 'proche-icon',
  tenant: 'tenant-icon',
  professional: 'professional-icon',
  // popup guest
  login: 'login-icon',
}

export default {
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      },
    },
    hoverColor: {
      type: String,
    },
    color: {
      type: String,
    },
    height: {
      type: String,
      default: '1em',
    },
    width: {
      type: String,
      default: '1em',
    },
  },

  computed: {
    iconComponent() {
      return icons[this.name]
    },
  },
}
</script>
