<template>
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2166 1.88676C14.0798 1.75894 13.9197 1.66031 13.7454 1.5965C13.5712 1.5327 13.3861 1.50497 13.2009 1.5149C13.0157 1.52483 12.8339 1.57223 12.6659 1.65438C12.4979 1.73654 12.3471 1.85184 12.222 1.99371L5.90211 9.149L2.72597 6.24766C2.47127 6.01469 2.13635 5.89615 1.7949 5.91813C1.45345 5.94011 1.13343 6.1008 0.905242 6.36485C0.677057 6.6289 0.559398 6.97468 0.578148 7.32613C0.596899 7.67758 0.750523 8.00591 1.00523 8.23888L5.26269 12.1277C5.39383 12.2474 5.54775 12.3378 5.71482 12.3932C5.8819 12.4485 6.05853 12.4677 6.23368 12.4495C6.41924 12.44 6.60142 12.3928 6.76978 12.3107C6.93813 12.2287 7.08935 12.1133 7.21477 11.9713L14.3054 3.94105C14.5582 3.65425 14.6908 3.27671 14.6741 2.89138C14.6574 2.50605 14.4928 2.14446 14.2164 1.88608"
      class="fill-current"
      :class="'text-' + color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
