<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.32992 0.270872C2.95397 0.0482454 3.62839 0.00732677 4.27478 0.152873C4.92116 0.298419 5.51296 0.624451 5.98136 1.09306C6.44977 1.56168 6.77553 2.15362 6.92079 2.80007C7.06605 3.44652 7.02483 4.12092 6.80192 4.74487L17.6469 15.5899L15.5249 17.7109L4.67992 6.86687C4.05587 7.0895 3.38145 7.13042 2.73506 6.98487C2.08868 6.83932 1.49688 6.51329 1.02848 6.04468C0.560077 5.57607 0.23431 4.98413 0.089053 4.33768C-0.0562041 3.69122 -0.0149841 3.01682 0.207921 2.39287L2.44392 4.62987C2.58229 4.77314 2.74781 4.88741 2.93081 4.96602C3.11382 5.04464 3.31065 5.08602 3.50982 5.08775C3.70899 5.08948 3.90651 5.05153 4.09085 4.9761C4.2752 4.90068 4.44267 4.7893 4.58351 4.64846C4.72435 4.50763 4.83573 4.34015 4.91115 4.1558C4.98658 3.97146 5.02453 3.77394 5.0228 3.57477C5.02107 3.3756 4.97969 3.17877 4.90107 2.99576C4.82246 2.81276 4.70819 2.64724 4.56492 2.50887L2.32892 0.269872L2.32992 0.270872ZM12.6969 2.15487L15.8789 0.386872L17.2929 1.80087L15.5249 4.98287L13.7569 5.33687L11.6369 7.45787L10.2219 6.04387L12.3429 3.92287L12.6969 2.15487ZM5.62592 9.93287L7.74692 12.0549L2.79692 17.0049C2.52396 17.2753 2.15764 17.4306 1.7735 17.4389C1.38937 17.4471 1.01671 17.3077 0.732374 17.0493C0.448042 16.7908 0.273718 16.4332 0.245352 16.05C0.216986 15.6668 0.336741 15.2873 0.579921 14.9899L0.676921 14.8829L5.62692 9.93287H5.62592Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#10AAAF',
    },
  },
}
</script>

<style></style>
