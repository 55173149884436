import { render, staticRenderFns } from "./AddressAlert.vue?vue&type=template&id=40b92bed&"
import script from "./AddressAlert.vue?vue&type=script&lang=js&"
export * from "./AddressAlert.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports