<template>
  <div class="flex justify-end">
    <svg
      width="262"
      height="173"
      viewBox="0 0 166 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="83.0846"
        cy="83.316"
        r="82.482"
        fill="#39B7ED"
        fill-opacity="0.12"
      />
      <path
        d="M36.4252 95.0594V86.4794L55.8052 59.8994H66.8452V86.1194H71.9452V95.0594H66.8452V102.859H56.4652V95.0594H36.4252ZM57.3052 71.7794L46.8652 86.1194H57.3052V71.7794ZM94.4977 60.0194C99.8177 60.0194 104.198 61.3194 107.638 63.9194C111.078 66.5194 113.218 70.0394 114.058 74.4794H103.198C102.478 72.9594 101.378 71.7594 99.8977 70.8794C98.4177 69.9594 96.6377 69.4994 94.5577 69.4994C91.3177 69.4994 88.7177 70.5994 86.7577 72.7994C84.7977 74.9594 83.8177 77.8994 83.8177 81.6194C83.8177 85.6594 84.8377 88.7594 86.8777 90.9194C88.9577 93.0794 91.8977 94.1594 95.6977 94.1594C98.1377 94.1594 100.278 93.4994 102.118 92.1794C103.958 90.8194 105.258 88.8994 106.018 86.4194H93.0577V79.0394H114.598V89.1794C113.798 91.6594 112.518 93.9594 110.758 96.0794C108.998 98.1994 106.738 99.9194 103.978 101.239C101.258 102.559 98.1377 103.219 94.6177 103.219C90.3377 103.219 86.5977 102.319 83.3977 100.519C80.1977 98.6794 77.7177 96.1194 75.9577 92.8394C74.2377 89.5594 73.3777 85.8194 73.3777 81.6194C73.3777 77.4194 74.2377 73.6794 75.9577 70.3994C77.7177 67.1194 80.1777 64.5794 83.3377 62.7794C86.5377 60.9394 90.2577 60.0194 94.4977 60.0194Z"
        fill="#10AAAF"
      />
      <path
        d="M124.256 80.5352H123.756V81.0352V95.804L119.284 91.3323L119.029 91.0774L118.707 91.2386L116.044 92.5699L115.423 92.8802L115.914 93.3707L125.233 102.69L125.587 103.044L125.94 102.69L135.26 93.3707L135.75 92.8802L135.13 92.5699L132.467 91.2386L132.145 91.0774L131.89 91.3323L127.418 95.804V81.0352V80.5352H126.918H124.256Z"
        fill="#10AAAF"
        stroke="#10AAAF"
      />
      <path
        d="M136.238 83.3125L136.738 83.3125L136.738 82.8125L136.738 68.0436L141.209 72.5154L141.464 72.7702L141.787 72.6091L144.449 71.2777L145.07 70.9675L144.579 70.477L135.26 61.1576L134.906 60.8041L134.553 61.1576L125.233 70.477L124.743 70.9675L125.363 71.2777L128.026 72.6091L128.348 72.7702L128.603 72.5154L133.075 68.0436L133.075 82.8125L133.075 83.3125L133.575 83.3125L136.238 83.3125Z"
        fill="#10AAAF"
        stroke="#10AAAF"
      />
    </svg>
  </div>
</template>

<script>
export default {}
</script>
