const getDefaultState = () => {
  return {
    map: null,
    active_layer: 'satellite-streets-v11',
    map_style_loaded: false,
  }
}
export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    map(state) {
      return state.map
    },
    active_layer(state) {
      return state.active_layer
    },
    map_style_loaded(state) {
      return state.map_style_loaded
    },
  },
  mutations: {
    MAP(state, payload) {
      state.map = payload
    },
    REMOVE_MAP(state) {
      if (state.map) {
        state.map.remove()
      }
      state.map = null
    },
    ACTIVE_LAYER(state, payload) {
      state.active_layer = payload
    },
    MAP_STYLLE_LOADED(state, payload) {
      return state.map_style_loaded = payload
    },
  },
}
