<template>
  <Popup
    v-show="true"
    @close="$emit('closeModal')"
    max_width_model="sm:w-full  w-[580px]"
    max_height_body="h-full"
    :is_btn_close_outside_container="true"
    padding_popup="p-7"
  >
    <template #body>
      <div class="flex flex-col px-10 mt-3 sm:px-0">
        <div class="flex flex-col">
          <div class="flex flex-col pr-4">
            <pro-input
              class="sm:text-sm"
              label="Veuillez renseigner votre adresse email : "
              placeholder="email"
              :rules="'required|email'"
              v-model="form.email"
              type="text"
            />
            <span
              v-if="errors.email"
              class="rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2"
            >
              {{ errors.email[0] }}
            </span>

            <div class="flex mt-8">
              <pro-button
                class="w-56 sm:w-full py-4 rounded-lg border-none h-14 md:h-12 sm:h-12 xl:text-base sm:text-sm"
                btn="primary"
                type="button"
                @click="saveEmail"
              >
                <span>Validez</span>
              </pro-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      form: {
        email: null,
      },
      errors: [],
      showModal: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    async saveEmail() {
      try {
        await this.$http.post('/grand-publics/insert-email', this.form)
        this.user.email = this.form.email
        this.$emit('emailSaved')
      } catch (error) {
        if (error.response.status == 422) {
          this.errors = error.response.data.errors
        }
      }
    },
  },
}
</script>
