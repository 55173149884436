<template>
  <div
    class="
      h-8
      w-8
      p-1
      rounded-md
      flex
      justify-center
      items-center
      bg-promy-blue-50
    "
  >
    <ProIcon :name="active_icon" v-if="is_active" color="text-promy-green-350" />
    <ProIcon :name="inactive_icon" v-else color="text-promy-green-350" />
  </div>
</template>

<script>
export default {
  props: {
    is_active: {
      type: Boolean,
      default: false,
    },
    active_icon: {
      type: String,
      default: 'minus',
    },
    inactive_icon: {
      type: String,
      default: 'plus',
    },
    focus_color: {
      type: String,
      default: 'text-promy-green-350',
    },
  },
}
</script>
