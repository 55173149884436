<template>
  <div class="flex flex-col space-y-4">
    <div
      v-if="with_green_number"
      @click="dial"
      class="
        flex
        bg-white
        border-3 border-solid border-[#0D8F4B]
        rounded-full
        items-center
        cursor-pointer
        hover:scale-105
        transition-all
      "
      style="height: fit-content"
    >
      <div class="flex bg-[#0D8F4B] rounded-full items-center">
        <div class="bg-white w-fit p-2 rounded-full m-[6px]">
          <PhoneRingingIcon class="h-4 w-4 text-[#0D8F4B]" />
        </div>
        <div class="relative text-white bottom-1 mr-4">
          <h4 class="uppercase font-bold text-xl">n° vert</h4>
          <h4
            class="
              absolute
              uppercase
              font-semibold
              text-[8px]
              right-0
              bottom-[-6px]
            "
          >
            appel gratuit
          </h4>
        </div>
      </div>
      <div class="text-[#0D8F4B] font-bold ml-1 mr-2">0 805 03 53 53</div>
    </div>
    <button
      v-if="with_contact_btn"
      @click="navigateToContactForm"
      :class="[
        'capitalize font-semibold text-base leading-4  text-promy-blue-270 px-4 bg-white  rounded-lg',
        isSideBar ? 'w-full mt-2 py-2' : 'py-2',
      ]"
    >
      <div class="flex items-center space-x-2 justify-center">
        <img src="/images/sendEmailIcon.svg" alt="" class="w-7 h-7" />
        <span class="minimized-sidebar:hidden" v-if="!is_hover_sidbar">
          Contact</span
        >
        <span v-if="is_hover_sidbar"> Contact</span>
      </div>
    </button>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    isSideBar: {
      type: Boolean,
    },
    with_contact_btn: {
      type: Boolean,
      default: true,
    },
    with_green_number: {
      type: Boolean,
      default: true,
    },
    is_hover_sidbar: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...mapMutations({
      toggleGuestModal: 'auth/TOGGLE_GUEST_MODAL',
    }),
    navigateToContactForm() {
      if (this.isSideBar) {
        this.$store.commit('sidebar/SET_ACTIVATED_SIDEBAR', false)
      }

      this.$router.push('/faq#contact_us')
    },
    dial() {
      window.open('tel:0 805 03 53 53')
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
}
</script>
