import Vue from 'vue'
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  localize,
  validate,
} from 'vee-validate'
import { required, email, min } from 'vee-validate/dist/rules'
import * as rules from 'vee-validate/dist/rules'
import fr from 'vee-validate/dist/locale/fr.json'

extend('email', {
  ...email,
  message: "L'email n'est pas valide",
})

extend('required', {
  ...required,
  message: 'Ce champ est obligatoire',
})

extend('minPassword', {
  ...min,
  message: 'Le mot de passe doit contenir au minimum {length} caractères',
})

extend('confirmedBy', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'La confirmation du mot de passe ne correspond pas',
})

extend('phone', {
  params: ['is_valid', 'dialCode'],
  validate(value, { is_valid, dialCode }) {
    let val = value.replace(dialCode, '')
    return (
      is_valid &&
      ['06', '6', '07', '7'].some((item) =>
        val.replace(/^\s+|\s+$/gm, '').startsWith(item),
      )
    )
  },
  message:
    "Numéro du téléphone n'est pas valide. Ce doit être un numéro mobile.",
})

// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

localize('fr', fr)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
