<template>
  <svg
    width="26"
    height="22"
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3969 6.13777L14.3963 1.5497C13.4207 1.05114 11.8237 1.05114 10.8481 1.5497L1.85355 6.13777C0.792957 6.67675 0.792957 7.55866 1.85355 8.09764L10.7722 12.6453C11.79 13.1641 13.4653 13.1641 14.4831 12.6453L23.4017 8.09764C24.4575 7.55866 24.4575 6.67608 23.3969 6.13777Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.83706 10.6405L1.84745 13.1441C0.786853 13.683 0.786853 14.5656 1.84745 15.1046L10.7661 19.6516C11.7839 20.1704 13.4591 20.1704 14.477 19.6516L23.3956 15.1046C24.4622 14.5656 24.4622 13.683 23.4016 13.1441L18.4072 10.6405"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
