<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9025 0H2.09774C0.941132 0 0 0.941132 0 2.09799V13.9015C0 15.0586 0.94088 16 2.09774 16H13.9025C15.0591 16 16 15.0589 16 13.9015V2.09799C16 0.941132 15.0591 0 13.9025 0ZM12.9177 9.63992H9.63966V12.9177H6.36236V9.63992H3.08328V6.36008H6.36236V3.08227H9.63966V6.36008H12.9177V9.63992Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
