<template>
  <div
    class="flex items-center justify-between text-promy-primary font-main font-semibold"
  >
    <div name="checkbox" :for="id">
      <slot></slot>
    </div>
    <div class="relative">
      <input
        class="hidden"
        type="checkbox"
        :id="id"
        :value="val"
        v-model="checked"
        @change="check"
      />
      <label :for="id" :class="{ '!static': staticLabel }">
        <span>
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.2168 1.88676C14.08 1.75894 13.9199 1.66031 13.7457 1.5965C13.5714 1.5327 13.3864 1.50497 13.2011 1.5149C13.0159 1.52483 12.8341 1.57223 12.6662 1.65438C12.4982 1.73654 12.3473 1.85184 12.2222 1.99371L5.90235 9.149L2.72622 6.24766C2.47151 6.01469 2.1366 5.89615 1.79514 5.91813C1.45369 5.94011 1.13367 6.1008 0.905486 6.36485C0.677301 6.6289 0.559642 6.97468 0.578392 7.32613C0.597143 7.67758 0.750767 8.00591 1.00547 8.23888L5.26294 12.1277C5.39407 12.2474 5.54799 12.3378 5.71507 12.3932C5.88214 12.4485 6.05878 12.4677 6.23392 12.4495C6.41949 12.44 6.60167 12.3928 6.77002 12.3107C6.93838 12.2287 7.0896 12.1133 7.21501 11.9713L14.3056 3.94105C14.5584 3.65425 14.691 3.27671 14.6743 2.89138C14.6577 2.50605 14.4931 2.14446 14.2167 1.88608"
              fill="white"
            />
          </svg>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    val: {},
    id: {
      type: String,
      required: true,
    },
    staticLabel: {
      type: Boolean,
    },
  },

  data() {
    return {
      proxy: false,
    }
  },

  methods: {
    check() {
      this.$emit('input', this.proxy)
    },
  },

  computed: {
    checked: {
      get() {
        return this.value
      },
      set(value) {
        this.proxy = value
      },
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  @apply absolute -left-2 -top-3 w-6 h-6 cursor-pointer rounded-md border border-promy-gray-100 bg-promy-gray-100 flex justify-center items-center hover:border-promy-gray-200;
}
label span svg {
  width: 0;
}
[type='checkbox']:checked + label {
  @apply border-promy-green-350 bg-promy-green-350;
}
[type='checkbox']:checked + label span svg {
  width: 15px;
  transition: all 0.3s ease;
}
</style>
