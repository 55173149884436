function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}

import auth from '../../middleware/auth'
import isValidatedCGU from '@/middleware/isValidatedCGU'

export default [
  {
    path: 'revelations',
    component: page('Revelation'),
    children: [
      {
        path: '/',
        name: 'revelations',
        component: page('Revelation/List'),
        meta: {
          middleware: [auth, isValidatedCGU],
        },
      },
      {
        path: ':id',
        name: 'recap-revelations',
        component: page('Revelation/RecapDemande'),
        meta: {
          middleware: [auth, isValidatedCGU],
        },
      },
    ],
  },
]
