<template>
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1882 25.3715V15.468H19.5292L20.0258 11.5904H16.1882V9.12058C16.1882 8.00167 16.4999 7.23558 18.1058 7.23558H20.1406V3.77854C19.1506 3.67244 18.1554 3.62121 17.1597 3.62508C14.2065 3.62508 12.1789 5.42792 12.1789 8.73754V11.5832H8.85962V15.4607H12.1862V25.3715H16.1882Z"
      :fill="color ? color : '#395185'"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
    },
  },
}
</script>
