<template>
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 0C12.0196 0 14.4359 0.993365 16.2175 2.76156C17.9991 4.52977 19 6.92796 19 9.42857C19 13.332 16.0022 17.4219 10.1333 21.7905C9.95062 21.9265 9.72839 22 9.5 22C9.27161 22 9.04938 21.9265 8.86667 21.7905C2.99778 17.4219 0 13.332 0 9.42857C0 6.92796 1.00089 4.52977 2.78249 2.76156C4.56408 0.993365 6.98044 0 9.5 0ZM9.5 6.28571C8.66015 6.28571 7.85469 6.61684 7.26083 7.20624C6.66696 7.79564 6.33333 8.59503 6.33333 9.42857C6.33333 10.2621 6.66696 11.0615 7.26083 11.6509C7.85469 12.2403 8.66015 12.5714 9.5 12.5714C10.3399 12.5714 11.1453 12.2403 11.7392 11.6509C12.333 11.0615 12.6667 10.2621 12.6667 9.42857C12.6667 8.59503 12.333 7.79564 11.7392 7.20624C11.1453 6.61684 10.3399 6.28571 9.5 6.28571Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
