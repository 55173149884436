<template>
  <svg
    fill="none"
    width="10"
    height="6"
    viewBox="0 0 10 6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0.333252L9.33013 5.33325L0.669873 5.33325L5 0.333252Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
