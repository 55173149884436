<template>
  <div>
    <h1 class="
          text-2xl
          lg:text-xl
          sm:text-base
          font-bold
          mt-4
          mb-10
          text-promy-purply-900
        ">
      Modifiez vos informations personnelles
    </h1>
    <div class="
          flex flex-wrap
          justify-between
          items-center
          md:flex-col md:space-x-0 md:space-y-6
        ">
      <pro-input class="w-1/2 pr-12 md:pr-0 md:w-full" label="Prénom" :placeholder="'Prénom'" :rules="'required'"
        v-model="form.prenom" type="text" />
      <pro-input class="w-1/2 md:w-full" label="Nom" :placeholder="'Nom'" :rules="'required'" v-model="form.nom"
        type="text" />
      <pro-input class="w-1/2 pr-12 md:pr-0 pt-6 md:pt-0 md:w-full" label="Email" :placeholder="'Email'"
        :rules="'required|email'" v-model="form.email" type="email" :disabled="user.email_verified_at ? true : false" />
      <pro-input-tel class="w-1/2 pt-6 md:pt-0 md:w-full" label="Tél" :rules="'required'" v-model="form.telephone_mobile"
        :disabled="user.verified_telephone_at ? true : false" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        prenom: '',
        email: '',
        nom: '',
        telephone_mobile: '',
      },
    }
  },
  mounted() {
    this.form.prenom = this.user.prenom
    this.form.nom = this.user.nom
    this.form.telephone_mobile = this.user.telephone_mobile
    this.form.email = this.user.email
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('profileInfo', val)
      },
      deep: true,
    },
  },
}
</script>

<style>
</style>
