import Vue from 'vue'
import moment from 'moment'

// filter global
Vue.filter('filterDate', function (date) {
  if (date) {
    return moment(new Date(date)).format('DD/MM/YYYY')
  }
})

Vue.filter('filterFullDate', function (date) {
  if (date) {
    return moment(new Date(date)).format('DD/MM/YYYY, h:mm:ss a')
  }
})
