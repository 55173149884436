<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7715 0.218426C17.6703 0.117681 17.5424 0.0479286 17.4029 0.0173707C17.2634 -0.0131872 17.1181 -0.00328057 16.984 0.0459259L0.484014 6.04593C0.341715 6.09991 0.219204 6.1959 0.132753 6.32115C0.0463009 6.4464 0 6.59499 0 6.74718C0 6.89937 0.0463009 7.04796 0.132753 7.17322C0.219204 7.29847 0.341715 7.39446 0.484014 7.44843L6.92651 10.0209L11.6815 5.25093L12.739 6.30843L7.96151 11.0859L10.5415 17.5284C10.5971 17.668 10.6933 17.7876 10.8177 17.8719C10.942 17.9561 11.0888 18.0011 11.239 18.0009C11.3906 17.9978 11.5376 17.9489 11.6608 17.8605C11.784 17.7721 11.8775 17.6485 11.929 17.5059L17.929 1.00593C17.9801 0.873234 17.9924 0.728749 17.9646 0.589319C17.9367 0.449889 17.8697 0.321259 17.7715 0.218426Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
