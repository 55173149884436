<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.9148 5.7657C23.8966 5.17766 22.5895 5.52805 22.0015 6.54621L20.1094 9.82414V7.10938C20.1094 6.82906 19.8819 6.60156 19.6016 6.60156C19.3207 6.60156 19.0938 6.82906 19.0938 7.10938V11.5781V11.5827L13.5164 21.2428C13.4809 21.3038 13.4591 21.3718 13.4514 21.4424L13.0696 24.9844H1.01563V1.01562H19.0938V2.53906C19.0938 2.81938 19.3207 3.04688 19.6016 3.04688C19.8819 3.04688 20.1094 2.81938 20.1094 2.53906V0.507812C20.1094 0.2275 19.8819 0 19.6016 0H0.507813C0.226992 0 0 0.2275 0 0.507812V25.4922C0 25.7725 0.226992 26 0.507813 26H19.6016C19.8819 26 20.1094 25.7725 20.1094 25.4922V18.3549L25.6958 8.67902C26.2834 7.66086 25.9335 6.35375 24.9148 5.7657ZM14.3777 22.3265L15.8417 23.1715L14.1538 24.4045L14.3777 22.3265ZM19.0938 24.9844H15.082L17.0706 23.5315C17.1275 23.4899 17.1752 23.4366 17.2108 23.3756L19.0938 20.1145V24.9844ZM16.5852 22.428L14.6499 21.3109L22.0827 8.4373L24.0175 9.55449L16.5852 22.428ZM24.8158 8.17121L24.5253 8.67496L22.5905 7.55777L22.881 7.05402C23.1888 6.52082 23.8738 6.3375 24.407 6.64523C24.9407 6.95348 25.124 7.63801 24.8158 8.17121Z"
      :fill="color"
    />
    <path
      d="M19.6016 4.31641C19.4675 4.31641 19.337 4.37074 19.2425 4.4652C19.1481 4.55965 19.0938 4.69066 19.0938 4.82422C19.0938 4.95828 19.1481 5.08879 19.2425 5.18324C19.337 5.2777 19.468 5.33203 19.6016 5.33203C19.7351 5.33203 19.8661 5.2777 19.9606 5.18324C20.055 5.08879 20.1094 4.95777 20.1094 4.82422C20.1094 4.69066 20.055 4.55965 19.9606 4.4652C19.8661 4.37074 19.7356 4.31641 19.6016 4.31641Z"
      :fill="color"
    />
    <path
      d="M15.1836 5.38281H4.92578C4.64496 5.38281 4.41797 5.61031 4.41797 5.89062C4.41797 6.17094 4.64496 6.39844 4.92578 6.39844H15.1836C15.4639 6.39844 15.6914 6.17094 15.6914 5.89062C15.6914 5.61031 15.4639 5.38281 15.1836 5.38281Z"
      :fill="color"
    />
    <path
      d="M15.1836 8.22656H4.92578C4.64496 8.22656 4.41797 8.45406 4.41797 8.73438C4.41797 9.01469 4.64496 9.24219 4.92578 9.24219H15.1836C15.4639 9.24219 15.6914 9.01469 15.6914 8.73438C15.6914 8.45406 15.4639 8.22656 15.1836 8.22656Z"
      :fill="color"
    />
    <path
      d="M15.1836 11.0703H11.4131C11.1323 11.0703 10.9053 11.2978 10.9053 11.5781C10.9053 11.8584 11.1323 12.0859 11.4131 12.0859H15.1836C15.4639 12.0859 15.6914 11.8584 15.6914 11.5781C15.6914 11.2978 15.4639 11.0703 15.1836 11.0703Z"
      :fill="color"
    />
    <path
      d="M15.6914 14.4219C15.6914 14.1416 15.4639 13.9141 15.1836 13.9141H4.92578C4.64496 13.9141 4.41797 14.1416 4.41797 14.4219C4.41797 14.7022 4.64496 14.9297 4.92578 14.9297H15.1836C15.4639 14.9297 15.6914 14.7022 15.6914 14.4219Z"
      :fill="color"
    />
    <path
      d="M13.5078 17.2656C13.5078 16.9853 13.2803 16.7578 13 16.7578H9.82617C9.54535 16.7578 9.31836 16.9853 9.31836 17.2656C9.31836 17.5459 9.54535 17.7734 9.82617 17.7734H13C13.2803 17.7734 13.5078 17.5459 13.5078 17.2656Z"
      :fill="color"
    />
    <path
      d="M4.92578 20.6172H9.39453C9.67484 20.6172 9.90234 20.3897 9.90234 20.1094C9.90234 19.8291 9.67484 19.6016 9.39453 19.6016H4.92578C4.64496 19.6016 4.41797 19.8291 4.41797 20.1094C4.41797 20.3897 4.64496 20.6172 4.92578 20.6172Z"
      :fill="color"
    />
    <path
      d="M4.92578 12.0859H8.31086C8.59168 12.0859 8.81867 11.8584 8.81867 11.5781C8.81867 11.2978 8.59168 11.0703 8.31086 11.0703H4.92578C4.64496 11.0703 4.41797 11.2978 4.41797 11.5781C4.41797 11.8584 4.64496 12.0859 4.92578 12.0859Z"
      :fill="color"
    />
    <path
      d="M4.92578 17.7734H7.54102C7.82133 17.7734 8.04883 17.5459 8.04883 17.2656C8.04883 16.9853 7.82133 16.7578 7.54102 16.7578H4.92578C4.64496 16.7578 4.41797 16.9853 4.41797 17.2656C4.41797 17.5459 4.64496 17.7734 4.92578 17.7734Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
