<template>
  <button
    class="first-letter:capitalize py-3 font-semibold font-dosis rounded-lg border flex flex-col justify-between items-center"
    :class="[
      OptionName === activeOption
        ? 'bg-promy-blue-50 border-promy-blue-50 text-promy-green-350'
        : 'text-promy-primary  bg-white hover:text-promy-green-350',
      classes,
    ]"
    @click="updateActiveOption"
  >
    <slot name="icon" />
    <span class="first-letter:capitalize my-auto">
      {{ OptionName }}
    </span>
    <input :value="OptionName" class="hidden" />
  </button>
</template>
<script>
export default {
  data() {
    return {}
  },
  model: {
    prop: 'activeOption',
    event: 'onUpdateOption',
  },
  props: {
    activeOption: {
      type: String,
    },
    OptionName: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      default: 'px-4 text-base',
    },
  },
  methods: {
    updateActiveOption() {
      this.$emit('onUpdateOption', this.OptionName)
    },
  },
}
</script>
