<template>
  <router-view />
</template>

<script>
import Vue100vh from 'vue-div-100vh'
export default {
  name: 'App',
  components: {
    Vue100vh,
  },
}
</script>

<style src="./assets/styles/tailwind.css"></style>
<style src="./assets/styles/toastification.css"></style>
<style src="./assets/styles/mapbox.css"></style>
<style lang="scss">
body {
  overflow: auto;
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
#app {
  @apply font-main text-promy-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}
</style>
