import Vue from 'vue'
import toast from '../components/Alert/CustomToast/toast.vue'
import infoIcon from '../components/Alert/CustomToast/icon_info.vue'
import warningIcon from '../components/Alert/CustomToast/icon_warning.vue'
import dangerIcon from '../components/Alert/CustomToast/icon_danger.vue'
import successIcon from '../components/Alert/CustomToast/icon_success.vue'
import progressIcon from '../components/Alert/CustomToast/icon_progress.vue'

Vue.mixin({
  methods: {
    ToastContent(title, content, type) {
      return {
        component: toast,
        props: {
          title,
          content,
          type,
        },
      }
    },
    ToastIcon(type) {
      switch (type) {
        case 'success':
          return {
            icon: successIcon,
          }
        case 'warning':
          return {
            icon: warningIcon,
          }
        case 'error':
          return {
            icon: dangerIcon,
          }
        case 'progress':
          return {
            icon: progressIcon,
          }
        default:
          return {
            icon: infoIcon,
          }
      }
    },
    toastConfig(title, content, type) {
      return [this.ToastContent(title, content, type), this.ToastIcon(type)]
    },
    toast(title = null, content = null, type) {
      this.$toast.updateDefaults({ timeout: 5000 })
      switch (type) {
        case 'success':
          return this.$toast.success(
            ...this.toastConfig(title, content, 'success'),
          )
        case 'warning':
          return this.$toast.warning(
            ...this.toastConfig(title, content, 'warning'),
          )
        case 'error':
          return this.$toast.error(...this.toastConfig(title, content, 'error'))
        case 'progress':
          this.$toast.updateDefaults({ timeout: 20000 })
          return this.$toast(...this.toastConfig(title, content, 'progress'))
        case 'clear':
          return this.$toast.clear()
        default:
          return this.$toast(...this.toastConfig(title, content, 'info'))
      }
    },
  },
})
