<template>
  <div>
    <slot></slot>
  </div>
</template>
<script>
import axios from 'axios'
var convert = require('xml-js')
import { mapGetters } from 'vuex'
import Mapbox from '@/mixins/map/mapbox'

export default {
  mixins: [Mapbox],
  data() {
    return {
      wms_list_layers_names: [],
    }
  },
  props: {
    is_active: {
      type: Boolean,
      require: true,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      map: 'map/map',
    }),
  },
  methods: {
    GetCapabilitiesCadastre() {
      if (this.wms_list_layers_names.length === 0) {
        axios
          .get(
            'https://inspire.cadastre.gouv.fr/scpc/' +
              this.data.code_insee +
              '.wms?service=WMS&request=GetCapabilities',
          )
          .then((response) => {
            let xmlDoc = response.data
            let data = convert.xml2json(xmlDoc, {
              compact: true,
              spaces: 4,
            })
            let list_layers =
              JSON.parse(data).WMS_Capabilities.Capability.Layer.Layer
            this.wms_list_layers_names = list_layers.map(
              (layer) => layer.Name._text,
            )

            this.addSourceLayersCadastreOfficiel()
          })
          .catch(() => {})
      } else if (this.wms_list_layers_names.length)
        this.addSourceLayersCadastreOfficiel()
    },
    addSourceLayersCadastreOfficiel() {
      if (this.is_active) {
        if (
          !this.isExist(this.map.getLayer(this.source_and_layers_cadastre[1]))
        ) {
          this.addSourceWmsCadastreOfficiel()
          this.addLayerWmsCadastreOfficiel()
        }

        this.map.setLayoutProperty(
          this.source_and_layers_cadastre[1],
          'visibility',
          'visible',
        )
      }
    },
    removeLayer() {
      if (this.isExist(this.map.getLayer(this.source_and_layers_cadastre[1])))
        this.map.setLayoutProperty(
          this.source_and_layers_cadastre[1],
          'visibility',
          'none',
        )
    },
    addSourceWmsCadastreOfficiel() {
      this.map.addSource(this.source_and_layers_cadastre[0], {
        type: 'raster',
        tiles: [
          'https://inspire.cadastre.gouv.fr/scpc/' +
            this.data.code_insee +
            '.wms?service=wms&version=1.3&request=GetMap&layers=' +
            this.wms_list_layers_names.toString() +
            '&format=image/png&crs=EPSG:3857&BBOX={bbox-epsg-3857}&width=256&height=256&styles&transparent=true',
        ],
      })
    },
    addLayerWmsCadastreOfficiel() {
      this.map.addLayer({
        id: this.source_and_layers_cadastre[1],
        type: 'raster',
        source: this.source_and_layers_cadastre[0],
        paint: {},
      })
    },
  },
  watch: {
    is_active: {
      immediate: true,
      handler(is_active) {
        if (is_active) {
          this.GetCapabilitiesCadastre()
        } else this.removeLayer()
      },
    },
  },
}
</script>
