<template>
  <li
    class="accordion__item rounded-box"
    :style="{ 'background-color': bgColor }"
  >
    <div
      class="accordion__trigger"
      :class="{ accordion__trigger_active: visible }"
      @click="open"
    >
      <!-- This slot will handle the title/header of the accordion and is the part you click on -->
      <slot name="accordion-trigger"></slot>
    </div>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div class="accordion__content cursor-auto" v-show="visible">
        <ul>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <slot name="accordion-content"></slot>
        </ul>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  inject: ['Accordion'],
  data() {
    return {
      index: null,
    }
  },
  props: {
    bgColor: {
      type: String,
    },
    active_first_accordion_item: {
      type: Boolean,
      default: false,
    },
    custom_index: {
      type: String,
      default: null,
    },
    should_active: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.Accordion.active = this.active_first_accordion_item ? 1 : null
  },
  computed: {
    visible() {
      return this.index == this.Accordion.active
    },
  },
  methods: {
    open(event) {
      if (
        event.target.getAttribute('name') != 'checkbox' &&
        event.target.getAttribute('type') != 'checkbox' &&
        !this.should_active
      ) {
        if (this.visible) {
          this.Accordion.active = null
        } else {
          this.Accordion.active = this.index
        }
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    end(el) {
      el.style.height = ''
    },
  },
  created() {
    this.index = this.custom_index ? this.custom_index : this.Accordion.count++
  },
}
</script>

<style scoped>
.accordion__item {
  cursor: pointer;
  position: relative;
}

.accordion__trigger {
  display: flex;
  justify-content: space-between;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
