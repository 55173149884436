<template>
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 18.25V21.125"
      :stroke="current_color"
      stroke-width="1.4375"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.4375 1H11.0625C11.4437 1 11.8094 1.15145 12.079 1.42103C12.3485 1.69062 12.5 2.05625 12.5 2.4375V20.9453C12.5 20.993 12.4811 21.0387 12.4474 21.0724C12.4137 21.1061 12.368 21.125 12.3203 21.125H1V2.4375C1 2.05625 1.15145 1.69062 1.42103 1.42103C1.69062 1.15145 2.05625 1 2.4375 1V1Z"
      :stroke="current_color"
      stroke-width="1.4375"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.2188 8.1875H18.25C18.6312 8.1875 18.9969 8.33895 19.2665 8.60853C19.536 8.87812 19.6875 9.24375 19.6875 9.625V21.125H12.5V8.90625C12.5 8.71563 12.5757 8.53281 12.7105 8.39802C12.8453 8.26323 13.0281 8.1875 13.2188 8.1875Z"
      :stroke="current_color"
      stroke-width="1.4375"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.2497 18.9629C3.10084 18.9824 2.94963 18.9548 2.81727 18.8839C2.68492 18.8131 2.57807 18.7025 2.51173 18.5679C2.44539 18.4332 2.42288 18.2811 2.44737 18.133C2.47186 17.9849 2.54211 17.8482 2.64826 17.742C2.75442 17.6359 2.89115 17.5656 3.03926 17.5411C3.18738 17.5166 3.33944 17.5391 3.47412 17.6055C3.60879 17.6718 3.71931 17.7787 3.79016 17.911C3.86101 18.0434 3.88864 18.1946 3.86918 18.3435C3.84863 18.5005 3.77676 18.6465 3.66473 18.7585C3.5527 18.8705 3.4068 18.9424 3.2497 18.9629Z"
      :fill="current_color"
    />
    <path
      d="M3.2497 15.3692C3.10084 15.3886 2.94963 15.361 2.81727 15.2902C2.68492 15.2193 2.57807 15.1088 2.51173 14.9741C2.44539 14.8394 2.42288 14.6874 2.44737 14.5393C2.47186 14.3911 2.54211 14.2544 2.64826 14.1483C2.75442 14.0421 2.89115 13.9719 3.03926 13.9474C3.18738 13.9229 3.33944 13.9454 3.47412 14.0117C3.60879 14.0781 3.71931 14.1849 3.79016 14.3173C3.86101 14.4496 3.88864 14.6008 3.86918 14.7497C3.84863 14.9068 3.77676 15.0527 3.66473 15.1647C3.5527 15.2768 3.4068 15.3486 3.2497 15.3692Z"
      :fill="current_color"
    />
    <path
      d="M3.2497 11.7754C3.10084 11.7949 2.94963 11.7673 2.81727 11.6964C2.68492 11.6256 2.57807 11.515 2.51173 11.3804C2.44539 11.2457 2.42288 11.0936 2.44737 10.9455C2.47186 10.7974 2.54211 10.6607 2.64826 10.5545C2.75442 10.4484 2.89115 10.3781 3.03926 10.3536C3.18738 10.3291 3.33944 10.3516 3.47412 10.418C3.60879 10.4843 3.71931 10.5912 3.79016 10.7235C3.86101 10.8559 3.88864 11.0071 3.86918 11.156C3.84863 11.313 3.77676 11.4589 3.66473 11.571C3.5527 11.683 3.4068 11.7549 3.2497 11.7754Z"
      :fill="current_color"
    />
    <path
      d="M3.2497 8.18168C3.10084 8.20114 2.94963 8.17351 2.81727 8.10266C2.68492 8.03181 2.57807 7.92129 2.51173 7.78661C2.44539 7.65194 2.42288 7.49988 2.44737 7.35176C2.47186 7.20365 2.54211 7.06692 2.64826 6.96076C2.75442 6.85461 2.89115 6.78436 3.03926 6.75987C3.18738 6.73538 3.33944 6.75789 3.47412 6.82423C3.60879 6.89057 3.71931 6.99742 3.79016 7.12977C3.86101 7.26213 3.88864 7.41334 3.86918 7.5622C3.84863 7.7193 3.77676 7.8652 3.66473 7.97723C3.5527 8.08926 3.4068 8.16113 3.2497 8.18168Z"
      :fill="current_color"
    />
    <path
      d="M3.2497 4.58793C3.10084 4.60739 2.94963 4.57976 2.81727 4.50891C2.68492 4.43806 2.57807 4.32754 2.51173 4.19287C2.44539 4.05819 2.42288 3.90613 2.44737 3.75801C2.47186 3.6099 2.54211 3.47317 2.64826 3.36701C2.75442 3.26086 2.89115 3.19061 3.03926 3.16612C3.18738 3.14163 3.33944 3.16414 3.47412 3.23048C3.60879 3.29682 3.71931 3.40367 3.79016 3.53602C3.86101 3.66838 3.88864 3.81959 3.86918 3.96845C3.84863 4.12555 3.77676 4.27145 3.66473 4.38348C3.5527 4.49551 3.4068 4.56738 3.2497 4.58793Z"
      :fill="current_color"
    />
    <path
      d="M6.84345 15.3692C6.69459 15.3886 6.54338 15.361 6.41102 15.2902C6.27867 15.2193 6.17182 15.1088 6.10548 14.9741C6.03914 14.8394 6.01663 14.6874 6.04112 14.5393C6.06561 14.3911 6.13586 14.2544 6.24201 14.1483C6.34817 14.0421 6.4849 13.9719 6.63301 13.9474C6.78113 13.9229 6.93319 13.9454 7.06786 14.0117C7.20254 14.0781 7.31306 14.1849 7.38391 14.3173C7.45476 14.4496 7.48239 14.6008 7.46293 14.7497C7.44238 14.9068 7.37051 15.0527 7.25848 15.1647C7.14645 15.2768 7.00055 15.3486 6.84345 15.3692Z"
      :fill="current_color"
    />
    <path
      d="M6.84345 11.7754C6.69459 11.7949 6.54338 11.7673 6.41102 11.6964C6.27867 11.6256 6.17182 11.515 6.10548 11.3804C6.03914 11.2457 6.01663 11.0936 6.04112 10.9455C6.06561 10.7974 6.13586 10.6607 6.24201 10.5545C6.34817 10.4484 6.4849 10.3781 6.63301 10.3536C6.78113 10.3291 6.93319 10.3516 7.06786 10.418C7.20254 10.4843 7.31306 10.5912 7.38391 10.7235C7.45476 10.8559 7.48239 11.0071 7.46293 11.156C7.44238 11.313 7.37051 11.4589 7.25848 11.571C7.14645 11.683 7.00055 11.7549 6.84345 11.7754Z"
      :fill="current_color"
    />
    <path
      d="M6.84345 8.18168C6.69459 8.20114 6.54338 8.17351 6.41102 8.10266C6.27867 8.03181 6.17182 7.92129 6.10548 7.78661C6.03914 7.65194 6.01663 7.49988 6.04112 7.35176C6.06561 7.20365 6.13586 7.06692 6.24201 6.96076C6.34817 6.85461 6.4849 6.78436 6.63301 6.75987C6.78113 6.73538 6.93319 6.75789 7.06786 6.82423C7.20254 6.89057 7.31306 6.99742 7.38391 7.12977C7.45476 7.26213 7.48239 7.41334 7.46293 7.5622C7.44238 7.7193 7.37051 7.8652 7.25848 7.97723C7.14645 8.08926 7.00055 8.16113 6.84345 8.18168Z"
      :fill="current_color"
    />
    <path
      d="M6.84345 4.58793C6.69459 4.60739 6.54338 4.57976 6.41102 4.50891C6.27867 4.43806 6.17182 4.32754 6.10548 4.19287C6.03914 4.05819 6.01663 3.90613 6.04112 3.75801C6.06561 3.6099 6.13586 3.47317 6.24201 3.36701C6.34817 3.26086 6.4849 3.19061 6.63301 3.16612C6.78113 3.14163 6.93319 3.16414 7.06786 3.23048C7.20254 3.29682 7.31306 3.40367 7.38391 3.53602C7.45476 3.66838 7.48239 3.81959 7.46293 3.96845C7.44238 4.12555 7.37051 4.27145 7.25848 4.38348C7.14645 4.49551 7.00055 4.56738 6.84345 4.58793Z"
      :fill="current_color"
    />
    <path
      d="M10.4372 18.9629C10.2883 18.9824 10.1371 18.9548 10.0048 18.8839C9.87242 18.8131 9.76557 18.7025 9.69923 18.5679C9.63289 18.4332 9.61038 18.2811 9.63487 18.133C9.65936 17.9849 9.72961 17.8482 9.83576 17.742C9.94192 17.6359 10.0786 17.5656 10.2268 17.5411C10.3749 17.5166 10.5269 17.5391 10.6616 17.6055C10.7963 17.6718 10.9068 17.7787 10.9777 17.911C11.0485 18.0434 11.0761 18.1946 11.0567 18.3435C11.0361 18.5005 10.9643 18.6465 10.8522 18.7585C10.7402 18.8705 10.5943 18.9424 10.4372 18.9629Z"
      :fill="current_color"
    />
    <path
      d="M10.4372 15.3692C10.2883 15.3886 10.1371 15.361 10.0048 15.2902C9.87242 15.2193 9.76557 15.1088 9.69923 14.9741C9.63289 14.8394 9.61038 14.6874 9.63487 14.5393C9.65936 14.3911 9.72961 14.2544 9.83576 14.1483C9.94192 14.0421 10.0786 13.9719 10.2268 13.9474C10.3749 13.9229 10.5269 13.9454 10.6616 14.0117C10.7963 14.0781 10.9068 14.1849 10.9777 14.3173C11.0485 14.4496 11.0761 14.6008 11.0567 14.7497C11.0361 14.9068 10.9643 15.0527 10.8522 15.1647C10.7402 15.2768 10.5943 15.3486 10.4372 15.3692Z"
      :fill="current_color"
    />
    <path
      d="M10.4372 11.7754C10.2883 11.7949 10.1371 11.7673 10.0048 11.6964C9.87242 11.6256 9.76557 11.515 9.69923 11.3804C9.63289 11.2457 9.61038 11.0936 9.63487 10.9455C9.65936 10.7974 9.72961 10.6607 9.83576 10.5545C9.94192 10.4484 10.0786 10.3781 10.2268 10.3536C10.3749 10.3291 10.5269 10.3516 10.6616 10.418C10.7963 10.4843 10.9068 10.5912 10.9777 10.7235C11.0485 10.8559 11.0761 11.0071 11.0567 11.156C11.0361 11.313 10.9643 11.4589 10.8522 11.571C10.7402 11.683 10.5943 11.7549 10.4372 11.7754Z"
      :fill="current_color"
    />
    <path
      d="M10.8529 7.97755C11.1327 7.69773 11.1316 7.24293 10.8504 6.96172C10.5691 6.6805 10.1143 6.67937 9.83453 6.95918C9.55471 7.23899 9.55585 7.69379 9.83707 7.975C10.1183 8.25622 10.5731 8.25736 10.8529 7.97755Z"
      :fill="current_color"
    />
    <path
      d="M10.4372 4.58793C10.2883 4.60739 10.1371 4.57976 10.0048 4.50891C9.87242 4.43806 9.76557 4.32754 9.69923 4.19287C9.63289 4.05819 9.61038 3.90613 9.63487 3.75801C9.65936 3.6099 9.72961 3.47317 9.83576 3.36701C9.94192 3.26086 10.0786 3.19061 10.2268 3.16612C10.3749 3.14163 10.5269 3.16414 10.6616 3.23048C10.7963 3.29682 10.9068 3.40367 10.9777 3.53602C11.0485 3.66838 11.0761 3.81959 11.0567 3.96845C11.0361 4.12555 10.9643 4.27145 10.8522 4.38348C10.7402 4.49551 10.5943 4.56738 10.4372 4.58793Z"
      :fill="current_color"
    />
    <path
      d="M16.8125 17.5312C16.6703 17.5312 16.5314 17.5734 16.4132 17.6524C16.295 17.7314 16.2029 17.8436 16.1485 17.9749C16.0941 18.1063 16.0798 18.2508 16.1076 18.3902C16.1353 18.5296 16.2037 18.6577 16.3043 18.7582C16.4048 18.8588 16.5329 18.9272 16.6723 18.9549C16.8117 18.9827 16.9562 18.9684 17.0876 18.914C17.2189 18.8596 17.3311 18.7675 17.4101 18.6493C17.4891 18.5311 17.5313 18.3922 17.5313 18.25C17.5313 18.0594 17.4555 17.8766 17.3207 17.7418C17.1859 17.607 17.0031 17.5312 16.8125 17.5312Z"
      :fill="current_color"
    />
    <path
      d="M16.8125 13.9375C16.6703 13.9375 16.5314 13.9797 16.4132 14.0586C16.295 14.1376 16.2029 14.2499 16.1485 14.3812C16.0941 14.5125 16.0798 14.657 16.1076 14.7965C16.1353 14.9359 16.2037 15.064 16.3043 15.1645C16.4048 15.265 16.5329 15.3335 16.6723 15.3612C16.8117 15.3889 16.9562 15.3747 17.0876 15.3203C17.2189 15.2659 17.3311 15.1738 17.4101 15.0556C17.4891 14.9374 17.5313 14.7984 17.5313 14.6562C17.5313 14.4656 17.4555 14.2828 17.3207 14.148C17.1859 14.0132 17.0031 13.9375 16.8125 13.9375Z"
      :fill="current_color"
    />
    <path
      d="M16.8125 10.3438C16.6703 10.3438 16.5314 10.3859 16.4132 10.4649C16.295 10.5439 16.2029 10.6561 16.1485 10.7874C16.0941 10.9188 16.0798 11.0633 16.1076 11.2027C16.1353 11.3421 16.2037 11.4702 16.3043 11.5707C16.4048 11.6713 16.5329 11.7397 16.6723 11.7674C16.8117 11.7952 16.9562 11.7809 17.0876 11.7265C17.2189 11.6721 17.3311 11.58 17.4101 11.4618C17.4891 11.3436 17.5313 11.2047 17.5313 11.0625C17.5313 10.8719 17.4555 10.6891 17.3207 10.5543C17.1859 10.4195 17.0031 10.3438 16.8125 10.3438Z"
      :fill="current_color"
    />
    <path
      d="M13.9375 17.5312C13.7953 17.5312 13.6564 17.5734 13.5382 17.6524C13.42 17.7314 13.3279 17.8436 13.2735 17.9749C13.2191 18.1063 13.2048 18.2508 13.2326 18.3902C13.2603 18.5296 13.3287 18.6577 13.4293 18.7582C13.5298 18.8588 13.6579 18.9272 13.7973 18.9549C13.9367 18.9827 14.0812 18.9684 14.2126 18.914C14.3439 18.8596 14.4561 18.7675 14.5351 18.6493C14.6141 18.5311 14.6563 18.3922 14.6563 18.25C14.6563 18.0594 14.5805 17.8766 14.4457 17.7418C14.3109 17.607 14.1281 17.5312 13.9375 17.5312Z"
      :fill="current_color"
    />
    <path
      d="M13.9375 13.9375C13.7953 13.9375 13.6564 13.9797 13.5382 14.0586C13.42 14.1376 13.3279 14.2499 13.2735 14.3812C13.2191 14.5125 13.2048 14.657 13.2326 14.7965C13.2603 14.9359 13.3287 15.064 13.4293 15.1645C13.5298 15.265 13.6579 15.3335 13.7973 15.3612C13.9367 15.3889 14.0812 15.3747 14.2126 15.3203C14.3439 15.2659 14.4561 15.1738 14.5351 15.0556C14.6141 14.9374 14.6563 14.7984 14.6563 14.6562C14.6563 14.4656 14.5805 14.2828 14.4457 14.148C14.3109 14.0132 14.1281 13.9375 13.9375 13.9375Z"
      :fill="current_color"
    />
    <path
      d="M13.9375 10.3438C13.7953 10.3438 13.6564 10.3859 13.5382 10.4649C13.42 10.5439 13.3279 10.6561 13.2735 10.7874C13.2191 10.9188 13.2048 11.0633 13.2326 11.2027C13.2603 11.3421 13.3287 11.4702 13.4293 11.5707C13.5298 11.6713 13.6579 11.7397 13.7973 11.7674C13.9367 11.7952 14.0812 11.7809 14.2126 11.7265C14.3439 11.6721 14.4561 11.58 14.5351 11.4618C14.6141 11.3436 14.6563 11.2047 14.6563 11.0625C14.6563 10.8719 14.5805 10.6891 14.4457 10.5543C14.3109 10.4195 14.1281 10.3438 13.9375 10.3438Z"
      :fill="current_color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
    hoverColor: {
      type: String,
      default: 'white',
    },
    hovering: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      current_color: this.color,
    }
  },

  watch: {
    color(value) {
      this.current_color = value
    },
    hovering(value) {
      this.current_color = this.color
      if (value) this.current_color = this.hoverColor
    },
  },
}
</script>
