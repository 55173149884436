<template>
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6041 17H0.396229C0.17742 17 0.0003724 16.8415 0.0003724 16.6458L0.000228824 0.354194C0.000228824 0.158542 0.177258 0 0.396085 0H18.604C18.8228 0 18.9999 0.158526 18.9999 0.354194V16.6458C19 16.8415 18.8228 17 18.6041 17ZM0.791717 16.2916H18.2083V0.708421H0.791717V16.2916Z"
      :fill="color"
    />
    <path
      d="M18.6041 4.60421H0.396226C0.177417 4.60421 0.000370157 4.44568 0.000370157 4.25001C0.000370157 4.05435 0.177399 3.89582 0.396226 3.89582H18.6041C18.823 3.89582 19 4.05435 19 4.25001C19 4.44554 18.8228 4.60421 18.6041 4.60421Z"
      :fill="color"
    />
    <path
      d="M18.6038 8.85421H0.395856C0.177047 8.85421 0 8.69568 0 8.50001C0 8.30436 0.177029 8.14582 0.395856 8.14582H18.6038C18.8226 8.14582 18.9996 8.30434 18.9996 8.50001C18.9996 8.69554 18.8226 8.85421 18.6038 8.85421Z"
      :fill="color"
    />
    <path
      d="M18.6041 13.1039H0.396226C0.177417 13.1039 0.000370157 12.9453 0.000370157 12.7497C0.000370157 12.554 0.177399 12.3955 0.396226 12.3955H18.6041C18.823 12.3955 19 12.554 19 12.7497C19 12.9453 18.8228 13.1039 18.6041 13.1039Z"
      :fill="color"
    />
    <path
      d="M4.74991 4.60419C4.5311 4.60419 4.35405 4.44567 4.35405 4.25V0.354194C4.35405 0.158542 4.53108 0 4.74991 0C4.96872 0 5.14577 0.158526 5.14577 0.354194V4.25C5.14577 4.44552 4.96874 4.60419 4.74991 4.60419Z"
      :fill="color"
    />
    <path
      d="M14.2497 4.60419C14.0309 4.60419 13.8539 4.44567 13.8539 4.25L13.854 0.354194C13.854 0.158542 14.031 0 14.2499 0C14.4687 0 14.6457 0.158526 14.6457 0.354194V4.25C14.6456 4.44552 14.4686 4.60419 14.2498 4.60419H14.2497Z"
      :fill="color"
    />
    <path
      d="M9.10396 8.85419C8.88515 8.85419 8.70811 8.69567 8.70811 8.5V4.60419C8.70811 4.40854 8.88513 4.25 9.10396 4.25C9.32279 4.25 9.49982 4.40852 9.49982 4.60419V8.5C9.49982 8.69552 9.32279 8.85419 9.10396 8.85419Z"
      :fill="color"
    />
    <path
      d="M4.74991 12.9272C4.5311 12.9272 4.35405 12.7687 4.35405 12.5731V8.67725C4.35405 8.4816 4.53108 8.32305 4.74991 8.32305C4.96872 8.32305 5.14577 8.48158 5.14577 8.67725V12.5731C5.14577 12.7686 4.96874 12.9272 4.74991 12.9272Z"
      :fill="color"
    />
    <path
      d="M14.2497 12.9272C14.0309 12.9272 13.8539 12.7687 13.8539 12.5731V8.67725C13.8539 8.4816 14.0309 8.32305 14.2497 8.32305C14.4686 8.32305 14.6456 8.48158 14.6456 8.67725V12.5731C14.6456 12.7686 14.4686 12.9272 14.2497 12.9272Z"
      :fill="color"
    />
    <path
      d="M9.10396 17C8.88515 17 8.70811 16.8415 8.70811 16.6458L8.70825 12.8075C8.70825 12.6119 8.88528 12.4533 9.10411 12.4533C9.32293 12.4533 9.49996 12.6119 9.49996 12.8075L9.49982 16.6458C9.49982 16.8414 9.32279 17 9.10396 17Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#484848',
    },
  },
}
</script>