<template>
  <a
    class="flex justify-between w-full px-3 cursor-pointer bg-white"
    :class="item_state.is_active(indice) ? 'rounded-t-xl' : 'rounded-xl'"
    @click="
      item_state.set_active_accordion(
        item_state.is_active(indice) ? -1 : indice,
      )
    "
  >
    <div
      class="w-9/10 font-bold flex items-center space-x-4"
      :class="
        item_state.is_active(indice) ? focus_color.title : `text-promy-primary`
      "
    >
      <ProIcon
        :name="item_icon.name"
        v-if="item_icon !== null"
        :color="item_icon.color"
      />
      <span>{{ title }}</span>
    </div>

    <accordion-icon
      :is_active="item_state.is_active(indice)"
      :active_icon="active_icon"
      :inactive_icon="inactive_icon"
      :focus_color="focus_color.action_icon"
    />
  </a>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Untitled',
    },
    indice: {
      type: [String, Number],
      required: true,
    },
    item_icon: {
      type: Object,
      default: null,
    },
    item_state: {
      type: Object,
      required: true,
    },
    active_icon: {
      type: String,
      default: 'minus',
    },
    inactive_icon: {
      type: String,
      default: 'plus',
    },
    focus_color: {
      type: Object,
      default: { title: '', action_icon: '#10AAAF' },
    },
  },
}
</script>
