<template>
  <div>
    <label
      v-if="label"
      :for="uniqueId"
      class="flex justify-between mb-1 font-bold text-promy-gray-200"
      >{{ label }} <span v-if="label_value">{{ label_value }}</span></label
    >
    <div class="relative">
      <ProIcon
        v-if="iconName"
        :name="iconName"
        :color="iconColor"
        :class="iconClasses"
      />
      <input
        :id="uniqueId"
        :dir="dir"
        :name="name ? name : label"
        :type="type"
        :placeholder="placeholder ? placeholder : label"
        :value="value"
        @input="handleInput($event.target.value)"
        :disabled="disabled"
        :class="[
          !clearBG ? 'bg-promy-gray-background-300' : 'bg-white',
          classes,
        ]"
        autocomplete="off"
      />
      <template v-if="is_loading">
        <div class="loader"></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dir: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: 'py-2',
    },
    label: {
      type: String,
      default: null,
    },
    label_value: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    type: {
      type: [String, Number],
      default: 'text',
    },
    clearBG: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: null,
    },
    iconClasses: {
      type: String,
      default: 'z-10 absolute left-[15px] top-[15px]',
    },
    iconColor: {
      type: String,
      default: '#6C6C6C',
    },
    is_loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    uniqueId() {
      return `input-${this._uid}`
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  @apply rounded-lg px-4 text-promy-primary border-solid border w-full border-promy-gray-150;
}
input:hover {
  @apply border-promy-gray-200;
}
input:focus {
  @apply outline-none bg-white border-promy-green-350;
}
input[disabled] {
  @apply border-promy-gray-100 bg-promy-gray-100;
}
.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #45ccdf; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  top: 13px;
  right: 13px;
  position: absolute;
}
</style>
