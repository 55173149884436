<template>
  <div class="justify-between bg-white rounded-lg p-10 sm:p-5 grid grid-cols-3">
    <div class="flex flex-col space-y-2 col-span-2">
      <span class="promy-dark-purple font-bold"> {{ title }} </span>
      <slot name="message-error"></slot>
    </div>
    <div
      class="row-span-2 flex justify-center items-center sm:item w-32 h-32 rounded-full bg-promy-blue-50 self-center sm:self-start justify-self-end"
    >
      <ProIcon :name="name_icon" :class="classes_icon" color="#10AAAF" />
    </div>
    <div class="col-span-2 sm:col-span-3">
      <slot class="" name="body"> </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
    },

    classes_icon: {
      type: String,
      default: '!w-[4rem] !h-[4rem]',
    },
    name_icon: {
      type: String,
      require: true,
    },
  },
}
</script>
