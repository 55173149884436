<template>
  <div class="w-16 h-16 relative container lg:!mr-8">
    <vue-circle
      :progress="100"
      :size="100"
      :reverse="false"
      line-cap="round"
      :fill="fill"
      empty-fill="#DEDEDE"
      :animation="animation"
      :animation-start-value="0.0"
      :start-angle="0"
      insert-mode="append"
      :thickness="10"
      :show-percent="true"
    />
  </div>
</template>

<script>
import VueCircle from 'vue2-circle-progress/src/index.vue'

export default {
  components: {
    VueCircle,
  },
  data() {
    return {
      value: 0,
      fill: { color: '#10AAAF' },
      animation: {
        duration: 20000,
      },
    }
  },
}
</script>

<style lang="scss">
.Vue-Toastification__icon {
  .circle {
    width: 40px;

    canvas {
      width: 40px;
    }
    @media (max-width: 1024px) {
      canvas {
        width: 50px !important;
        height: 50px !important;
      }
    }
    @media (min-width: 1025px) {
      canvas {
        width: 40px !important;
        height: 40px !important;
      }
    }
    .circle-percent-text-body {
      .percent-text {
        color: black;
        font-size: 10px !important;
      }
      @media (max-width: 1000px) {
        .percent-text {
          margin-left: 12px !important;
        }
      }
    }
  }
}
</style>
