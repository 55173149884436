<template>
  <div>
    <Popup
      v-if="show_validate_modal"
      @close="show_validate_modal = false"
      max_width_model="max-w-xl  sm:w-full"
    >
      <h2
        class="text-2xl font-bold py-2 font-main text-promy text-promy-green-350 sm:text-xl"
        slot="title"
      >
        Confirmation de suppression
      </h2>
      <p
        class="text-base font-normal text-promy-gray-250 mt-4 mb-8"
        slot="body"
      >
        Etes-vous sûr.e. de vouloir supprimer votre compte ? <br />
        En cliquant sur "continuez" l'ensemble de vos données sera perdu.
      </p>
      <div slot="buttons">
        <div class="flex space-x-4 justify-center">
          <ProButton
            class="h-[50px] space-x-1 sm:w-full w-1/3 !px-6"
            @click="togglePopupConfirmationDeleteAccount"
            btn="primary"
            outline
          >
            <span> Annulez </span>
          </ProButton>
          <ProButton
            class="h-[50px] space-x-1 sm:w-full w-1/3 !px-6"
            @click="deleteAccount"
            btn="danger"
          >
            <span> Continuez </span>
          </ProButton>
        </div>
      </div>
    </Popup>
    <ProButton
      class="h-[60px] space-x-1 sm:w-full w-fit !px-6"
      @click="togglePopupConfirmationDeleteAccount"
      btn="danger-outline"
    >
      <span> Supprimer mon compte </span>
    </ProButton>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_validate_modal: false,
    }
  },
  methods: {
    togglePopupConfirmationDeleteAccount() {
      this.show_validate_modal = !this.show_validate_modal
    },
    deleteAccount() {
      this.$http.delete('grand-publics/user/delete').then((response) => {
        this.togglePopupConfirmationDeleteAccount()
        this.$store.commit('auth/SET_USER', {})
        this.$store.commit('auth/CLEAR_USER_INTENDED_ACTIONS')
        this.$store.commit('address/ResetStoreGUAddress')
        this.$router.push('/login')
      })
    },
  },
}
</script>
