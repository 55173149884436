<template>
  <button
    class="p-4 rounded-lg w-fit bg-promy-blue-100 hover:opacity-70"
    :class="classes"
    @click="handleBackClick"
  >
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9999 6.99997H4.41394L9.70694 1.70697L8.29294 0.292969L0.585938 7.99997L8.29294 15.707L9.70694 14.293L4.41394 8.99997H18.9999V6.99997Z"
        fill="#10AAAF"
      />
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
    },
    type: {
      type: String,
      default: 'link',
    },
    route_name: {
      type: String,
    },
  },
  methods: {
    handleBackClick() {
      if (this.route_name) {
        this.$router.push({ name: this.route_name, query: this.$route.query })
      } else {
        this.type && this.type === 'link'
          ? this.$router.push({ name: 'profile' })
          : this.$emit('resetComponent')
      }
    },
  },
}
</script>
