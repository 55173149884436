<template>
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5204 5.89371C16.0943 2.57464 13.221 0 9.75 0C7.0629 0 4.72875 1.5525 3.66307 4.00082C1.56878 4.62086 0 6.57643 0 8.67857C0 11.2407 2.03678 13.3216 4.5903 13.472V13.5H15.5903V13.4971L15.6 13.5C17.7509 13.5 19.5 11.7701 19.5 9.64286C19.4988 8.77839 19.2047 7.93927 18.6645 7.25962C18.1244 6.57997 17.3695 6.09905 16.5204 5.89371ZM5.85 6.75H8.775V3.85714H10.725V6.75H13.65L9.75 11.5714L5.85 6.75Z"
      class="fill-current"
      :class="'text-' + color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
