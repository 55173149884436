<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.63677 10.871C9.45916 10.871 9.30375 10.834 9.17053 10.76C9.04472 10.6786 8.98182 10.5528 8.98182 10.3826C8.98182 9.93852 8.96702 9.45007 8.93741 8.91723C8.91521 8.37698 8.88931 7.84043 8.85971 7.30759C8.8375 6.76734 8.8264 6.2789 8.8264 5.84226C8.8264 5.62764 8.90411 5.46113 9.05952 5.34272C9.21494 5.22431 9.40735 5.1651 9.63677 5.1651C9.85139 5.1651 10.0364 5.22431 10.1918 5.34272C10.3546 5.46113 10.436 5.62764 10.436 5.84226C10.436 6.2789 10.4212 6.76734 10.3916 7.30759C10.3694 7.84043 10.3435 8.37698 10.3139 8.91723C10.2917 9.45007 10.2806 9.93852 10.2806 10.3826C10.2806 10.5528 10.2066 10.6786 10.0586 10.76C9.9106 10.834 9.76999 10.871 9.63677 10.871ZM9.64788 13.3465C9.40365 13.3465 9.20014 13.2614 9.03732 13.0912C8.87451 12.921 8.7931 12.7212 8.7931 12.4917C8.7931 12.2549 8.87451 12.0551 9.03732 11.8923C9.20014 11.7221 9.40365 11.637 9.64788 11.637C9.8773 11.637 10.0734 11.7221 10.2362 11.8923C10.4064 12.0551 10.4915 12.2549 10.4915 12.4917C10.4915 12.7212 10.4064 12.921 10.2362 13.0912C10.0734 13.2614 9.8773 13.3465 9.64788 13.3465Z"
      :fill="current_color"
    />
    <circle
      r="8.625"
      transform="matrix(-1 0 0 1 9.5 9.5)"
      :stroke="current_color"
      stroke-width="1.75"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
    hoverColor: {
      type: String,
      default: 'white',
    },
    hovering: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      current_color: this.color,
    }
  },

  watch: {
    color(value) {
      this.current_color = value
    },
    hovering(value) {
      this.current_color = this.color
      if (value) this.current_color = this.hoverColor
    },
  },
}
</script>
