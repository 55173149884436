<template>
  <transition name="modal-fade">
    <div
      class="min-w-screen h-screen fixed left-0 top-0 flex justify-center items-center inset-0 z-[70] outline-none focus:outline-none"
    >
      <div
        class="absolute bg-black opacity-80 inset-0 z-0"
        @click="close"
      ></div>
      <div
        class="w-full relative mx-auto my-auto shadow-lg bg-white"
        :class="[max_width_model, container_rounded]"
      >
        <!--content-->
        <div class="h-full relative" :class="padding_popup">
          <!--Header-->
          <div class="flex-auto justify-center">
            <div class="flex justify-between items-start">
              <div>
                <slot name="title"></slot>
              </div>
              <span
                v-if="!disableClickOutside"
                class="cursor-pointer absolute rounded-full bg-promy-blue-50"
                :class="[
                  is_btn_close_outside_container
                    ? 'top-[-0.5rem] right-[-0.5rem] sm:right-0'
                    : 'top-4 right-4',
                ]"
                @click="close"
              >
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9391 9.99943L13.803 7.14179C13.9284 7.01635 13.9989 6.84623 13.9989 6.66884C13.9989 6.49146 13.9284 6.32133 13.803 6.1959C13.6776 6.07047 13.5075 6 13.3301 6C13.1528 6 12.9827 6.07047 12.8572 6.1959L10 9.06021L7.14276 6.1959C7.01735 6.07047 6.84725 6 6.66988 6C6.49252 6 6.32242 6.07047 6.19701 6.1959C6.07159 6.32133 6.00114 6.49146 6.00114 6.66884C6.00114 6.84623 6.07159 7.01635 6.19701 7.14179L9.06091 9.99943L6.19701 12.8571C6.13458 12.919 6.08504 12.9927 6.05122 13.0738C6.01741 13.155 6 13.2421 6 13.33C6 13.418 6.01741 13.505 6.05122 13.5862C6.08504 13.6674 6.13458 13.741 6.19701 13.803C6.25892 13.8654 6.33259 13.915 6.41375 13.9488C6.49491 13.9826 6.58196 14 6.66988 14C6.75781 14 6.84486 13.9826 6.92602 13.9488C7.00718 13.915 7.08085 13.8654 7.14276 13.803L10 10.9387L12.8572 13.803C12.9192 13.8654 12.9928 13.915 13.074 13.9488C13.1551 13.9826 13.2422 14 13.3301 14C13.418 14 13.5051 13.9826 13.5863 13.9488C13.6674 13.915 13.7411 13.8654 13.803 13.803C13.8654 13.741 13.915 13.6674 13.9488 13.5862C13.9826 13.505 14 13.418 14 13.33C14 13.2421 13.9826 13.155 13.9488 13.0738C13.915 12.9927 13.8654 12.919 13.803 12.8571L10.9391 9.99943Z"
                    class="fill-current text-promy-green-350"
                  />
                </svg>
              </span>
            </div>
          </div>
          <!-- Body -->
          <div
            :id="body_id ? body_id : ''"
            class="flex-auto justify-center overflow-y-auto pr-5 -mr-5"
            :class="max_height_body"
          >
            <slot name="body"></slot>
          </div>
          <!-- Buttons -->
          <div class="flex-auto justify-center">
            <slot name="buttons"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    max_height_body: {
      type: String,
      default: 'max-h-[70vh]',
    },
    max_width_model: {
      type: String,
      default: ' max-w-2xl  sm:max-w-xs',
    },
    body_id: {
      type: String,
    },
    padding_popup: {
      type: String,
      default: 'p-8',
    },
    with_btn_close: {
      type: Boolean,
      default: true,
    },
    without_padding: {
      type: Boolean,
      default: false,
    },
    is_btn_close_outside_container: {
      type: Boolean,
      default: false,
    },
    disableClickOutside: {
      type: Boolean,
      default: false,
    },
    container_rounded: {
      type: String,
      default: 'rounded-lg',
    },
  },
  methods: {
    close() {
      !this.disableClickOutside && this.$emit('close')
    },
  },
}
</script>

<style scoped>
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.1s ease;
}
</style>
