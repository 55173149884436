<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0001 19.9991C10.9242 19.9991 10.8483 19.9799 10.7802 19.9416L0.220946 14.0117C0.0766112 13.9308 -0.00872492 13.7777 0.000708768 13.6151C0.0101475 13.4532 0.11201 13.3101 0.264993 13.2457L3.10688 12.042C3.33027 11.9465 3.58904 12.047 3.68657 12.2649C3.78332 12.4827 3.68067 12.7359 3.45808 12.8299L1.41932 13.6933L11 19.0731L20.5807 13.6933L18.8282 12.9507C18.6049 12.8563 18.5026 12.6032 18.5998 12.3857C18.6961 12.1682 18.9553 12.0677 19.1791 12.1628L21.735 13.246C21.888 13.3105 21.9898 13.4536 21.9993 13.6154C22.0087 13.7777 21.9234 13.9307 21.7794 14.0121L11.2202 19.9415C11.1521 19.9795 11.0762 19.999 11.0003 19.999L11.0001 19.9991Z"
      :fill="color"
    />
    <path
      d="M11.0001 15.6311C10.9242 15.6311 10.8483 15.6119 10.7802 15.5735L0.220946 9.64409C0.0766112 9.56316 -0.00872492 9.40934 0.000708768 9.24747C0.0101475 9.0856 0.11201 8.94251 0.264993 8.87769L3.5103 7.50293C3.73369 7.40742 3.99247 7.50792 4.0896 7.72579C4.18635 7.94367 4.08449 8.19683 3.86111 8.29081L1.41882 9.32571L10.9999 14.7054L20.5806 9.32532L18.5261 8.45535C18.3027 8.36099 18.2005 8.10782 18.2976 7.89033C18.394 7.67245 18.6527 7.57157 18.8769 7.66747L21.7341 8.87766C21.8871 8.94248 21.989 9.08556 21.9984 9.24743C22.0078 9.40929 21.9225 9.56273 21.7786 9.64405L11.2193 15.5735C11.1512 15.6119 11.0753 15.631 10.9994 15.631L11.0001 15.6311Z"
      :fill="color"
    />
    <path
      d="M11.0001 11.2614C10.9242 11.2614 10.8483 11.2422 10.7802 11.2038L0.220946 5.27477C0.0766112 5.19383 -0.00872492 5.04002 0.000708768 4.87814C0.0101475 4.71627 0.11201 4.57319 0.264993 4.50837L10.8243 0.0353856C10.936 -0.0117952 11.0634 -0.0117952 11.1755 0.0353856L21.7348 4.50837C21.8877 4.57281 21.9896 4.71589 21.9991 4.87814C22.0085 5.04001 21.9231 5.19344 21.7792 5.27477L11.2199 11.2038C11.1519 11.2418 11.076 11.2614 11.0001 11.2614ZM1.4198 4.95641L11.0001 10.3357L20.5808 4.95602L11.0001 0.897327L1.4198 4.95641Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#484848',
    },
  },
}
</script>