function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
import auth from '../../middleware/auth'
import isValidatedCGU from '@/middleware/isValidatedCGU'

export default [
  {
    path: '/faq',
    name: 'faq',
    component: page('FAQ'),
  },
]
