<template>
  <pro-button :class="classes" btn="primary" @click="revelezPotentiel">
    Révélez le potentiel
  </pro-button>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    classes: {
      type: String,
      default:
        'hidden sm:block fixed z-10 bottom-0 px-6 py-3 border-promy-green-350 rounded-none w-full font-semibold',
    },
  },
  methods: {
    ...mapMutations({
      toggleGuestModal: 'auth/TOGGLE_GUEST_MODAL',
    }),
    revelezPotentiel() {
      this.$router.push({
        name: 'RevelationSteps',
        query: { id_address: this.$route.params.id_address },
      })
    },
  },
}
</script>
