<template>
  <button
    :disabled="disabled ? true : false"
    class="
      btn
      leading-relaxed
      font-medium
      flex
      items-center
      justify-center
      cursor-pointer
      border
      hover:shadow-lg
      focus:outline-none
      rounded-lg
      gap-x-2
      py-2
      px-3
    "
    :class="{
      [`btn-${btn}`]: !outline && !disabled && !active,
      ['btn-primary-outline']: btn === 'primary' && outline && !disabled,
      ['btn-primary-active']: btn === 'primary' && active && !disabled,
      [`h-10 ${icon ? 'px-3' : 'px-6'}`]: icon,
      '!rounded-full': round,
      [`btn-${btn}-disabled`]: disabled,
    }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </button>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    btn: {
      type: String,
      default: 'primary', // primary, secondary,danger
    },
    outline: Boolean,
    icon: Boolean,
    round: Boolean,
    disabled: Boolean,
    active: Boolean,
  },
}
</script>
<style lang="scss" scoped>
// Primary button style
.btn-primary {
  @apply bg-promy-green-350 text-white hover:bg-promy-green-300 border-promy-green-350;
}

.btn-primary-outline {
  @apply border-promy-green-350 text-promy-green-350 hover:bg-promy-green-350 hover:text-white bg-white;
}

.btn-primary-active {
  @apply border-promy-green-350 text-promy-green-350 bg-white;
}

// tertiary
.btn-tertiary {
  @apply bg-promy-blue-290 text-white hover:bg-promy-blue-290 border-promy-blue-290
}

.btn-tertiary-outline {
  @apply border-promy-blue-290 text-promy-blue-290 hover:bg-promy-blue-290 hover:text-white bg-white;
}

.btn-tertiary-active {
  @apply border-promy-blue-290 text-promy-blue-290 bg-white;
}




.btn-primary-disabled {
  @apply bg-promy-gray-100 border-promy-gray-100 text-promy-gray-200;
}

// Secondary button style
.btn-secondary {
  @apply bg-promy-blue-50 border-promy-blue-50 text-promy-green-350 hover:bg-promy-blue-100;
}

.btn-secondary-disabled {
  @apply bg-white text-promy-gray-200 border-white;
}

// Secondary button style
.btn-quaternary {
  @apply bg-promy-blue-110 border-promy-blue-110 text-promy-blue-290 hover:bg-promy-blue-110;
}

.btn-quaternary-disabled {
  @apply bg-white text-promy-gray-200 border-white;
}



.btn-danger {
  @apply bg-promy-red text-white hover:bg-promy-red border-promy-red;
}

.btn-danger-outline {
  @apply border-promy-red text-promy-red hover:bg-promy-red hover:text-white bg-white;
}
</style>
