import Vue from 'vue'

Vue.filter('formatEUR', function (value, fractionDigits = 2) {
  const euroformatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })

  return euroformatter.format(value)
})
