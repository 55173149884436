<template>
  <validation-provider
    ref="validatePhoneInput"
    class="flex flex-col space-y-[6px] w-full relative"
    name="phone"
    :rules="rules"
    v-slot="{ errors }"
  >
    <label v-if="label" :for="uniqueId" class="font-medium text-promy-gray-200"
      >{{ label }}
    </label>
    <vue-tel-input
      @input="handleInput"
      @country-changed="countryChanged"
      v-model="phone"
      :placeholder="'hghg'"
      :defaultCountry="'FR'"
      :dropdownOptions="dropdownOptions"
      :inputOptions="inputOptions"
      :preferredCountries="preferredCountries"
      mode="international"
      validCharactersOnly
    ></vue-tel-input>
    <span v-show="errors[0]" class="msg-error">{{ errors[0] }}</span>
    <span v-show="!is_phone_valid && phone" class="msg-error"
      >Le numéro de téléphone n'est pas valide</span
    >
  </validation-provider>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'mobile',
    },
    rules: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
      default: 'Entrez votre numéro',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      is_phone_valid: false,
      preferredCountries: ['FR', 'ES', 'BE', 'IT', 'US'],
      dialCode: null,
    }
  },
  mounted() {
    if (this.disabled) {
      let input = document.querySelector('#' + this.uniqueId)
      input.setAttribute('disabled', 'disabled')
    }
    this.$nextTick(() => {
      this.$refs.validatePhoneInput.reset()
    })
  },
  computed: {
    uniqueId() {
      return `input-${this._uid}`
    },
    phone: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    dropdownOptions() {
      return {
        showFlags: false,
        showSearchBox: true,
        disabled: this.disabled,
        width: '260px',
      }
    },
    inputOptions() {
      return {
        id: this.uniqueId,
        'aria-describedby': this.uniqueId,
        placeholder: this.placeholder,
        name: this.label,
        showDialCode: false,
        type: 'tel',
        maxlength: 25,
        styleClasses: this.disabled
          ? 'bg-promy-gray-100 border-promy-gray-100 !rounded-r-lg cursor-not-allowed'
          : 'bg-transparent',
      }
    },
  },
  methods: {
    handleInput(number, phoneObj) {
      this.is_phone_valid = phoneObj.valid
      this.$emit('is_valid', this.is_phone_valid)
    },
    countryChanged(value) {
      this.dialCode = '+' + value.dialCode
      this.$emit('dialCode', this.dialCode)
    },
  },
}
</script>
<style lang="scss" scoped>
.msg-error {
  @apply rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2;
}
.vue-tel-input {
  @apply rounded-lg border-solid border border-promy-gray-150 bg-white;
  height: 42px;

  &:hover {
    @apply border-promy-gray-200;
  }
  &:focus,
  &:focus-within {
    @apply outline-none bg-white border-promy-green-350 shadow-none;
  }
}
</style>