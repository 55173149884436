function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
import auth from '../../middleware/auth'
import isValidatedCGU from '@/middleware/isValidatedCGU'

export default [
  {
    path: 'profil',
    name: 'profile',
    component: page('Profile'),
    meta: {
      middleware: [auth, isValidatedCGU],
    },
  },
  {
    path: 'mentions-legales',
    name: 'mentions-legales',
    component: page('Profile/MentionsLegales'),
    meta: {
      middleware: [auth, isValidatedCGU],
    },
  },
  {
    path: 'cgu-profile',
    name: 'cgu-profile',
    component: page('Profile/CGUProfile'),
    meta: {
      middleware: [auth, isValidatedCGU],
    },
  },
]
