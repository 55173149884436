<template>
  <ul
    class="accordion"
    :class="{ accordionFullWidth: fullWidthWhiteBackground }"
  >
    <slot></slot>
  </ul>
</template>

<script>
export default {
  props: {
    fullWidthWhiteBackground: {
      type: Boolean,
    },
    indice: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      Accordion: {
        count: 1,
        active: null,
      },
    }
  },
  watch: {
    indice: {
      immediate: true,
      handler(val) {
        this.Accordion.active = val
      },
    },
  },
  provide() {
    return { Accordion: this.Accordion }
  },
}
</script>

<style scoped>
.accordion {
  list-style: none;
  margin: 0;
}
.accordionFullWidth {
  background-color: transparent;
  max-width: 100%;
  padding: 0;
  margin-top: 30px;
}
</style>
