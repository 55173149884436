import transport from '@/plugins/axios'
import { urlDetector } from '@/utilities/urlDetector'

export default {
  namespaced: true,
  state: {
    isLoggedIn: false,
    user: {},
    cgu: {
      data: null,
      is_validated_cgu: null,
      validated_cgu_at: null,
    },
    showGuestModal: false,
    guestUserIntendedLocation: null,
    guestUserIntendedAddress: null,
  },
  getters: {
    user(state) {
      return state.user
    },
    isLoggedIn(state) {
      return state.isLoggedIn
    },
    cgu(state) {
      return state.cgu
    },
    showGuestModal(state) {
      return state.showGuestModal
    },
    guestUserIntendedLocation(state) {
      return state.guestUserIntendedLocation
    },
    guestUserIntendedAddress(state) {
      return state.guestUserIntendedAddress
    },
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_LOGGEDIN(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn
    },
    SET_LOGOUT(state) {
      state.isLoggedIn = false
    },
    TOGGLE_GUEST_MODAL(state, payload = state.showGuestModal) {
      if (!state.isLoggedIn) {
        state.showGuestModal = !state.showGuestModal
        throw 'Login/Signup required'
      } else if (payload === false) {
        state.showGuestModal = false
      }
    },
    SET_GUEST_INTENDED_LOCATION(state, payload) {
      state.guestUserIntendedLocation = payload
    },
    SET_GUEST_INTENDED_ADDRESS(state, payload) {
      state.guestUserIntendedAddress = payload
    },
    CLEAR_USER_INTENDED_ACTIONS(state) {
      state.guestUserIntendedAddress = null
      state.guestUserIntendedLocation = null
    },
  },
  actions: {
    async logout({ commit }) {
      try {
        await transport.post('grand-publics/logout')
        commit('SET_LOGOUT')
      } catch (e) {
        throw e
      }
    },
    async me({ commit, state }) {
      return transport
        .get('/grand-publics/me')
        .then(({ data: { data } }) => {
          commit('SET_USER', data)
          commit('SET_LOGGEDIN', true)
          state.cgu.data = data.CGU
          state.cgu.data.context = urlDetector(data.CGU.context)
          state.cgu.is_validated_cgu = data.validated_CGU
          state.cgu.validated_cgu_at = data.validated_CGU_at
        })
        .catch(() => {
          transport
            .get('grand-publics/CGU', {
              params: {
                is_gugp: true,
              },
            })
            .then((response) => {
              if (response.data.hasOwnProperty('context')) {
                state.cgu.data = response.data
                state.cgu.data.context = urlDetector(response.data.context)
              }
            })

          commit('SET_LOGGEDIN', false)
        })
    },
  },
}
