<template>
  <svg
    :class="classes"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="10" fill="#fff" />
    <path
      d="M19.2572 30.4557C20.347 29.2202 20.9842 22.2192 20.9842 22.2192L23.1304 21.0166C23.1304 21.0166 29.2672 17.1455 29.2672 12.5495C29.2672 11.0669 27.4228 9.12305 25.1425 9.12305C13.288 9.12305 11.1083 20.9837 11.4771 23.7182C11.628 24.789 12.7347 24.8713 12.785 24.5254C14.1431 15.5476 18.62 10.1609 24.0694 10.1609C25.2934 10.1609 26.4 11.0339 26.4168 12.8789C26.4335 16.2888 21.1183 20.7366 21.1183 20.7366C21.1183 20.7366 22.2417 13.7355 21.8058 12.7471C21.6716 12.4506 20.85 12.6153 20.6824 12.8954C19.4751 14.8722 16.4738 29.7144 16.7588 30.4887C17.0103 31.1476 18.7877 30.9829 19.2572 30.4557Z"
      fill="#3E4D9A"
    />
  </svg>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      default: 'w-10 h-10',
    },
  },
}
</script>

<style></style>
