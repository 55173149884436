<template>
  <svg
    width="23"
    height="29"
    viewBox="0 0 23 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5434 20.0338L11.5 28.125L3.45661 20.0338C1.86581 18.4335 0.782458 16.3946 0.343562 14.1749C-0.0953331 11.9553 0.129938 9.65457 0.990888 7.5637C1.85184 5.47284 3.3098 3.68575 5.18041 2.42842C7.05101 1.1711 9.25024 0.5 11.5 0.5C13.7498 0.5 15.949 1.1711 17.8196 2.42842C19.6902 3.68575 21.1482 5.47284 22.0091 7.5637C22.8701 9.65457 23.0953 11.9553 22.6564 14.1749C22.2175 16.3946 21.1342 18.4335 19.5434 20.0338ZM10.2361 10.6711H6.44445V13.214H10.2361V17.0282H12.7639V13.214H16.5556V10.6711H12.7639V6.85693H10.2361V10.6711Z"
      class="fill-current"
      :class="'!text-' + color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
