<template>
  <div class="flex">
    <!-- <keep-alive> -->
    <component :is="ReseauxIcon" />
    <div
      class="flex flex-col space-y-2 items-end relative left-7 sm:left-0 md:left-0"
    >
      <div class="five" :class="uniqueClass"></div>
      <div class="four" :class="uniqueClass"></div>
      <div class="three" :class="uniqueClass"></div>
      <div class="two" :class="uniqueClass"></div>
      <div class="one" :class="uniqueClass"></div>
    </div>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  props: {
    reseauxDegree: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ReseauxIcon() {
      return `Reseaux_${this.type}`
    },
    uniqueClass() {
      return `colorClassfor_${this.type}`
    },
  },
  mounted() {
    let cols = document.getElementsByClassName(this.uniqueClass)
    cols = [...cols].reverse()
    for (var i = 0; i < this.reseauxDegree; i++) {
      cols[i].style.backgroundColor = '#10AAAF'
    }
  },
}
</script>

<style scoped>
.five {
  width: 15.17px;
  height: 45.51px;
  background-color: #e9e9e9;
}
.four {
  width: 12.32px;
  height: 36.97px;
  background: #e9e9e9;
}
.three {
  width: 9.48px;
  height: 28.44px;
  background: #e9e9e9;
}
.two {
  width: 6.64px;
  height: 19.91px;
  background: #e9e9e9;
}
.one {
  width: 4.74px;
  height: 14.22px;
  background: #e9e9e9;
}
</style>
