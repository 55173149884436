<template>
<svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.6981 4.25391H25.9071C26.1039 4.25391 26.2644 4.41384 26.2644 4.61114V25.0551C26.2644 25.2519 26.1039 25.4124 25.9071 25.4124H21.6981C21.5013 25.4124 21.3408 25.2519 21.3408 25.0551V4.61114C21.3408 4.41409 21.5013 4.25391 21.6981 4.25391ZM25.5497 4.96811H22.0556V24.6972H25.5497V4.96811Z"   :fill="current_color"
      :stroke="current_color" stroke-width="0.5"/>
<path d="M15.5848 8.76562H19.7936C19.9904 8.76562 20.1508 8.92605 20.1508 9.12286V25.0568C20.1508 25.2536 19.9904 25.414 19.7936 25.414H15.5848C15.3877 25.414 15.2273 25.2536 15.2273 25.0568V9.12286C15.2273 8.92606 15.3877 8.76562 15.5848 8.76562ZM19.4361 9.48034H15.942V24.6993H19.4361V9.48034Z"   :fill="current_color"
      :stroke="current_color" stroke-width="0.5"/>
<path d="M9.47199 13.6738H13.6808C13.8779 13.6738 14.038 13.8343 14.038 14.0313V25.0546C14.038 25.2514 13.8781 25.4119 13.6808 25.4119H9.47199C9.27494 25.4119 9.1145 25.2514 9.1145 25.0546V14.0313C9.1145 13.8343 9.27493 13.6738 9.47199 13.6738ZM13.3236 14.3885H9.82899V24.6969H13.3236V14.3885Z"   :fill="current_color"
      :stroke="current_color" stroke-width="0.5"/>
<path d="M3.3587 18.3848H7.56752C7.76457 18.3848 7.92501 18.5452 7.92501 18.742V25.0551C7.92501 25.2519 7.76458 25.4123 7.56752 25.4123H3.3587C3.16165 25.4123 3.00146 25.2519 3.00146 25.0551V18.742C3.00146 18.5452 3.1614 18.3848 3.3587 18.3848ZM7.21032 19.0995H3.71571V24.6976H7.21032V19.0995Z"   :fill="current_color"
      :stroke="current_color" stroke-width="0.5"/>
<path d="M18.892 3.14436C18.9281 3.33767 18.8006 3.52401 18.6073 3.55963C18.414 3.59575 18.2276 3.46821 18.192 3.27489L18.0221 2.37855C14.3476 8.57182 8.09571 12.3923 1.47725 14.8189C1.03632 14.9801 0.792197 14.3097 1.23187 14.149C7.69089 11.7809 13.8215 8.0631 17.41 2.01606L16.5072 2.34166C16.3226 2.40768 16.1184 2.31127 16.0526 2.12667C15.9868 1.94208 16.083 1.7378 16.2678 1.67203L18.0518 1.02779L18.1253 1.00612C18.3186 0.969996 18.5049 1.09754 18.541 1.29086L18.892 3.14436Z"   :fill="current_color"
      :stroke="current_color" stroke-width="0.5"/>
</svg>


</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
    hoverColor: {
      type: String,
      default: 'white',
    },
    hovering: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      current_color: this.color,
    }
  },

  watch: {
    color(value) {
      this.current_color = value
    },
    hovering(value) {
      this.current_color = this.color
      if (value) this.current_color = this.hoverColor
    },
  },
}
</script>
