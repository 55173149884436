<template>
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.65685 6.34315C5.84439 6.15561 6.09874 6.05025 6.36396 6.05025C6.62918 6.05025 6.88353 6.15561 7.07107 6.34315L11.3137 10.5858L15.5563 6.34315C15.7439 6.15561 15.9982 6.05025 16.2635 6.05025C16.5287 6.05025 16.783 6.15561 16.9706 6.34315C17.1581 6.53068 17.2635 6.78504 17.2635 7.05025C17.2635 7.31547 17.1581 7.56982 16.9706 7.75736L12.7279 12L16.9706 16.2426C17.1581 16.4302 17.2635 16.6845 17.2635 16.9497C17.2635 17.215 17.1581 17.4693 16.9706 17.6569C16.783 17.8444 16.5287 17.9497 16.2635 17.9497C15.9982 17.9497 15.7439 17.8444 15.5563 17.6569L11.3137 13.4142L7.07107 17.6569C6.88353 17.8444 6.62918 17.9497 6.36396 17.9497C6.09874 17.9497 5.84439 17.8444 5.65685 17.6569C5.46932 17.4693 5.36396 17.215 5.36396 16.9497C5.36396 16.6845 5.46932 16.4302 5.65685 16.2426L9.89949 12L5.65685 7.75736C5.46932 7.56982 5.36396 7.31547 5.36396 7.05025C5.36396 6.78504 5.46932 6.53068 5.65685 6.34315V6.34315Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
