function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}

import auth from '../../middleware/auth'
import isValidatedCGU from '@/middleware/isValidatedCGU'

export default [
  {
    path: '/revelation/steps',
    name: 'RevelationSteps',
    component: page('NewTunnel'),
    redirect: '/revelation/steps/1/:id_revelation?',
    children: [
      {
        path: '1/:id_revelation?',
        name: 'revelationStep1',
        component: page('NewTunnel/Step1'),
      },
      {
        path: '2/:id_revelation?',
        name: 'revelationStep2',
        component: page('NewTunnel/Step2'),
      },
      {
        path: '3/:id_revelation?',
        name: 'revelationStep3',
        component: page('NewTunnel/Step3'),
      },
      {
        path: '4/:id_revelation?',
        name: 'revelationStep4',
        component: page('NewTunnel/Step4'),
      },
      {
        path: '5/:id_revelation?',
        name: 'revelationStep5',
        component: page('NewTunnel/Step5'),
      },
      {
        path: '6/:id_revelation?',
        name: 'revelationStep6',
        component: page('NewTunnel/Step6'),
      },
      {
        path: '7/:id_revelation?',
        name: 'revelationStep7',
        component: page('NewTunnel/Step7'),
      },
    ],
  },
]
