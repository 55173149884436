<template>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M19.5961 19.5967H16.1882V14.2597C16.1882 12.987 16.1655 11.3487 14.4158 11.3487C12.6409 11.3487 12.3693 12.7354 12.3693 14.1671V19.5963H8.96148V8.62136H12.233V10.1212H12.2788C12.6062 9.5614 13.0793 9.10087 13.6477 8.78866C14.2161 8.47646 14.8586 8.32428 15.5067 8.34833C18.9607 8.34833 19.5975 10.6203 19.5975 13.576L19.5961 19.5967ZM5.11617 7.12124C4.02393 7.12142 3.13834 6.2361 3.13816 5.14387C3.13798 4.05164 4.02322 3.16605 5.11545 3.16587C6.20768 3.1656 7.09327 4.05092 7.09345 5.14315C7.09354 5.66766 6.88528 6.17073 6.51447 6.54169C6.14366 6.91265 5.64068 7.12112 5.11617 7.12124ZM6.82014 19.5968H3.40868V8.62136H6.82005V19.5967L6.82014 19.5968ZM21.2951 0.000941537H1.69626C0.769973 -0.00948034 0.0103438 0.732539 -0.000976562 1.65883V21.3394C0.00998438 22.2661 0.769524 23.0089 1.69617 22.9991H21.2951C22.2237 23.0106 22.9862 22.2679 22.999 21.3394V1.6573C22.9858 0.729215 22.2232 -0.0127147 21.2951 -0.000585807"
        :fill="color ? color : '#0A66C2'"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="23" height="23" :fill="color ? color : '#0A66C2'" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
    },
  },
}
</script>
