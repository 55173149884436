<template>
  <div class="w-11 h-11">
    <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M29.2784 23.6491L34.3488 31.1236L25.1721 32.8443C24.3696 32.9947 23.5422 32.9439 22.7641 32.6963L9.65039 28.5238V25.274L14.3958 24.624L18.0999 22.9991"
          :stroke="color"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.9499 11.8281C20.9222 11.8281 18.4658 14.166 18.4658 17.0455C18.4658 20.3588 22.1218 25.6094 23.4643 27.4229C23.52 27.4995 23.593 27.5618 23.6774 27.6047C23.7618 27.6477 23.8552 27.67 23.9499 27.67C24.0445 27.67 24.1379 27.6477 24.2223 27.6047C24.3067 27.5618 24.3797 27.4995 24.4354 27.4229C25.7779 25.6101 29.4339 20.3615 29.4339 17.0455C29.4339 14.166 26.9775 11.8281 23.9499 11.8281Z"
          :stroke="color"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.9501 19.14C24.9597 19.14 25.7781 18.3216 25.7781 17.312C25.7781 16.3024 24.9597 15.484 23.9501 15.484C22.9405 15.484 22.1221 16.3024 22.1221 17.312C22.1221 18.3216 22.9405 19.14 23.9501 19.14Z"
          :stroke="color"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#10AAAF',
    },
  },
}
</script>

<style></style>
