<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden shadow-box w-full px-5 sm:px-3 py-3 bg-white"
  >
    <div class="flex text-promy-green-350 font-main font-bold sm:text-sm">
      {{ title }}
    </div>
    <div
      class="flex justify-between items-end py-8 sm:py-4 pl-6 sm:px-0"
      v-if="dataset.length"
    >
      <div class="relative w-1/2 md:w-2/5">
        <canvas :id="chartId" class="w-full" />
        <div
          class="absolute top-1/2 left-1/2 flex flex-col items-center leading-4 transform -translate-y-1/2 -translate-x-1/2"
          v-if="unit !== '%'"
        >
          <span class="font-bold text-lg text-promy-dark-purple">{{
            totalDataset | formatNumber
          }}</span>
          <span class="text-promy-xs text-promy-gray-200 font-bold">{{
            unit
          }}</span>
        </div>
      </div>
      <div class="font-main text-promy-gray-200 ml-6 md:ml-0">
        <div
          class="flex gap-6 justify-between"
          v-for="(item, index) in labels"
          :key="index"
        >
        <div class="flex items-center gap-2">
          <span class="w-2 h-2 rounded-full"  :style="`background: ${colors[index]}`"></span>
          <span class="text-sm leading-5 font-normal text-promy-grey-70">
              {{ item }}
          </span>
        </div>
        <span class="text-sm leading-5 font-medium text-promy-grey-100">
          {{ percent(Math.round(dataset[index]), totalDataset) }}%
        </span>
    
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chartId: {
      type: String,
    },
    title: {
      type: String,
    },
    labels: {
      type: Array,
    },
    dataset: {
      type: Array,
    },
    with_percentage: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
      default: '%',
    },
  },

  data() {
    return {
      cutout: 80,
      colors: [
        '#1D63EB',
        '#1DEBEB',
        '#1DEBAD',
        '#CE1DEB',
        '#EB421D',
        '#3AEB1D',
        '#EB1DBE',
        '#9DEB1D',
      ],
      totalDataset: 0,
    }
  },

  mounted() {
    if (this.dataset.length) {
      this.renderPie()
    }
  },
  methods: {
    percent(value, total) {
        return Math.round((value / total) * 100)
    },
    renderPie() {
      switch (this.$mq) {
        case 'sm':
          this.cutout = 40
          break
        case 'md':
          this.cutout = 90
          break
      }
      var doghnut1 = document.getElementById(this.chartId)

      this.totalDataset = this.dataset.reduce((acc, cur) => acc + cur, 0)

      new this.$chart(doghnut1, {
        type: 'doughnut',
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.title,
              data: this.dataset,
              backgroundColor: this.colors,
              borderWidth: 0,
              width: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          suffixe: this.suffixe,
          cutout: '80%',
          animation: {
            animateRotate: true,
            animateScale: true,
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  const valueInPercentage = Math.round(
                    (context.parsed / this.totalDataset) * 100,
                  )
                  return `${context.label} : ${valueInPercentage}${
                    (this.with_percentage && '%') || ''
                  }`
                },
              },
            },
          },
        },
      })
    },
  },
}
</script>

<style></style>
