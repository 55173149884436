<template>
  <div
    class="bg-white hover:bg-promy-gray-100 hover:shadow-lg text-promy-primary md:text-sm rounded-xl cursor-pointer"
    @click="handleClick"
  >
    <div
      class="w-full py-5 sm:py-3 px-7 lg:px-6 flex sm:flex-col justify-between items-center sm:items-start "
    >
      <div class="flex items-center space-x-7 lg:space-x-6 sm:space-x-3">
        <ProIcon
          v-if="icon"
          :name="icon"
          color="#10AAAF"
          class="!w-6 !h-6 sm:!w-4 sm:!h-4"
        />
        <p>
          <strong>{{ title }}</strong>
          <br />
          <span v-if="subtitle">{{ subtitle }}</span>
        </p>
      </div>
      <p
        v-if="status && status === 'active'"
        class="sm:self-end text-promy-actions-warning flex items-center space-x-1"
      >
        <span class="text-xl">•</span><span>En cours</span>
      </p>
      <p
        v-if="status && status === 'finish'"
        class="sm:ml-8 text-promy-actions-success flex items-center space-x-1"
      >
        <span class="text-xl">•</span><span>Terminée</span>
      </p>
      <p
        v-if="status && status === 'canceled'"
        class="sm:ml-8 text-promy-actions-danger flex items-center space-x-1"
      >
        <span class="text-xl">•</span><span>Annulée</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleClick() {
      this.$emit('handleClick')
    },
  },
}
</script>
