function urlDetector(string) {
  const URLMatcher =
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim
  return string.replace(URLMatcher, (match) => {
    return `<a href="${match.replace(
      '&nbsp',
      '',
    )}" target="_blank" class=" text-promy-green-300">${match}</a>`
  })
}
export { urlDetector }
