<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1251 4.5037L7.88967 1.15417C7.68141 1.05014 7.45133 0.997322 7.21856 1.0001C6.98578 1.00289 6.75703 1.06119 6.55132 1.17017L1.38898 4.63397C1.27154 4.69619 1.17326 4.78924 1.10473 4.90311C1.0362 5.01699 0.999988 5.14738 1 5.28029V18.8009C0.999989 18.927 1.0326 19.051 1.09465 19.1608C1.15671 19.2706 1.24611 19.3625 1.35417 19.4276C1.46222 19.4926 1.58527 19.5286 1.71134 19.5321C1.83742 19.5355 1.96225 19.5063 2.0737 19.4472L6.54492 16.5315C6.75231 16.4216 6.98309 16.3633 7.21778 16.3613C7.45247 16.3594 7.68418 16.414 7.89333 16.5205L13.0063 19.8572C13.2136 19.9629 13.4432 20.0175 13.6759 20.0167C13.9085 20.0158 14.1377 19.9594 14.3442 19.8522L19.6203 16.3811C19.7392 16.3194 19.8389 16.2263 19.9084 16.1118C19.978 15.9973 20.0148 15.866 20.0148 15.732V2.21507C20.0148 2.08894 19.9822 1.96496 19.9202 1.85516C19.8581 1.74536 19.7687 1.65347 19.6606 1.58843C19.5526 1.52338 19.4295 1.48739 19.3035 1.48394C19.1774 1.48049 19.0526 1.50971 18.9411 1.56875L14.4617 4.4877C14.2562 4.59638 14.0278 4.65451 13.7953 4.6573C13.5629 4.66008 13.3332 4.60743 13.1251 4.5037Z"
      :stroke="current_color"
      stroke-width="1.46268"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.7985 4.65723V20.0153"
      :stroke="current_color"
      stroke-width="1.46268"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.21643 1.00061V16.3587"
      :stroke="current_color"
      stroke-width="1.46268"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
    hoverColor: {
      type: String,
      default: 'white',
    },
    hovering: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      current_color: this.color,
    }
  },

  watch: {
    color(value) {
      this.current_color = value
    },
    hovering(value) {
      this.current_color = this.color
      if (value) this.current_color = this.hoverColor
    },
  },
}
</script>
