<template>
  <svg
    width="19"
    height="27"
    viewBox="0 0 19 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.450193 0C0.204578 0.0263207 -0.00127458 0.264916 5.94225e-06 0.517638V23.3148C5.94225e-06 23.5847 0.244121 23.8325 0.507477 23.8325H2.89199V26.3443C2.86848 26.8594 3.52241 26.9302 3.77315 26.6702L5.27678 24.9349L6.73253 26.8043C7.06087 27.2131 7.65555 26.9216 7.63268 26.4784V23.8325H18.4925C18.7561 23.8325 19 23.5847 19 23.3148V0.517638C19 0.247797 18.7559 0 18.4925 0H0.450193ZM1.00553 1.03525H17.9944V22.7971H7.63253V21.666C8.14363 21.0848 8.4658 20.3229 8.4658 19.4803C8.4658 17.6808 7.03423 16.2112 5.26727 16.2112C3.50994 16.2112 2.05911 17.678 2.05911 19.4803C2.05911 20.3283 2.37548 21.1034 2.89239 21.6852V22.7973H1.00569L1.00553 1.03525ZM6.62726 22.4421V25.0112L5.69822 23.8032C5.5131 23.5676 5.11377 23.5631 4.92246 23.7938L3.89766 24.9729V22.4517C4.84524 22.7337 5.88307 22.7823 6.62698 22.4423L6.62726 22.4421Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.13219 5.72159C2.47676 5.72758 2.50095 6.76133 3.13219 6.75213H15.8773C16.5901 6.75299 16.4502 5.71255 15.8773 5.72159H3.13219Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.13218 9.50753C2.49258 9.51887 2.52722 10.5473 3.13218 10.5381H15.8773C16.5754 10.5543 16.4946 9.51372 15.8773 9.50753H3.13218Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.13219 12.7702C2.47168 12.7702 2.48536 13.826 3.13219 13.8008H15.8773C16.5641 13.8164 16.4927 12.761 15.8773 12.7702H3.13219Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.69591 16.313C7.97553 16.3044 8.07491 17.3526 8.69591 17.3435H15.0684C15.7813 17.3435 15.6599 16.3192 15.0684 16.313H8.69591Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#484848',
    },
  },
}
</script>
