<template>
  <div class="font-main text-promy-purply-900">
    <portal to="navbar-title">
      <h2 class="text-2xl xl:text-xl sm:text-base capitalize">Compte</h2>
    </portal>
    <h1 class="text-2xl lg:text-xl font-bold mt-4 mb-10">Votre compte Promy</h1>
    <div
      class="flex lg:flex-col justify-between space-x-6 lg:space-x-0 lg:space-y-6"
    >
      <div class="w-1/3 lg:w-full h-full py-6 px-7 bg-white">
        <div class="flex items-center">
          <div
            class="w-12 h-12 flex justify-center items-center rounded-lg bg-promy-gray-50"
          >
            <UserIcon color="#10AAAF" />
          </div>
          <div class="ml-6">
            <p class="font-semibold text-base lg:text-sm leading-none">
              {{ user.prenom }} {{ user.nom }}
            </p>
          </div>
          <div class="ml-auto">
            <UpdateIcon @click.native="updateProfile" class="cursor-pointer" />
          </div>
        </div>
        <div class="my-12">
          <div class="my-6">
            <span class="text-promy-gray-200 font-medium text-sm"> Email </span>
            <p class="font-semibold text-base lg:text-sm leading-none">
              {{ user.email }}
            </p>
          </div>
          <hr />
          <div class="my-6">
            <span class="text-promy-gray-200 font-medium text-sm">
              Téléphone
            </span>
            <p class="font-semibold text-base lg:text-sm leading-none">
              {{ user.telephone_mobile }}
            </p>
          </div>
        </div>
        <pro-button
          class="w-full flex justify-center font-semibold mb-4 py-3 border-promy-green-350 rounded-lg"
          btn="primary"
          @click="$router.push({ name: 'revelations' })"
        >
          Mes révélations
        </pro-button>
        <pro-button
          class="w-full hidden justify-center font-semibold py-3 border-promy-green-350 rounded-lg"
          btn="secondary"
        >
          Gérez votre projet immobllier
        </pro-button>
      </div>
      <div class="w-1/3 lg:w-full h-full py-6 px-7 bg-white">
        <div class="flex items-center">
          <div
            class="w-12 h-12 flex justify-center items-center rounded-lg bg-promy-gray-50"
          >
            <MessageIcon color="#10AAAF" />
          </div>
          <div class="ml-6">
            <p class="font-semibold text-base lg:text-sm leading-none">
              Centre d’aide
            </p>
            <span class="text-promy-gray-200 font-medium text-sm">
              Contactez-nous avec vos questions
            </span>
          </div>
        </div>
        <div class="my-12">
          <div
            class="my-8 flex justify-between items-center cursor-pointer"
            @click="$router.push('/faq#faq')"
          >
            <p class="font-semibold text-base lg:text-sm leading-none">
              FAQ et assistance
            </p>
            <ArrowIcon />
          </div>
          <hr />
          <div
            class="my-8 flex justify-between items-center cursor-pointer"
            @click="$router.push('/faq#contact_us')"
          >
            <p class="font-semibold text-base lg:text-sm leading-none">
              Contactez-nous
            </p>
            <ArrowIcon />
          </div>
        </div>
      </div>
      <div class="flex flex-col w-1/3 lg:w-full">
        <div class="py-6 px-7 bg-white">
          <div class="flex items-center">
            <div
              class="w-12 h-12 flex justify-center items-center rounded-lg bg-promy-gray-50"
            >
              <ShieldIcon color="#10AAAF" />
            </div>
            <div class="ml-6">
              <p class="font-semibold text-base lg:text-sm leading-none">
                Conditions et Politiques
              </p>
              <p class="text-promy-gray-200 font-medium text-sm mt-1">
                Consultez nos conditions d'utilisation et mentions légales
              </p>
            </div>
          </div>
          <div class="my-12">
            <div
              class="my-8 flex justify-between items-center cursor-pointer hover:underline"
              @click="$router.push({ name: 'mentions-legales' })"
            >
              <p class="font-semibold text-base lg:text-sm w-3/4 leading-6">
                Mentions légales et politique de protection des données
              </p>
              <ArrowIcon />
            </div>
            <hr />
            <div
              class="my-8 flex justify-between items-center cursor-pointer hover:underline"
              @click="$router.push({ name: 'cgu-profile' })"
            >
              <p class="font-semibold text-base lg:text-sm leading-none">
                Conditions générales d’utilisation
              </p>
              <ArrowIcon />
            </div>
          </div>
        </div>
        <div class="bg-white mt-4 flex justify-center py-6">
          <DeleteAccount></DeleteAccount>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateProfile() {
      this.$emit('enableUpdatingProfile', true)
    },
  },
}
</script>
