<template>
  <svg
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.98462 5.625C11.5355 5.625 12.7971 4.3634 12.7971 2.8125C12.7971 1.2616 11.5355 0 9.98462 0C8.4339 0 7.17212 1.2616 7.17212 2.8125C7.17212 4.3634 8.4339 5.625 9.98462 5.625Z"
      :fill="color"
    />
    <path
      d="M16.9358 19.0109C16.4083 18.8928 15.8318 18.7901 15.2175 18.7035C14.8374 18.65 14.4831 18.9258 14.4329 19.3039C14.3828 19.6811 14.6406 20.0429 15.0222 20.0966C15.4466 20.1563 15.8511 20.224 16.2307 20.2994C17.8685 20.6246 18.5673 20.9978 18.7872 21.1875C18.5673 21.3772 17.8685 21.7504 16.2307 22.0758C14.5481 22.4097 12.3799 22.5938 10.1252 22.5938C7.87042 22.5938 5.70209 22.4097 4.01953 22.0756C2.38165 21.7504 1.68311 21.3772 1.4632 21.1875C1.68311 20.9978 2.38165 20.6246 4.01953 20.2994C4.31689 20.2403 4.62946 20.1861 4.95483 20.1365C5.33038 20.0795 5.586 19.7241 5.53857 19.3511C5.48987 18.9679 5.13373 18.6866 4.74792 18.7452C4.23962 18.8225 3.7597 18.9113 3.31458 19.0109C1.08417 19.5103 0 20.2222 0 21.1875C0 22.1528 1.08417 22.8648 3.31458 23.3641C5.14636 23.7742 7.56519 24 10.1252 24C12.6852 24 15.1038 23.7742 16.9358 23.3641C19.1662 22.8648 20.2504 22.1528 20.2504 21.1875C20.2504 20.2222 19.1662 19.5103 16.9358 19.0109Z"
      :fill="color"
    />
    <path
      d="M6.91382 14.3115V19.2188C6.91382 20.3818 7.86011 21.3281 9.02319 21.3281H10.9462C12.1093 21.3281 13.0555 20.3818 13.0555 19.2188V14.3115C13.7583 13.9634 14.2252 13.2424 14.2252 12.4219V9.14062C14.2252 7.97754 13.2789 7.03125 12.1158 7.03125H7.85352C6.69043 7.03125 5.74414 7.97754 5.74414 9.14062V12.4219C5.74414 13.2424 6.21124 13.9634 6.91382 14.3115Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
