<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 0C5.60752 0 0 5.60752 0 12.5C0 19.3925 5.60752 25 12.5 25C19.3925 25 25 19.3925 25 12.5C25 5.60752 19.3925 0 12.5 0ZM12.5 23.0211C6.69853 23.0211 1.97891 18.3011 1.97891 12.5C1.97891 6.69888 6.69853 1.97891 12.5 1.97891C18.3011 1.97891 23.0211 6.69922 23.0211 12.5003C23.0211 18.3015 18.3011 23.0211 12.5 23.0211Z"
      :fill="color"
    />
    <path
      d="M12.5 14.718C8.98452 14.718 5.69526 16.4808 3.23877 19.6813L4.80869 20.8858C6.88223 18.1847 9.61377 16.6969 12.5 16.6969C15.3862 16.6969 18.1177 18.1847 20.191 20.8858L21.7609 19.6813C19.3044 16.4808 16.0155 14.718 12.5 14.718Z"
      :fill="color"
    />
    <path
      d="M12.5001 4.48548C9.66303 4.48548 7.35498 6.80838 7.35498 9.66356C7.35498 12.5187 9.66303 14.8417 12.5001 14.8417C15.3372 14.8417 17.6452 12.5188 17.6452 9.66361C17.6452 6.80843 15.3372 4.48548 12.5001 4.48548ZM12.5001 12.8628C10.7544 12.8628 9.33389 11.4278 9.33389 9.66356C9.33389 7.89935 10.7544 6.46434 12.5001 6.46434C14.2458 6.46434 15.6663 7.89969 15.6663 9.66356C15.6663 11.4274 14.2458 12.8628 12.5001 12.8628Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
