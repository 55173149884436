<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 11.25C12.1685 11.25 11.8505 11.3817 11.6161 11.6161C11.3817 11.8505 11.25 12.1685 11.25 12.5V17.5C11.25 17.8315 11.3817 18.1495 11.6161 18.3839C11.8505 18.6183 12.1685 18.75 12.5 18.75C12.8315 18.75 13.1495 18.6183 13.3839 18.3839C13.6183 18.1495 13.75 17.8315 13.75 17.5V12.5C13.75 12.1685 13.6183 11.8505 13.3839 11.6161C13.1495 11.3817 12.8315 11.25 12.5 11.25ZM12.975 6.35C12.6707 6.22498 12.3293 6.22498 12.025 6.35C11.8716 6.40949 11.7314 6.4987 11.6125 6.6125C11.5021 6.734 11.4133 6.87352 11.35 7.025C11.28 7.17335 11.2458 7.33603 11.25 7.5C11.2491 7.66451 11.2806 7.82759 11.3428 7.97988C11.405 8.13218 11.4967 8.2707 11.6125 8.3875C11.734 8.49791 11.8735 8.5867 12.025 8.65C12.2144 8.7278 12.42 8.7579 12.6237 8.73764C12.8274 8.71739 13.023 8.6474 13.1934 8.53384C13.3637 8.42027 13.5036 8.26661 13.6006 8.08634C13.6977 7.90607 13.749 7.70473 13.75 7.5C13.7454 7.16904 13.6159 6.85204 13.3875 6.6125C13.2686 6.4987 13.1284 6.40949 12.975 6.35ZM12.5 0C10.0277 0 7.61099 0.733112 5.55538 2.10663C3.49976 3.48015 1.89761 5.43238 0.951512 7.71646C0.00541605 10.0005 -0.242126 12.5139 0.24019 14.9386C0.722505 17.3634 1.91301 19.5907 3.66117 21.3388C5.40933 23.087 7.63661 24.2775 10.0614 24.7598C12.4861 25.2421 14.9995 24.9946 17.2836 24.0485C19.5676 23.1024 21.5199 21.5002 22.8934 19.4446C24.2669 17.389 25 14.9723 25 12.5C25 10.8585 24.6767 9.23303 24.0485 7.71646C23.4203 6.19989 22.4996 4.8219 21.3388 3.66117C20.1781 2.50043 18.8001 1.57969 17.2836 0.951506C15.767 0.323322 14.1415 0 12.5 0ZM12.5 22.5C10.5222 22.5 8.58879 21.9135 6.9443 20.8147C5.29981 19.7159 4.01809 18.1541 3.26121 16.3268C2.50433 14.4996 2.3063 12.4889 2.69215 10.5491C3.07801 8.60929 4.03041 6.82746 5.42894 5.42893C6.82746 4.03041 8.60929 3.078 10.5491 2.69215C12.4889 2.3063 14.4996 2.50433 16.3268 3.26121C18.1541 4.01808 19.7159 5.29981 20.8147 6.9443C21.9135 8.58879 22.5 10.5222 22.5 12.5C22.5 15.1522 21.4464 17.6957 19.5711 19.5711C17.6957 21.4464 15.1522 22.5 12.5 22.5Z"
      fill="#10AAAF"
    />
  </svg>
</template>
<script>
export default {}
</script>
