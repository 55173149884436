<template>
  <svg viewBox="0 0 27 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2267 23.0327H24.5886C24.9931 23.0327 25.364 23.141 25.7012 23.2856V20.6501C25.7012 16.9675 22.9028 13.971 19.4639 13.971H12.8221C9.9226 13.971 7.49512 16.065 6.78711 18.9533C9.41687 19.1699 11.8107 20.6862 13.2267 23.0328V23.0327Z"
      :fill="color"
    />
    <path
      d="M21.6562 6.17188C21.6562 9.44198 19.1807 12.0928 16.1269 12.0928C13.0731 12.0928 10.5977 9.44198 10.5977 6.17188C10.5977 2.90178 13.0731 0.250977 16.1269 0.250977C19.1807 0.250977 21.6562 2.90178 21.6562 6.17188Z"
      :fill="color"
    />
    <path
      d="M26.881 27.6886L24.8244 25.6306C24.7569 25.5583 24.6558 25.5223 24.5547 25.5223H12.7207C12.2151 25.5223 11.7431 25.2335 11.507 24.764C10.4619 22.7783 8.47277 21.4425 6.18003 21.4066C2.94328 21.3706 0.178775 24.1503 0.0102002 27.6163C-0.192053 31.4072 2.63996 34.5481 6.14632 34.5481C8.43881 34.5481 10.4282 33.2123 11.5069 31.1906C11.743 30.7212 12.2151 30.4686 12.7207 30.4686H15.0471C15.1482 30.4686 15.2493 30.4326 15.3168 30.3603L16.4631 29.1329C16.6317 28.9523 16.8676 28.9523 17.0025 29.1329L18.1488 30.3603C18.2163 30.4326 18.3174 30.4686 18.4185 30.4686H19.5311C19.6323 30.4686 19.7334 30.4326 19.8009 30.3603L20.9471 29.1329C21.1158 28.9523 21.3516 28.9523 21.4866 29.1329L22.6329 30.3603C22.7004 30.4326 22.8015 30.4686 22.9026 30.4686H24.4872C24.5884 30.4686 24.6895 30.4326 24.757 30.3603L26.8136 28.3023C27.0495 28.1218 27.0495 27.8329 26.8811 27.6886L26.881 27.6886ZM4.29203 29.9629C3.28052 29.9629 2.43765 29.0603 2.43765 27.9772C2.43765 26.894 3.28052 25.9914 4.29203 25.9914C5.30353 25.9914 6.14641 26.894 6.14641 27.9772C6.14641 29.0603 5.30353 29.9629 4.29203 29.9629Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#C1C1C1",
    },
  },
};
</script>
