<template>
  <div class="relative w-10 h-10 rounded-full" v-click-outside="closeModal">
    <transition name="slide-fade" mode="out-in">
      <TipsModal v-if="open" @close="closeModal">
        <div class="pr-12 flex items-start space-x-2">
          <MapRotationIcon color="#10AAAF" />
          <p class="w-56 text-sm">
            Pour modifier l'<strong>orientation</strong>, maintenez
            <strong>clic droit et bougez votre souris.</strong>
          </p>
        </div>
      </TipsModal>
    </transition>
    <div
      @click="open = !open"
      class="
        bg-promy-green-350
        w-full
        h-full
        flex
        justify-center
        items-center
        rounded-full
        cursor-pointer
        relative
        hover:bg-white
      "
      :class="{ 'border-2 border-promy-green-350': open }"
    >
      <InfoIcon :color="hovering ? '#10AAAF' : 'white'" />
      <div
        class="absolute top-0 left-0 w-full h-full rounded-full z-10"
        @mouseover="toggleTip($event)"
        @mouseleave="toggleTip($event)"
      ></div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },

  data() {
    return {
      hovering: false,
      open: false,
    }
  },
  methods: {
    closeModal() {
      this.open = false
    },
    toggleTip(e) {
      e.stopPropagation()
      this.hovering = !this.hovering;
      if (!navigator.userAgentData.mobile && window.screen.availWidth > 1025) this.open = !this.open
    }
  },
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
