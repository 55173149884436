<template>
  <button
    class="h-12 py-1 px-3.5 flex items-center border rounded-full hover:shadow-lg focus:outline-none cursor-pointer leading-relaxed font-medium"
    :class="{
      ['btn-google']: btn === 'google',
      ['btn-facebook']: btn === 'facebook',
      ['btn-linkedin']: btn === 'linkedin',
      ['btn-apple']: btn === 'apple',
    }"
    v-bind="$attrs"
    v-on="$listeners"
    @mouseover="isHover = true"
    @mouseout="isHover = false"
  >
    <ProIcon
      :name="btn"
      :color="isHover ? 'white' : ''"
      class="ml-8 xl:ml-0 sm:ml-0 text-xl text-blue-500 social"
    />
    <span class="!mr-10 xl:hidden sm:hidden">
      Continuer avec {{ btn | upperfirstchar }}
    </span>
  </button>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    btn: {
      type: String,
      default: "google", // google, facebook, linkedin
    },
    classes: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  filters: {
    upperfirstchar: function (value) {
      if (!value) {
        return "";
      }

      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>
<style lang="scss" scoped>
// google button style
.btn-google {
  &:hover {
    @apply bg-[#4A90E2] text-white;
  }
}
// facebook button style
.btn-facebook {
  &:hover {
    @apply bg-[#395185] text-white;
  }
}
// linkedin button style
.btn-linkedin {
  &:hover {
    @apply bg-[#0A66C2] text-white;
  }
}
// apple button style
.btn-apple {
  &:hover {
    @apply bg-[#b3b3b3] text-white;
  }
}
</style>
