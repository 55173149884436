<template>
  <!-- <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5996 23.3999H13.7996M8.99961 17.7999H15.3996M6.59961 12.9999C6.59961 11.5147 7.18961 10.0903 8.23981 9.0401C9.29002 7.9899 10.7144 7.3999 12.1996 7.3999C13.6848 7.3999 15.1092 7.9899 16.1594 9.0401C17.2096 10.0903 17.7996 11.5147 17.7996 12.9999C17.8001 13.9016 17.5821 14.7899 17.1641 15.5888C16.7461 16.3877 16.1406 17.0734 15.3996 17.5871L14.966 19.6399C14.9086 20.0183 14.7175 20.3635 14.4273 20.6131C14.1371 20.8626 13.7671 20.9998 13.3844 20.9999H11.0148C10.6321 20.9998 10.2621 20.8626 9.97192 20.6131C9.68174 20.3635 9.49062 20.0183 9.43321 19.6399L8.99961 17.5959C8.25834 17.0804 7.65287 16.3931 7.23493 15.5928C6.817 14.7924 6.59901 13.9028 6.59961 12.9999Z"
      :stroke="color"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 13.7998H23.4"
      :stroke="color"
      stroke-width="1.6"
      stroke-linecap="round"
    />
    <path
      d="M3.40039 13.7998H1.00039"
      :stroke="color"
      stroke-width="1.6"
      stroke-linecap="round"
    />
    <path
      d="M20.2002 7.49707L21.8973 5.80001"
      :stroke="color"
      stroke-width="1.6"
      stroke-linecap="round"
    />
    <path
      d="M4.2002 7.49707L2.50314 5.80001"
      :stroke="color"
      stroke-width="1.6"
      stroke-linecap="round"
    />
    <path
      d="M12.2002 3.3999L12.2002 0.999902"
      :stroke="color"
      stroke-width="1.6"
      stroke-linecap="round"
    />
  </svg> -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    width="15"
    height="15"
    :fill="color"
    viewBox="0 0 973.1 973.1"
    xml:space="preserve"
  >
    <g>
      <path
        d="M502.29,788.199h-47c-33.1,0-60,26.9-60,60v64.9c0,33.1,26.9,60,60,60h47c33.101,0,60-26.9,60-60v-64.9   C562.29,815,535.391,788.199,502.29,788.199z"
      />
      <path
        d="M170.89,285.8l86.7,10.8c27.5,3.4,53.6-12.4,63.5-38.3c12.5-32.7,29.9-58.5,52.2-77.3c31.601-26.6,70.9-40,117.9-40   c48.7,0,87.5,12.8,116.3,38.3c28.8,25.6,43.1,56.2,43.1,92.1c0,25.8-8.1,49.4-24.3,70.8c-10.5,13.6-42.8,42.2-96.7,85.9   c-54,43.7-89.899,83.099-107.899,118.099c-18.4,35.801-24.8,75.5-26.4,115.301c-1.399,34.1,25.8,62.5,60,62.5h49   c31.2,0,57-23.9,59.8-54.9c2-22.299,5.7-39.199,11.301-50.699c9.399-19.701,33.699-45.701,72.699-78.1   C723.59,477.8,772.79,428.4,795.891,392c23-36.3,34.6-74.8,34.6-115.5c0-73.5-31.3-138-94-193.4c-62.6-55.4-147-83.1-253-83.1   c-100.8,0-182.1,27.3-244.1,82c-52.8,46.6-84.9,101.8-96.2,165.5C139.69,266.1,152.39,283.5,170.89,285.8z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
