<template>
  <svg
    width="7"
    height="21"
    viewBox="0 0 7 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 0C4.03043 0 4.53914 0.221249 4.91421 0.615076C5.28929 1.0089 5.5 1.54305 5.5 2.1C5.5 2.65695 5.28929 3.1911 4.91421 3.58492C4.53914 3.97875 4.03043 4.2 3.5 4.2C2.96957 4.2 2.46086 3.97875 2.08579 3.58492C1.71071 3.1911 1.5 2.65695 1.5 2.1C1.5 1.54305 1.71071 1.0089 2.08579 0.615076C2.46086 0.221249 2.96957 0 3.5 0ZM2 5.25H5C5.53043 5.25 6.03914 5.47125 6.41421 5.86508C6.78929 6.2589 7 6.79305 7 7.35V13.125H5.5V21H1.5V13.125H0V7.35C0 6.79305 0.210714 6.2589 0.585787 5.86508C0.96086 5.47125 1.46957 5.25 2 5.25Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1DEB4A',
    },
  },
}
</script>

<style></style>
