<template>
  <div class="w-full lg:w-auto rounded-lg px-6 py-4 bg-white">
    <div class="text-promy-green-350 font-main font-bold sm:text-sm">
      {{ title }}
    </div>
    <div class="sm:px-8">
      <div class="w-full m-auto relative" :class="classesContainerChart">
        <canvas :id="chartId" height="250" />
        <div
          class="
            absolute
            bottom-10
            md:bottom-6
            sm:bottom-16
            left-1/2
            transform
            -translate-y-1/2 -translate-x-1/2
          "
        >
        
        <div class="flex flex-col items-center" v-if="score >= 0">
          <span class="text-xs leading-4 font-medium text-promy-grey-70">Niveau</span>
            <span
                class="font-bold text-xl sm:text-sm text-promy-gray-300"
                >{{  getChartState() }}</span
            >

            <span class="text-xs leading-4 font-medium">
                {{ (score * 100) / 100 }} %
            </span>
            </div>
            <div class="text-xs leading-4 font-medium mt-2" v-else>
            Information non connue
            </div>
  
        </div>
      </div>
      <div
        class="
          flex
          justify-between
          items-center
          space-x-14
          lg:space-x-10
          font-main font-bold
          text-promy-gray-200
          sm:text-sm
          pb-3
        "
      >
        <p v-if="this.inverted" class="text-promy-actions-danger">Mauvais</p>
        <p v-if="!this.inverted" class="text-promy-green-100">Bien</p>
        <p class="text-promy-orange-100">Modéré</p>
        <p v-if="this.inverted" class="text-promy-green-100">Bien</p>
        <p v-if="!this.inverted" class="text-promy-actions-danger">Mauvais</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chartId: {
      type: String,
    },
    title: {
      type: String,
    },
    score: {
      type: Number,
      required: true,
    },
    states: {
      type: Array,
      default: () => [],
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    classesContainerChart: {
      type: String,
    },
  },

  data() {
    return {
      cutout: 100,
    }
  },

  mounted() {
    this.renderPie()
  },
  methods: {
    renderPie() {
      this.cutout =
        (this.$mq === 'sm' && 65) || (this.$mq === 'md' && 100) || this.cutout
      const doghnut = document.getElementById(this.chartId)
      const doghnutContext = doghnut.getContext('2d')
      const doghnutGradient = doghnutContext.createLinearGradient(
        this.inverted ? -100 : 0,
        0,
        400,
        0,
      )

      doghnutGradient.addColorStop(this.inverted ? 1 : 0, '#21EB1D')
      doghnutGradient.addColorStop(0.5, '#EBB11D')
      doghnutGradient.addColorStop(this.inverted ? 0 : 1, '#EB1D1D')

      new this.$chart(doghnut, {
        type: 'doughnut',
        data: {
          labels: [this.title, 'Vide'],
          datasets: [
            {
              label: this.title,
              data: [this.score, 100 - this.score],
              backgroundColor: [doghnutGradient, '#EAEAEA'],
              borderWidth: 0,
              width: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          cutout: '70%',
          rotation: -90,
          circumference: 180,
          plugins: {
            tooltip: {
              enabled: false,
            },
          },
          animation: {
            animateRotate: true,
            animateScale: true,
          },
        },
      })
    },
    getChartState(stateIndex = 0) {
      let result = ''
      const chartState = this.states.length && this.states[stateIndex]
      const chartStateLabelIfConditionPassed =
        chartState &&
        chartState.condition(this.score, chartState.min, chartState.max) &&
        chartState.label

      result =
        stateIndex++ < this.states.length
          ? chartStateLabelIfConditionPassed || this.getChartState(stateIndex)
          : chartStateLabelIfConditionPassed || 'Undefined'

      return result
    },
  },
}
</script>

<style></style>
