<template>
  <validation-provider :rules="rules" v-slot="{ errors }" class="w-full">
    <div class="w-full flex flex-col space-y-2">
      <label
        v-if="label"
        :for="uniqueId"
        class="text-promy-gray-200 first-letter:capitalize font-medium"
      >
        {{ label }}
      </label>
      <textarea
        :class="[
          errors[0] ? 'border-promy-actions-danger' : 'border-promy-gray-500',
          classTextArea,
        ]"
        class="w-full py-2 pl-3 text-left bg-white border rounded-md shadow-sm outline-none appearance-none cursor-default text-promy-gray-700 focus:outline-none focus:ring-1 sm:text-sm hover:border-promy-gray-200"
        :id="uniqueId"
        :value="value"
        @input="handleInput($event.target.value)"
        :rows="row"
        :placeholder="placeholder"
        name="description"
      />
    </div>
    <span
      class="mt-1 text-xs italic text-promy-actions-danger"
      v-if="errors[0]"
    >
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
    },
    rules: {
      type: String,
    },
    row: {
      type: String,
      default: '5',
    },
    classTextArea: {
      type: String,
      default: 'w-full',
    },
    placeholder: {
      type: String,
      default: 'Description',
    },
  },
  computed: {
    uniqueId() {
      return `textarea-${this._uid}`
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>
