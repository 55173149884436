<template>
  <svg
    width="66"
    height="54"
    viewBox="0 0 66 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.5664 25.422L55.0079 18.3102V2.36673C55.0079 2.00198 54.8902 1.70316 54.6558 1.46879C54.4205 1.23345 54.1217 1.11675 53.7569 1.11675H46.2546C45.8898 1.11675 45.5905 1.23345 45.3557 1.46879C45.1213 1.70316 45.0041 2.00296 45.0041 2.36673V9.98673L35.4701 2.01561C34.6366 1.33788 33.6459 1 32.4999 1C31.354 1 30.3637 1.33788 29.5297 2.01561L1.43395 25.4227C1.1737 25.6312 1.03014 25.9105 1.00427 26.2626C0.978392 26.6146 1.07019 26.9198 1.27819 27.181L3.70153 30.0736C3.91002 30.3079 4.18297 30.451 4.52182 30.5033C4.83481 30.5286 5.14681 30.4373 5.4598 30.2293L32.0694 8.04096C32.3081 7.84272 32.6929 7.84272 32.9302 8.04096L59.5397 30.2293C59.7482 30.4115 60.0212 30.5033 60.361 30.5033H60.4787C60.8161 30.4505 61.09 30.3079 61.299 30.0736L63.7218 27.181C63.9298 26.9208 64.0216 26.6146 63.9957 26.2626C63.9694 25.91 63.8248 25.6302 63.5656 25.4222L63.5664 25.422Z"
      :class="'stroke-current stroke-' + color"
      stroke-width="2"
    />
    <path
      d="M52.2084 52.9203C53.7553 52.9203 55.0096 51.666 55.0096 50.1196V31.4362C55.0096 31.1276 54.8172 30.716 54.5789 30.5173L32.9343 12.4763C32.6965 12.2791 32.3083 12.2791 32.0705 12.4763C32.0705 12.4763 13.5084 27.8273 10.4409 30.396C10.2036 30.5947 10.0112 31.0093 10.0103 31.3184C10.0054 32.7105 9.99902 44.5956 9.99609 50.1193C9.99561 51.6656 11.2489 52.92 12.7958 52.92L52.2084 52.9203ZM32.315 48.5898C30.9844 48.5898 29.9063 47.5112 29.9063 46.1816C29.9063 44.851 30.9844 43.7729 32.315 43.7729C33.6441 43.7729 34.7232 44.851 34.7232 46.1816C34.7237 47.5107 33.6451 48.5898 32.315 48.5898ZM32.4991 24.7264L32.627 24.7259C35.1294 24.7259 39.3638 26.0707 39.3638 31.1106C39.3638 34.0695 37.6738 35.5339 36.1837 36.8272C35.0587 37.8028 34.0871 38.6456 34.1241 39.9351C34.1241 40.2881 34.1285 40.356 34.1309 40.415C34.1329 40.4614 34.1353 40.5024 34.1353 40.6616C34.1353 41.4736 33.3267 42.27 32.5021 42.27H32.1291C31.259 42.27 30.4948 41.518 30.4948 40.6616C30.4113 37.1714 32.4177 35.5695 34.0299 34.2803C35.1393 33.3936 36.0977 32.6285 36.0977 31.4791C36.0977 29.0027 35.0226 27.9451 32.5025 27.9451C30.486 27.9451 29.2813 29.2668 29.2813 31.4791C29.2813 32.7189 28.3463 33.2829 27.4776 33.2829C27.0016 33.2829 26.5558 33.1217 26.2203 32.8297C25.8409 32.4987 25.6407 32.0309 25.6407 31.4786C25.6177 25.7239 29.9273 24.7781 32.499 24.7263L32.4991 24.7264Z"
      :class="'stroke-current stroke-' + color"
      stroke-width="2"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
}
</script>
