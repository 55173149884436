import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'
const options = {
  color: '#00C9BC',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '10s',
    opacity: '0.6s',
    termination: 40000,
  },
  position: 'absolute',
  autoRevert: true,
  location: 'bottom',
  inverse: false,
}
Vue.use(VueProgressBar, options)
