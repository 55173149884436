<template>
  <div>
    <h1
      class="text-2xl lg:text-xl sm:text-base font-bold mt-4 mb-10 text-promy-purply-900"
    >
      Modifiez votre mot de passe
    </h1>
    <div class="flex space-x-8">
      <!-- <div class="w-1/2 sm:w-full space-y-6"> -->
      <ValidationObserver class="w-1/2 sm:w-full space-y-6" ref="form">
        <pro-input
          label="Ancien mot de passe"
          :placeholder="'Ancien mot de passe'"
          :rules="'required'"
          v-model="form.old_password"
          type="password"
        />
        <pro-input
          label="Nouveau mot de passe"
          :placeholder="'Nouveau mot de passe'"
          :rules="'required|min:8'"
          v-model="form.password"
          type="password"
          ref="password"
          :with_rule="true"
          @passwordInputIsValidated="(value) => (password_is_valid = value)"
        />
        <pro-input
          label="Confirmez le mot de passe"
          :placeholder="'Confirmez le mot de passe'"
          :rules="`required|confirmedBy:${form.password}`"
          v-model="form.password_confirmation"
          type="password"
          :with_rule="true"
          @passwordInputIsValidated="
            (value) => (password_confirmation_is_valid = value)
          "
        />
      </ValidationObserver>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      password_is_valid: false,
      password_confirmation_is_valid: false,
    }
  },
  watch: {
    form: {
      handler(val) {
        val.is_password_valid =
          this.password_is_valid && this.password_confirmation_is_valid
        this.$emit('passwordInfo', val)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.msg-error {
  @apply rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2;
}
</style>
