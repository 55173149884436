<template>
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.88086 16.3899H15.9456"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.07787 2.17929C9.77877 1.28628 10.9114 1.33288 11.8054 2.03378L13.1273 3.07041C14.0213 3.77131 14.338 4.85834 13.6371 5.75326L5.75402 15.8104C5.49058 16.1471 5.0883 16.3458 4.66034 16.3506L1.6199 16.3896L0.931351 13.4271C0.834346 13.0115 0.931351 12.574 1.19479 12.2364L9.07787 2.17929Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.60156 4.06271L12.1608 7.63668"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#10AAAF',
    },
  },
}
</script>

<style></style>
