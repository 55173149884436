function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}

import auth from '../../middleware/auth'
import isValidatedCGU from '@/middleware/isValidatedCGU'

export default [
  {
    path: 'sections-gu',
    name: 'sectionsGU',
    component: page('SectionsGU'),
    redirect: 'sections-gu/adresse/:id_address?',
    children: [
      {
        path: 'adresse/:id_address?',
        name: 'adresse',
        component: page('Search'),
        meta: {
          middleware: [isValidatedCGU],
          guestModeActivated: true,
          is_height_screen: true,
        },
      },
      {
        path: 'historique/:id_address?',
        name: 'historique',
        component: page('SectionsGU/Historique'),
      },
      {
        path: 'parcelle/:id_address?',
        name: 'parcelle',
        component: page('SectionsGU/Parcelle'),
        meta: {
          middleware: [isValidatedCGU],
          guestModeActivated: true,
          inside_address: true,
          is_height_screen: true,
        },
      },
      {
        path: 'batiment/:id_address?',
        name: 'batiment',
        component: page('SectionsGU/Batiment'),
        meta: {
          middleware: [isValidatedCGU],
          guestModeActivated: true,
          inside_address: true,
          is_height_screen: true,
        },
      },
      {
        path: 'pollution/:id_address?',
        name: 'pollution',
        component: page('SectionsGU/Pollution'),
        meta: {
          middleware: [isValidatedCGU],
          inside_address: true,
        },
      },
      {
        path: 'etude-commerciale/:id_address?',
        name: 'EtudeCommerciale',
        component: page('SectionsGU/EtudeCommerciale'),
        meta: {
          middleware: [isValidatedCGU],
          guestModeActivated: true,
          inside_address: true,
          is_height_screen: true,
        },
      },
      {
        path: 'evolution-des-prix/:id_address?',
        name: 'evolutionDesPrix',
        component: page('SectionsGU/EvolutionDesPrix'),
        meta: {
          middleware: [isValidatedCGU],
          guestModeActivated: true,
          inside_address: true,
          is_height_screen: true,
        },
      },
      {
        path: 'commodite/:id_address?',
        name: 'Commodite',
        component: page('SectionsGU/Commodite'),
        meta: {
          middleware: [isValidatedCGU],
          inside_address: true,
          is_height_screen: true,
        },
      },
      {
        path: 'streetView/:id_address?',
        name: 'StreetView',
        component: page('SectionsGU/StreetView'),
        meta: {
          middleware: [isValidatedCGU],
          inside_address: true,
          is_height_screen: true,
        },
      },
      {
        path: 'urbanisme/:id_address?',
        name: 'urbanisme',
        component: page('SectionsGU/Urbanisme'),
        meta: {
          middleware: [isValidatedCGU],
          inside_address: true,
          is_height_screen: true,
        },
      },
      {
        path: 'reseaux/:id_address?',
        name: 'reseaux',
        component: page('SectionsGU/Reseaux'),
        meta: {
          middleware: [isValidatedCGU],
          inside_address: true,
        },
      },
      {
        path: 'commune/:id_address?',
        name: 'commune',
        component: page('SectionsGU/Commune'),
        meta: {
          middleware: [isValidatedCGU],
          inside_address: true,
        },
      },
      {
        path: 'risque/:id_address?',
        name: 'risque',
        component: page('SectionsGU/Risque'),
        meta: {
          middleware: [isValidatedCGU],
          inside_address: true,
        },
      },
    ],
  },
]
