<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.79688 13.2051C4.88281 12.0977 3.59375 10.0273 3.59375 7.65625C3.59375 4.11914 6.46289 1.25 10 1.25C13.5371 1.25 16.4062 4.11914 16.4062 7.65625C16.4062 10.0273 15.1172 12.0977 13.2031 13.2051V15.4688C13.2031 15.8145 12.9238 16.0938 12.5781 16.0938H7.42188C7.07617 16.0938 6.79688 15.8145 6.79688 15.4688V13.2051ZM7.65625 17.3438H12.3438C12.4297 17.3438 12.5 17.4141 12.5 17.5V18.125C12.5 18.4707 12.2207 18.75 11.875 18.75H8.125C7.7793 18.75 7.5 18.4707 7.5 18.125V17.5C7.5 17.4141 7.57031 17.3438 7.65625 17.3438Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
