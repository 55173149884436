<template>
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1666 15.1712C21.1553 13.2397 22.0304 11.7841 23.7976 10.7107C22.8092 9.29473 21.3139 8.51592 19.3428 8.36582C17.4765 8.21855 15.4346 9.45332 14.6869 9.45332C13.8968 9.45332 12.0899 8.4168 10.6683 8.4168C7.73428 8.46211 4.61621 10.7561 4.61621 15.4232C4.61621 16.8024 4.86826 18.227 5.37236 19.6939C6.04639 21.6254 8.47627 26.3577 11.0109 26.2812C12.3363 26.2501 13.2737 25.341 14.9984 25.341C16.6722 25.341 17.5388 26.2812 19.0171 26.2812C21.5744 26.2444 23.7721 21.9426 24.4121 20.0055C20.9825 18.3884 21.1666 15.2703 21.1666 15.1712V15.1712ZM18.1901 6.5335C19.626 4.82861 19.4957 3.27666 19.4532 2.71875C18.1845 2.79238 16.7175 3.58252 15.882 4.55391C14.9616 5.59609 14.4207 6.88467 14.5368 8.3375C15.9075 8.44228 17.1593 7.73711 18.1901 6.5335V6.5335Z"
      :fill="color ? color : '#b3b3b3'"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
    },
  },
}
</script>
