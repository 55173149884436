<template>
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M18.5981 16.1607L18.4793 14.1416C18.7469 14.0417 18.971 13.851 19.1122 13.6027C19.2535 13.3544 19.3031 13.0644 19.2523 12.7833C19.2016 12.5022 19.0537 12.2479 18.8344 12.0647C18.6152 11.8816 18.3387 11.7812 18.053 11.7813H9.928C9.64234 11.7812 9.36576 11.8816 9.14654 12.0647C8.92733 12.2479 8.77942 12.5022 8.72865 12.7833C8.67788 13.0644 8.72747 13.3544 8.86876 13.6027C9.01005 13.851 9.23406 14.0417 9.50167 14.1416L9.38289 16.1607C9.2135 19.0979 8.44589 21.9696 7.12714 24.5995C7.09616 24.6615 7.08153 24.7303 7.08464 24.7995C7.08774 24.8687 7.10849 24.9359 7.14489 24.9948C7.1813 25.0537 7.23216 25.1024 7.29265 25.1361C7.35314 25.1698 7.42124 25.1875 7.4905 25.1875H20.4905C20.5598 25.1875 20.6279 25.1698 20.6883 25.1361C20.7488 25.1024 20.7997 25.0537 20.8361 24.9948C20.8725 24.9359 20.8933 24.8687 20.8964 24.7995C20.8995 24.7303 20.8848 24.6615 20.8539 24.5995C19.5351 21.9696 18.7675 19.0979 18.5981 16.1607ZM9.928 12.5938H18.053C18.1607 12.5938 18.2641 12.6366 18.3403 12.7127C18.4164 12.7889 18.4592 12.8923 18.4592 13C18.4592 13.1077 18.4164 13.2111 18.3403 13.2873C18.2641 13.3634 18.1607 13.4062 18.053 13.4062H9.928C9.82025 13.4062 9.71692 13.3634 9.64073 13.2873C9.56455 13.2111 9.52175 13.1077 9.52175 13C9.52175 12.8923 9.56455 12.7889 9.64073 12.7127C9.71692 12.6366 9.82025 12.5938 9.928 12.5938ZM8.13746 24.375C9.33321 21.8073 10.0311 19.0362 10.194 16.2084L10.3111 14.2188H17.6699L17.787 16.2084C17.9499 19.0362 18.6478 21.8073 19.8435 24.375H8.13746Z"
        :fill="color"
      />
      <path
        d="M13.9905 19.9062C14.2148 19.9062 14.3967 19.7244 14.3967 19.5C14.3967 19.2756 14.2148 19.0938 13.9905 19.0938C13.7661 19.0938 13.5842 19.2756 13.5842 19.5C13.5842 19.7244 13.7661 19.9062 13.9905 19.9062Z"
        :fill="color"
      />
      <path
        d="M14.9517 20.3521C14.925 20.3058 14.8894 20.2653 14.847 20.2327C14.8046 20.2002 14.7562 20.1764 14.7046 20.1627C14.6529 20.1489 14.5991 20.1454 14.5461 20.1525C14.4932 20.1596 14.4421 20.177 14.3959 20.2039C14.2722 20.2737 14.1325 20.3104 13.9905 20.3104C13.8484 20.3104 13.7088 20.2737 13.5851 20.2039C13.5389 20.177 13.4878 20.1596 13.4348 20.1525C13.3819 20.1454 13.328 20.1489 13.2764 20.1627C13.2248 20.1764 13.1764 20.2002 13.134 20.2327C13.0916 20.2653 13.056 20.3058 13.0293 20.3521L12.2168 21.7597C12.1629 21.853 12.1483 21.9638 12.1761 22.0678C12.204 22.1718 12.2719 22.2605 12.3651 22.3144C12.8593 22.5997 13.4199 22.7499 13.9905 22.7499C14.5611 22.7499 15.1217 22.5997 15.6159 22.3144C15.7091 22.2605 15.777 22.1718 15.8048 22.0678C15.8326 21.9638 15.818 21.853 15.7642 21.7597L14.9517 20.3521ZM13.1405 21.7847L13.5555 21.0657C13.8402 21.1439 14.1408 21.1439 14.4254 21.0657L14.8405 21.7847C14.2917 21.9858 13.6893 21.9858 13.1405 21.7847H13.1405Z"
        :fill="color"
      />
      <path
        d="M15.6157 16.6852C15.5695 16.6585 15.5185 16.6412 15.4656 16.6342C15.4127 16.6272 15.3589 16.6307 15.3073 16.6445C15.2558 16.6583 15.2074 16.6821 15.1651 16.7146C15.1227 16.7471 15.0872 16.7876 15.0605 16.8338L14.248 18.2415C14.1942 18.3347 14.1796 18.4455 14.2074 18.5495C14.2352 18.6535 14.3032 18.7422 14.3963 18.7962C14.5197 18.8677 14.6222 18.9703 14.6935 19.0937C14.7648 19.2172 14.8026 19.3572 14.803 19.4998C14.803 19.6076 14.8458 19.7109 14.922 19.7871C14.9982 19.8633 15.1015 19.9061 15.2092 19.9061H16.8342C16.942 19.9061 17.0453 19.8633 17.1215 19.7871C17.1977 19.7109 17.2405 19.6076 17.2405 19.4998C17.2394 18.9296 17.0888 18.3696 16.8037 17.8757C16.5186 17.3818 16.109 16.9713 15.6157 16.6852ZM15.5634 19.0936C15.489 18.8081 15.3388 18.548 15.1287 18.341L15.5438 17.6218C15.9935 17.9954 16.2949 18.5173 16.3936 19.0936H15.5634Z"
        :fill="color"
      />
      <path
        d="M13.178 19.4998C13.1784 19.3572 13.2161 19.2172 13.2875 19.0937C13.3588 18.9703 13.4613 18.8677 13.5846 18.7962C13.6778 18.7422 13.7458 18.6535 13.7736 18.5495C13.8014 18.4455 13.7868 18.3347 13.733 18.2415L12.9205 16.8338C12.8938 16.7876 12.8582 16.7471 12.8159 16.7146C12.7735 16.6821 12.7252 16.6583 12.6736 16.6445C12.6221 16.6307 12.5683 16.6272 12.5154 16.6342C12.4625 16.6412 12.4114 16.6585 12.3652 16.6852C11.872 16.9713 11.4624 17.3818 11.1773 17.8757C10.8922 18.3696 10.7416 18.9296 10.7405 19.4998C10.7405 19.6076 10.7833 19.7109 10.8595 19.7871C10.9357 19.8633 11.039 19.9061 11.1467 19.9061H12.7717C12.8795 19.9061 12.9828 19.8633 13.059 19.7871C13.1352 19.7109 13.178 19.6076 13.178 19.4998ZM11.5873 19.0936C11.6861 18.5173 11.9875 17.9954 12.4371 17.6218L12.8523 18.341C12.6421 18.548 12.4919 18.8081 12.4176 19.0936H11.5873Z"
        :fill="color"
      />
      <path
        d="M5.45353 16.25H8.70924V15.4375H5.05299C4.94525 15.4375 4.84192 15.3947 4.76573 15.3185C4.68954 15.2423 4.64674 15.139 4.64674 15.0312C4.64674 14.9235 4.68954 14.8202 4.76573 14.744C4.84192 14.6678 4.94525 14.625 5.05299 14.625H8.30299V13.8125H5.05299C4.76587 13.8125 4.48796 13.9138 4.26827 14.0987C4.04857 14.2836 3.90121 14.5401 3.85216 14.823C3.80311 15.1059 3.85552 15.397 4.00015 15.6451C4.14479 15.8931 4.37235 16.0821 4.64273 16.1788C4.58129 19.1543 3.63499 22.0441 1.92449 24.4796L1.87639 24.5483C1.83378 24.6092 1.80869 24.6806 1.80384 24.7548C1.799 24.8289 1.81459 24.903 1.84891 24.9689C1.88323 25.0348 1.93498 25.09 1.99851 25.1286C2.06204 25.1671 2.13493 25.1875 2.20924 25.1875H6.27174V24.375H2.97171C4.52632 21.9445 5.38472 19.1343 5.45353 16.25Z"
        :fill="color"
      />
      <path
        d="M26.0564 24.4795C24.346 22.044 23.3997 19.1543 23.3382 16.1788C23.6086 16.0821 23.8362 15.8931 23.9808 15.6451C24.1255 15.397 24.1779 15.1059 24.1288 14.823C24.0798 14.5401 23.9324 14.2836 23.7127 14.0987C23.493 13.9138 23.2151 13.8125 22.928 13.8125H19.678V14.625H22.928C23.0357 14.625 23.1391 14.6678 23.2152 14.744C23.2914 14.8202 23.3342 14.9235 23.3342 15.0312C23.3342 15.139 23.2914 15.2423 23.2152 15.3185C23.1391 15.3947 23.0357 15.4375 22.928 15.4375H19.2717V16.25H22.5275C22.5963 19.1343 23.4547 21.9445 25.0093 24.375H21.7092V25.1875H25.7717C25.846 25.1875 25.9189 25.1671 25.9825 25.1286C26.046 25.09 26.0977 25.0348 26.1321 24.9689C26.1664 24.903 26.182 24.8289 26.1771 24.7548C26.1723 24.6806 26.1472 24.6092 26.1046 24.5483L26.0564 24.4795Z"
        :fill="color"
      />
      <path
        d="M5.86548 10.5625C5.8662 9.91625 6.12324 9.29668 6.5802 8.83972C7.03717 8.38276 7.65674 8.12572 8.30298 8.125C9.16463 8.12403 9.99072 7.78131 10.6 7.17202C11.2093 6.56274 11.552 5.73665 11.553 4.875C11.5514 4.28412 11.667 3.6988 11.8931 3.1529C12.1192 2.60701 12.4514 2.11138 12.8703 1.69472L13.059 1.50603L12.4844 0.931465L12.2958 1.12016C11.8011 1.61211 11.409 2.19728 11.142 2.8418C10.8751 3.48631 10.7386 4.17738 10.7405 4.875C10.7398 5.52124 10.4827 6.14081 10.0258 6.59777C9.56879 7.05473 8.94922 7.31177 8.30298 7.3125C7.44132 7.31346 6.61523 7.65619 6.00595 8.26547C5.39667 8.87475 5.05395 9.70084 5.05298 10.5625V13H5.86548V10.5625Z"
        :fill="color"
      />
      <path
        d="M11.9592 10.9688H12.7717C12.7721 10.6456 12.9006 10.3359 13.1291 10.1074C13.3576 9.87889 13.6674 9.75037 13.9905 9.75H15.8186C16.5187 9.74921 17.1899 9.47074 17.6849 8.9757C18.18 8.48066 18.4584 7.80947 18.4592 7.10938C18.4598 6.62469 18.6526 6.16002 18.9953 5.8173C19.338 5.47458 19.8027 5.2818 20.2874 5.28125H20.6936C21.3937 5.28046 22.0649 5.00199 22.5599 4.50695C23.055 4.01191 23.3334 3.34072 23.3342 2.64062V0.8125H22.5217V2.64062C22.5212 3.12531 22.3284 3.58998 21.9857 3.9327C21.643 4.27542 21.1783 4.4682 20.6936 4.46875H20.2874C19.5873 4.46954 18.9161 4.74801 18.421 5.24305C17.926 5.73809 17.6475 6.40928 17.6467 7.10938C17.6462 7.59406 17.4534 8.05873 17.1107 8.40145C16.768 8.74417 16.3033 8.93695 15.8186 8.9375H13.9905C13.4519 8.93812 12.9356 9.15232 12.5548 9.53312C12.174 9.91392 11.9598 10.4302 11.9592 10.9688Z"
        :fill="color"
      />
      <path
        d="M10.7405 9.95312C10.7403 10.1147 10.676 10.2696 10.5618 10.3838C10.4476 10.4981 10.2927 10.5623 10.1311 10.5625H8.5061C8.12913 10.5629 7.76771 10.7129 7.50115 10.9794C7.23459 11.246 7.08465 11.6074 7.08423 11.9844V13.4062H7.89673V11.9844C7.8969 11.8228 7.96116 11.6679 8.07541 11.5537C8.18965 11.4394 8.34454 11.3752 8.5061 11.375H10.1311C10.5081 11.3746 10.8695 11.2246 11.1361 10.9581C11.4026 10.6915 11.5526 10.3301 11.553 9.95312V9.34375H10.7405V9.95312Z"
        :fill="color"
      />

      <path d="M11.553 7.71875H10.7405V8.53125H11.553V7.71875Z" :fill="color" />
      <path
        d="M24.553 7.3125C23.9067 7.31322 23.2872 7.57026 22.8302 8.02723C22.3732 8.48419 22.1162 9.10376 22.1155 9.75C22.115 10.1808 21.9436 10.5939 21.639 10.8985C21.3343 11.2031 20.9213 11.3745 20.4905 11.375H20.0842V12.1875H20.4905C21.1367 12.1868 21.7563 11.9297 22.2133 11.4728C22.6702 11.0158 22.9273 10.3962 22.928 9.75C22.9285 9.31917 23.0998 8.90614 23.4045 8.6015C23.7091 8.29686 24.1222 8.12549 24.553 8.125H26.178V7.3125H24.553Z"
        :fill="color"
      />
      <path
        d="M25.3655 10.1562C25.3652 10.3717 25.2796 10.5782 25.1272 10.7305C24.9749 10.8828 24.7684 10.9685 24.553 10.9688C24.1222 10.9692 23.7091 11.1406 23.4045 11.4452C23.0998 11.7499 22.9285 12.1629 22.928 12.5938V13H23.7405V12.5938C23.7407 12.3783 23.8264 12.1718 23.9787 12.0195C24.131 11.8672 24.3376 11.7815 24.553 11.7812C24.9838 11.7808 25.3968 11.6094 25.7015 11.3048C26.0061 11.0001 26.1775 10.5871 26.178 10.1562V9.75H25.3655V10.1562Z"
        :fill="color"
      />
      <path
        d="M20.4905 8.125C20.4902 8.34041 20.4046 8.54693 20.2522 8.69925C20.0999 8.85157 19.8934 8.93725 19.678 8.9375C19.2472 8.93799 18.8341 9.10936 18.5295 9.414C18.2248 9.71864 18.0535 10.1317 18.053 10.5625V10.9688H18.8655V10.5625C18.8657 10.3471 18.9514 10.1406 19.1037 9.98825C19.256 9.83593 19.4626 9.75025 19.678 9.75C20.1088 9.74951 20.5218 9.57814 20.8265 9.2735C21.1311 8.96886 21.3025 8.55583 21.303 8.125V7.71875H20.4905V8.125Z"
        :fill="color"
      />
      <path
        d="M14.803 6.09375C14.8032 5.87834 14.8889 5.67182 15.0412 5.5195C15.1935 5.36718 15.4001 5.2815 15.6155 5.28125C16.0463 5.28076 16.4593 5.10939 16.764 4.80475C17.0686 4.50011 17.24 4.08708 17.2405 3.65625V3.25H16.428V3.65625C16.4277 3.87166 16.3421 4.07818 16.1897 4.2305C16.0374 4.38282 15.8309 4.4685 15.6155 4.46875C15.1847 4.46924 14.7716 4.64061 14.467 4.94525C14.1623 5.24989 13.991 5.66292 13.9905 6.09375V6.5H14.803V6.09375Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="26"
          height="26"
          fill="white"
          transform="translate(0.990479)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
