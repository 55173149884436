<template>
  <div
    class="w-max rounded-lg bg-promy-gray-50 absolute transition-all bottom-[-8.5rem] -right-1 duration-500 z-30"
  >
    <div
      class="py-2 px-4 flex justify-between items-center bg-white rounded-t-lg"
    >
      <div class="flex space-x-2">
        <BulbIcon color="#10AAAF" />
        <p class="font-main font-bold text-promy-green-350">Astuces</p>
      </div>
      <div
        class="bg-[#F4F4F4] rounded-lg w-5 h-5 flex justify-center items-center cursor-pointer"
      >
        <CloseIcon @click.native="close" class="w-4 h-4" />
      </div>
    </div>
    <div class="flex items-center p-4 font-main font-medium text-xs">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style></style>
