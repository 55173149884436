<template>
  <button
    @click="clickEvent()"
    class="flex items-center px-4 py-3 rounded-lg hover:bg-promy-gray-50 font-semibold font-main"
    :class="[
      active
        ? 'text-promy-green-350 bg-promy-blue-50'
        : 'text-promy-primary bg-white',
    ]"
  >
    <p :class="{ 'xl:hidden lg:hidden': is_hidden_text_into_small_screen }">
      {{ text }}
    </p>
    <component
      v-if="iconComponent"
      :is="iconComponent"
      :color="active ? '#10AAAF' : '#26243C'"
      class="ml-4 xl:ml-0 lg:ml-0"
    />
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    iconComponent: {
      type: String,
      default: '',
    },
    is_hidden_text_into_small_screen: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    clickEvent() {
      this.$emit('click')
    },
  },
}
</script>
