export default function auth({ next, store, router }) {
  if (!store.getters['auth/isLoggedIn']) {
    store.commit('address/ResetStoreGUAddress')
    return router.push({
      name: 'Auth',
    })
  }

  return next()
}
