<template>
  <div
    class="z-[60] w-96 minimized-sidebar:w-max h-full min-h-screen flex transition-all duration-1000 shadow-box bg-promy-blue-270 fixed minimized-sidebar:ml-0 full-hd:ml-0 group overflow-y-auto scrollbar-thin scrollbar-thumb-promy-gray-180 scrollbar-track-promy-gray-100 minimized-sidebar:pl-3"
    @mouseover="is_hover_sidbar = true" @mouseleave="is_hover_sidbar = false" :class="[open_sideBar ? 'ml-0' : '-ml-96']"
    style="transition: margin-left width 0.125s">
    <portal to="navbar-title">
      <h2 class="text-2xl xl:text-xl sm:text-base capitalize">
        {{ getCurrentRouteLabel }}
      </h2>
    </portal>
    <div class="flex flex-col w-full mt-5">
      <div class="pl-16 pr-10 flex items-center my-6 minimized-sidebar:px-0">
        <router-link class="flex items-center" to="/">
          <LogoIcon />
          <p
            class="font-bold text-xl font-main ml-3 sm:text-base text-white minimized-sidebar:hidden minimized-sidebar:group-hover:block">
            Promy
          </p>
        </router-link>
        <CloseIcon @click.native="closeSidebar" color="white"
          class="bg-[#484c60] rounded-full w-8 h-8 p-1 ml-auto cursor-pointer minimized-sidebar:hidden full-hd:hidden" />
      </div>
      <div
        class="w-full pl-16 pr-10 minimized-sidebar:px-0 h-[83vh] lg:h-[80vh] overflow-y-auto mt-5 lg:mt-1 scrollbar-thin scrollbar-thumb-promy-gray-180 scrollbar-track-promy-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full flex flex-col justify-between">
        <div class="flex flex-col">
          <div class="hidden md:block pt-4">
            <div v-for="(button, index) in buttons" :key="index" class="flex items-center py-3 cursor-pointer"
              @click="enableButton(button.link)" :class="[
                $route.name === button.link
                  ? 'text-promy-orange-200 -ml-5'
                  : 'text-promy-gray-150 hover:text-white',
              ]">
              <div v-if="$route.name === button.link"
                class="h-6 mr-3 bg-promy-orange-200 border-4 border-promy-orange-200 rounded-lg" />
              <component :is="button.icon" :color="$route.name === button.link ? '#FDB815' : '#DEDEDE'" class="w-6 h-6" />
              <p class="font-medium font-main pl-6">{{ button.text }}</p>
            </div>
          </div>
          <div class="py-8 minimized-sidebar:py-4 minimized-sidebar:pl-3 minimized-sidebar:pr-8 lg:py-2">
            <div @click="activeSection(section)" @mouseover="hovering = section.text" @mouseleave="hovering = ''"
              v-for="(section, idx) in sections" :key="idx" class="flex items-center justify-between my-8 cursor-pointer"
              :class="[
                $route.name === section.route_name
                  ? 'text-promy-orange-200 -ml-5'
                  : 'text-promy-gray-150 hover:text-white',
                is_address &&
                  ((allowedForGuest(section.route_name) && !isLoggedIn) ||
                    (isLoggedIn && $route.params.id_address))
                  ? 'opacity-100 cursor-pointer'
                  : 'opacity-50 cursor-default',
              ]">
              <div v-if="$route.name === section.route_name"
                class="h-6 mr-3 bg-promy-orange-200 border-4 border-promy-orange-200 rounded-lg" />

              <component :is="section.icon" class="w-6 h-6 cursor-pointer" :color="$route.name === section.route_name ? '#FDB815' : '#DEDEDE'
                " :hovering="actived_section != section.text && hovering === section.text
    " />
              <div
                class="font-medium font-main px-6 minimized-sidebar:hidden minimized-sidebar:group-hover:block text-left flex-1 cursor-pointer">
                {{ section.text }}
              </div>
              <cadenas-icon class="minimized-sidebar:hidden minimized-sidebar:group-hover:block" :color="'#DEDEDE'"
                :hovering="actived_section != section.text && hovering === section.text
                  " v-if="isLoggedIn
    ? false
    : !is_address || !allowedForGuest(section.route_name)
    " />
              <div v-else />
            </div>
          </div>
        </div>

        <Contact class="lg:pr-0 pr-16 minimized-sidebar:pr-4" :with_green_number="false || $mq != 'xl'" isSideBar
          :is_hover_sidbar="is_hover_sidbar" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CadenasIcon from '../Icons/SideBarMenu/CadenasIcon.vue'
export default {
  components: { CadenasIcon },
  data() {
    return {
      is_hover_sidbar: false,
      hovering: '',
      actived_section: '',
      buttons: [
        {
          text: 'Recheche',
          link: 'adresse',
          icon: 'SearchIcon',
        },
        {
          text: 'Historique',
          link: 'historique',
          icon: 'HistoryIcon',
        },
      ],
      sections: [
        {
          text: 'Parcelle',
          link: '/sections-gu/parcelle',
          icon: 'ParcelleIcon',
          inside_address: true,
          route_name: 'parcelle',
        },
        {
          text: 'Bâtiments',
          link: '/sections-gu/batiment',
          icon: 'BatimentsIcon',
          inside_address: true,
          route_name: 'batiment',
        },
        {
          text: 'Urbanisme',
          link: '/sections-gu/urbanisme',
          icon: 'UrbanismeIcon',
          inside_address: true,
          route_name: 'urbanisme',
        },
        {
          text: 'Veille commerciale',
          link: '/sections-gu/etude-commerciale',
          icon: 'EtudeCommercialeIcon',
          inside_address: true,
          route_name: 'EtudeCommerciale',
        },
        {
          text: 'Évolution des prix',
          link: '/sections-gu/evolution-des-prix',
          icon: 'EvolutionDesPrixIcon',
          inside_address: true,
          route_name: 'evolutionDesPrix',
        },
        {
          text: 'Commodités',
          link: '/sections-gu/commodite',
          icon: 'CommoditeIcon',
          inside_address: true,
          route_name: 'Commodite',
        },
        {
          text: 'Street View',
          link: '/sections-gu/streetView',
          icon: 'StreetViewIcon',
          inside_address: true,
          route_name: 'StreetView',
        },
        {
          text: 'Commune',
          link: '/sections-gu/commune',
          icon: 'CommuneIcon',
          inside_address: true,
          route_name: 'commune',
        },
        {
          text: 'Risques',
          link: '/sections-gu/risque',
          icon: 'RisquesIcon',
          inside_address: true,
          route_name: 'risque',
        },
        {
          text: 'Pollution',
          link: '/sections-gu/pollution',
          icon: 'PollutionIcon',
          inside_address: true,
          route_name: 'pollution',
        },
        {
          text: 'Réseaux',
          link: '/sections-gu/reseaux',
          icon: 'ReseauxIcon',
          inside_address: true,
          route_name: 'reseaux',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      open_sideBar: 'sidebar/activated_sibeBar',
      is_data_loaded: 'address/is_data_loaded',
      is_data_loading: 'address/is_data_loading',
      address: 'address/address',
      isLoggedIn: 'auth/isLoggedIn',
    }),
    is_address() {
      return this.address ?? false
    },
    getCurrentRouteLabel() {
      return this.sections.find((item) => item.route_name === this.$route.name)
        ?.text
    },
  },
  methods: {
    ...mapMutations({
      toggleGuestModal: 'auth/TOGGLE_GUEST_MODAL',
    }),
    enableButton(name) {
      if (name == 'historique') {
        this.toggleGuestModal()
      } else if (name == 'recherche') {
        this.closeSidebar()
        this.$router.push(`/sections-gu/${name}/}`)
      }
      this.closeSidebar()
      this.$router.push(
        `/sections-gu/${name}/${this.$route.params.id_address ?? ''}`,
      )
    },
    closeSidebar() {
      this.$store.commit('sidebar/SET_ACTIVATED_SIDEBAR', false)
    },
    showSideBar(value) {
      this.$store.commit('sidebar/SET_ACTIVATED_SIDEBAR', value)
    },
    allowedForGuest(sectionRouteName) {
      return true
      return ['parcelle', 'batiment', 'EtudeCommerciale'].includes(
        sectionRouteName,
      )
    },
    activeSection(section) {
      if (
        (!this.is_address && !this.is_data_loading) ||
        (this.isLoggedIn && !this.$route.params.id_address)
      ) {
        this.$router.push({ name: 'adresse' })
        this.toast('Avertissement', 'Veuillez choisir une adresse !', 'warning')
        this.closeSidebar()
        return
      }
      if (!this.is_data_loaded && this.is_data_loading) {
        this.toast('', 'Chargement en cours...', 'warning')
        return
      }

      if (!this.allowedForGuest(section.route_name)) {
        this.$store.commit(
          'auth/SET_GUEST_INTENDED_LOCATION',
          section.route_name,
        )
        this.toggleGuestModal()
      }

      this.actived_section = section.text
      let link = section.inside_address
        ? `${section.link}/${this.$route.params.id_address ?? ''}`
        : section.link

      if (this.$route.name !== section.route_name) {
        this.$router.push(link)
      }
      this.closeSidebar()
    },
  },
}
</script>
<style lang="scss" scoped>
.activeLink {
  @apply relative;

  &::before {
    content: '';
    @apply border-l h-6 border;
  }
}
</style>
