<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.35938 19.6413V2.93733C2.35937 2.78456 2.42006 2.63806 2.52808 2.53003C2.6361 2.42201 2.78261 2.36133 2.93537 2.36133H19.6394"
      :stroke="color"
      stroke-width="1.5"
    />
    <path
      d="M15.8002 19.6412H19.0642C19.217 19.6412 19.3635 19.5805 19.4715 19.4725C19.5795 19.3644 19.6402 19.2179 19.6402 19.0652V15.8012M19.6402 6.20117V8.12117M19.6402 11.0012V12.9212M6.2002 19.6412H8.1202M11.0002 19.6412H12.9202"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.3599 3.32039C2.61451 3.32039 2.85869 3.21925 3.03873 3.03921C3.21876 2.85918 3.3199 2.615 3.3199 2.36039C3.3199 2.10578 3.21876 1.8616 3.03873 1.68157C2.85869 1.50153 2.61451 1.40039 2.3599 1.40039C2.10529 1.40039 1.86111 1.50153 1.68108 1.68157C1.50104 1.8616 1.3999 2.10578 1.3999 2.36039C1.3999 2.615 1.50104 2.85918 1.68108 3.03921C1.86111 3.21925 2.10529 3.32039 2.3599 3.32039ZM2.3599 20.6004C2.61451 20.6004 2.85869 20.4993 3.03873 20.3192C3.21876 20.1392 3.3199 19.895 3.3199 19.6404C3.3199 19.3858 3.21876 19.1416 3.03873 18.9616C2.85869 18.7815 2.61451 18.6804 2.3599 18.6804C2.10529 18.6804 1.86111 18.7815 1.68108 18.9616C1.50104 19.1416 1.3999 19.3858 1.3999 19.6404C1.3999 19.895 1.50104 20.1392 1.68108 20.3192C1.86111 20.4993 2.10529 20.6004 2.3599 20.6004ZM19.6399 3.32039C19.8945 3.32039 20.1387 3.21925 20.3187 3.03921C20.4988 2.85918 20.5999 2.615 20.5999 2.36039C20.5999 2.10578 20.4988 1.8616 20.3187 1.68157C20.1387 1.50153 19.8945 1.40039 19.6399 1.40039C19.3853 1.40039 19.1411 1.50153 18.9611 1.68157C18.781 1.8616 18.6799 2.10578 18.6799 2.36039C18.6799 2.615 18.781 2.85918 18.9611 3.03921C19.1411 3.21925 19.3853 3.32039 19.6399 3.32039Z"
      :fill="color"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#484848',
    },
  },
}
</script>
