<template>
  <validation-provider
    class="relative flex flex-col"
    :rules="rules"
    v-slot="{ errors }"
  >
    <div class="relative">
      <input
        type="search"
        :id="`#search-data-${_uid}`"
        class="w-max py-2 pr-10 relative sm:text-sm placeholder-gray-500 h-12"
        :class="[
          errors[0] ? 'input-error' : '',
          bordered ? 'bordered' : '',
          '!pl-14' ? typeInput === 'gu' : '',
          inputClasses,
        ]"
        :placeholder="placeholder"
        autocomplete="off"
        :value="value"
        @input="handleInput($event.target.value)"
        @focus="open = true"
        :disabled="disabled"
      />
      <template v-if="is_loading">
        <div class="loader"></div>
      </template>
    </div>
    <div
      class="flex z-[11]"
      v-show="value != '' && open && list.length"
      @mouseover="
        () => {
          hover_drop_down = true
        }
      "
      @mouseleave="
        () => {
          hover_drop_down = false
        }
      "
    >
      <div
        class="absolute w-full pt-2"
        v-click-outside="away"
        style="background-color: transparent"
      >
        <ul
          class="bg-white w-full max-h-56 text-xs text-promy-primary leading-6 overflow-auto focus:outline-none sm:text-sm sm:leading-5 rounded-lg shadow"
        >
          <li
            v-for="(item, index) in list_searchable"
            :key="index"
            class="cursor-pointer select-none relative py-3 px-6 focus:outline-none hover:bg-gray-50"
            @click="setItem(item)"
          >
            <p class="capitalize flex align-center space-x-2">
              <ProIcon name="marker" color="#DBDBDB" class="mt-1.5" />
              <span
                ><strong>{{ getNestedObject(item) }}</strong
                ><br class="hidden sm:block" />
              </span>
            </p>
          </li>
        </ul>
      </div>
    </div>

    <span
      v-show="errors[0] && !hover_drop_down && !disabled"
      class="msg-error"
      >{{ errors[0] }}</span
    >
  </validation-provider>
</template>
<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },
  props: {
    rules: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    list: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {},
    is_loading: {
      type: Boolean,
      default: false,
    },
    args: {
      type: Array,
      default: function () {
        return []
      },
    },
    is_searchable: {
      type: Boolean,
      default: false,
    },
    is_auto_complete_cross_api: {
      type: Boolean,
      default: false,
    },
    bordered: Boolean,
    typeInput: {
      type: String,
      default: 'gu',
    },
    inputClasses: {
      type: String,
    },
  },
  data() {
    return {
      open: false,
      hover_drop_down: false,
    }
  },
  computed: {
    list_searchable() {
      return this.value &&
        this.is_searchable &&
        !this.is_auto_complete_cross_api
        ? this.list.filter((item) =>
            this.getNestedObject(item)
              .toLowerCase()
              .includes(this.value.toLowerCase()),
          )
        : this.list
    },
  },

  methods: {
    away() {
      if (`#search-data-${this._uid}` !== document.activeElement.id) {
        this.open = false
      }
    },
    setItem(item) {
      this.open = false
      this.$emit('setItem', item)
    },
    handleInput(value) {
      this.$emit('input', value)
    },
    getNestedObject(obj) {
      return this.args.reduce((obj, level) => obj && obj[level], obj)
    },
  },
}
</script>
<style lang="scss" scoped>
input {
  @apply rounded-lg px-4 text-promy-primary border-solid border w-full border-promy-gray-150;
}
input:hover {
  @apply border-promy-gray-200;
}
input:focus {
  @apply outline-none bg-white border-promy-green-350;
}
input[disabled] {
  @apply border-promy-gray-100 bg-promy-gray-100;
}
.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #45ccdf; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  top: 13px;
  right: 13px;
  position: absolute;
}
.btn-auto-complete {
  border-radius: 9999px !important;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  appearance: none !important;
}
input {
  -webkit-appearance: none;
}
.input-error {
  @apply border-promy-actions-danger;
}
.msg-error {
  @apply rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2;
}
.bordered {
  @apply border-promy-gray-150 border rounded-lg;
}
</style>
