<template>
  <transition name="modal-fade">
    <div
      :class="color[0]"
      class="flex flex-col w-96 sm:w-64 p-4 rounded-md fixed bottom-10 right-5 border-2 border-l-8"
    >
      <div class="flex justify-between">
        <div class="flex space-x-2">
          <span>
            <svg
              :class="color[1]"
              class="fill-current"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 11.25C12.1685 11.25 11.8505 11.3817 11.6161 11.6161C11.3817 11.8505 11.25 12.1685 11.25 12.5V17.5C11.25 17.8315 11.3817 18.1495 11.6161 18.3839C11.8505 18.6183 12.1685 18.75 12.5 18.75C12.8315 18.75 13.1495 18.6183 13.3839 18.3839C13.6183 18.1495 13.75 17.8315 13.75 17.5V12.5C13.75 12.1685 13.6183 11.8505 13.3839 11.6161C13.1495 11.3817 12.8315 11.25 12.5 11.25ZM12.975 6.35C12.6707 6.22498 12.3293 6.22498 12.025 6.35C11.8716 6.40949 11.7314 6.4987 11.6125 6.6125C11.5021 6.734 11.4133 6.87352 11.35 7.025C11.28 7.17335 11.2458 7.33603 11.25 7.5C11.2491 7.66451 11.2806 7.82759 11.3428 7.97988C11.405 8.13218 11.4967 8.2707 11.6125 8.3875C11.734 8.49791 11.8735 8.5867 12.025 8.65C12.2144 8.7278 12.42 8.7579 12.6237 8.73764C12.8274 8.71739 13.023 8.6474 13.1934 8.53384C13.3637 8.42027 13.5036 8.26661 13.6006 8.08634C13.6977 7.90607 13.749 7.70473 13.75 7.5C13.7454 7.16904 13.6159 6.85204 13.3875 6.6125C13.2686 6.4987 13.1284 6.40949 12.975 6.35ZM12.5 0C10.0277 0 7.61099 0.733112 5.55538 2.10663C3.49976 3.48015 1.89761 5.43238 0.951512 7.71646C0.00541605 10.0005 -0.242126 12.5139 0.24019 14.9386C0.722505 17.3634 1.91301 19.5907 3.66117 21.3388C5.40933 23.087 7.63661 24.2775 10.0614 24.7598C12.4861 25.2421 14.9995 24.9946 17.2836 24.0485C19.5676 23.1024 21.5199 21.5002 22.8934 19.4446C24.2669 17.389 25 14.9723 25 12.5C25 10.8585 24.6767 9.23303 24.0485 7.71646C23.4203 6.19989 22.4996 4.8219 21.3388 3.66117C20.1781 2.50043 18.8001 1.57969 17.2836 0.951506C15.767 0.323322 14.1415 0 12.5 0ZM12.5 22.5C10.5222 22.5 8.58879 21.9135 6.9443 20.8147C5.29981 19.7159 4.01809 18.1541 3.26121 16.3268C2.50433 14.4996 2.3063 12.4889 2.69215 10.5491C3.07801 8.60929 4.03041 6.82746 5.42894 5.42893C6.82746 4.03041 8.60929 3.078 10.5491 2.69215C12.4889 2.3063 14.4996 2.50433 16.3268 3.26121C18.1541 4.01808 19.7159 5.29981 20.8147 6.9443C21.9135 8.58879 22.5 10.5222 22.5 12.5C22.5 15.1522 21.4464 17.6957 19.5711 19.5711C17.6957 21.4464 15.1522 22.5 12.5 22.5Z"
              />
            </svg>
          </span>
          <span :class="color[1]" class="font-main font-semibold text-base">
            {{ title }}
          </span>
        </div>
        <span class="cursor-pointer" @click="close">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" rx="5" fill="#F4F4F4" />
            <path
              d="M10.9391 9.99943L13.803 7.14179C13.9284 7.01635 13.9989 6.84623 13.9989 6.66884C13.9989 6.49146 13.9284 6.32133 13.803 6.1959C13.6776 6.07047 13.5075 6 13.3301 6C13.1528 6 12.9827 6.07047 12.8572 6.1959L10 9.06021L7.14276 6.1959C7.01735 6.07047 6.84725 6 6.66988 6C6.49252 6 6.32242 6.07047 6.19701 6.1959C6.07159 6.32133 6.00114 6.49146 6.00114 6.66884C6.00114 6.84623 6.07159 7.01635 6.19701 7.14179L9.06091 9.99943L6.19701 12.8571C6.13458 12.919 6.08504 12.9927 6.05122 13.0738C6.01741 13.155 6 13.2421 6 13.33C6 13.418 6.01741 13.505 6.05122 13.5862C6.08504 13.6674 6.13458 13.741 6.19701 13.803C6.25892 13.8654 6.33259 13.915 6.41375 13.9488C6.49491 13.9826 6.58196 14 6.66988 14C6.75781 14 6.84486 13.9826 6.92602 13.9488C7.00718 13.915 7.08085 13.8654 7.14276 13.803L10 10.9387L12.8572 13.803C12.9192 13.8654 12.9928 13.915 13.074 13.9488C13.1551 13.9826 13.2422 14 13.3301 14C13.418 14 13.5051 13.9826 13.5863 13.9488C13.6674 13.915 13.7411 13.8654 13.803 13.803C13.8654 13.741 13.915 13.6674 13.9488 13.5862C13.9826 13.505 14 13.418 14 13.33C14 13.2421 13.9826 13.155 13.9488 13.0738C13.915 12.9927 13.8654 12.919 13.803 12.8571L10.9391 9.99943Z"
              fill="#34324F"
            />
          </svg>
        </span>
      </div>
      <div class="font-main font-normal text-xs text-promy-gray-250 mt-2 ml-8">
        {{ message }}
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
  computed: {
    color() {
      return [
        `border-promy-actions-${this.type}`,
        `text-promy-actions-${this.type}`,
      ]
    },
  },
}
</script>

<style scoped>
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
