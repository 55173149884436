<template>
  <div class="flex justify-end">
    <svg
      width="262"
      height="173"
      viewBox="0 0 166 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="82.8346"
        cy="83.316"
        r="82.482"
        fill="#39B7ED"
        fill-opacity="0.12"
      />
      <path
        d="M82.7162 44.4883C61.0636 44.4883 43.5127 62.0404 43.5127 83.6906C43.5127 105.341 61.0636 122.893 82.7162 122.893C104.368 122.893 121.917 105.34 121.917 83.6906C121.917 62.0404 104.369 44.4883 82.7162 44.4883ZM82.7162 114.8C65.5586 114.8 51.6043 100.843 51.6043 83.6918C51.6043 66.539 65.5598 52.5811 82.7162 52.5811C99.8689 52.5811 113.826 66.539 113.826 83.6918C113.826 100.843 99.8689 114.8 82.7162 114.8Z"
        fill="#10AAAF"
      />
      <path
        d="M102.676 71.6573L86.4281 74.9544C83.884 73.8766 80.9154 73.8682 78.2835 75.2527C73.6213 77.7053 71.8362 83.4634 74.2852 88.1269C76.7355 92.7855 82.4959 94.5718 87.1558 92.1215C89.7876 90.7346 91.4644 88.2844 92.0177 85.578L103.942 74.057C104.393 73.6216 104.503 72.9384 104.21 72.3815C103.918 71.8282 103.295 71.5322 102.676 71.6573Z"
        fill="#10AAAF"
      />
      <path
        d="M96.8215 100.184H68.6025V105.57H96.8215V100.184Z"
        fill="#10AAAF"
      />
    </svg>
  </div>
</template>

<script>
export default {}
</script>
