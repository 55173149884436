<template>
  <div :id="size" class="loading loading--full-height"></div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'sm_spinner',
    },
  },
}
</script>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
}

#sm_spinner.loading::after {
  content: '';
  width: 35px;
  height: 35px;
  border: 7px solid #dddddd;
  border-top-color: theme('colors.promy-green.350');
  border-radius: 50%;
  animation: loading 1s ease infinite;
}

#md_spinner.loading::after {
  content: '';
  width: 75px;
  height: 75px;
  border: 7px solid #dddddd;
  border-top-color: theme('colors.promy-green.350');
  border-radius: 50%;
  animation: loading 1s ease infinite;
}

@keyframes loading {
  to {
    transform: rotate(1turn);
  }
}
</style>
