<template>
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.7788 6.92198C8.7788 8.11519 7.78271 8.11519 7.24591 8.11519H6.57661V5.85946H7.4177C7.88807 5.85946 8.7788 5.85946 8.7788 6.92198ZM13.9203 5.73089C14.0196 4.51678 14.3743 3.40255 15 2.38111L12.6003 0C11.8421 0.64743 10.9792 1.00585 10.0006 1.06925C9.12017 1.15317 8.2344 0.976433 7.4552 0.561366C6.6334 0.967249 5.78695 1.1369 4.90443 1.06925C3.99156 0.988146 3.16797 0.667264 2.43009 0.0984604L0.0236159 2.47851C0.615414 3.51447 0.945064 4.59895 1.01007 5.73089C1.04078 6.25223 0.890777 6.96979 0.554341 7.89737C0.378266 8.41163 0.245049 8.85789 0.154333 9.23048C0.0704027 9.6006 0.0179015 9.90164 0.000401125 10.1269C-0.0120991 11.1154 0.26755 12.0079 0.840776 12.8009C1.28864 13.38 2.02723 14.0193 3.05296 14.7188C4.17513 15.2854 5.04372 15.6534 5.65195 15.81L6.15624 16.0423C6.31482 16.1181 6.48482 16.1914 6.66233 16.2715C7.04483 16.4988 7.31377 16.7457 7.4552 17C7.62877 16.7248 7.90342 16.4843 8.26843 16.2715C8.48756 16.1784 8.70464 16.0806 8.91952 15.9782C9.09452 15.9021 9.22488 15.8447 9.3006 15.8096C9.46619 15.7301 9.63503 15.6575 9.80668 15.5918C10.0149 15.5107 10.2717 15.4112 10.5785 15.3014C11.1713 15.0928 11.6028 14.8962 11.8771 14.7184C12.8717 14.0189 13.5989 13.3903 14.0618 12.8275C14.6554 12.0309 14.9436 11.1345 14.9304 10.1265C14.8954 9.67533 14.7029 8.95317 14.3532 7.96927C14.0196 7.01158 13.8718 6.26782 13.9203 5.73089ZM9.50953 8.99283C8.93987 9.36259 8.15664 9.36259 7.74771 9.36259H6.62447V12.4106H4.9355V4.59187H7.33305C8.44736 4.59187 9.37917 4.66306 10.0263 5.44543C10.4303 5.94339 10.4985 6.50901 10.4985 6.911C10.4978 7.82158 10.1195 8.59014 9.50953 8.99283Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
