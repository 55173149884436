<template>
  <validation-provider :rules="rules" v-slot="{ errors }">
    <label
      :class="[
        classes,
        value === label ? ' text-promy-orange-300' : 'text-promy-purple-300',
      ]"
      class="pr-6 py-2 rounded-lg font-semibold font-dosis flex items-center"
    >
      <input
        type="radio"
        :id="uniqueId"
        :value="label"
        @click="check"
        v-model="value"
      />
      <span class="m-0" :for="uniqueId" />
      <div>
        <slot />
      </div>
    </label>
    <span v-show="errors[0]" class="msg-error">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
    },

    classes: {
      type: String,
      default: 'flex-row',
    },
    rules: {
      type: String,
    },
  },
  computed: {
    uniqueId() {
      return `radio-${this._uid}`
    },
  },
  methods: {
    check() {
      this.$emit('change', this.label)
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  @apply flex cursor-pointer relative overflow-hidden;
  margin-bottom: 0.375em;

  input {
    position: absolute;
    left: -9999px;
    &:checked + span {
      &:before {
        box-shadow: inset 0 0 0 4px theme('colors.promy-green.350');
      }
    }
  }
  span {
    @apply flex items-center mr-2;
    border-radius: 99em;
    transition: 0.25s ease;
    &:before {
      @apply flex w-4 h-4 rounded-full bg-white;
      flex-shrink: 0;
      content: '';
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 2px theme('colors.promy-gray.150');
    }
  }
}
</style>
