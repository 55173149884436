import transport from '@/plugins/axios'
import axios from 'axios'
const getDefaultState = () => {
  return {
    current_parcelle: {},
    parcelles_voisines: [],
    parcelles_autour: [],
    features_autour: [],
    is_parcelle_changing: false,
    is_parcelle_aggregating: false,
    current_batiments: [],
    cancel_save_selection_parcelles: false,
    batiment_selected: null,
    altimetries: [],
    is_altimetries_loading: false,
    source_cancel_token: null,
    ids_parcelles_selected: [],
    parcelle_principale: null,
  }
}
export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    parcelles_autour(state) {
      return state.parcelles_autour
    },
    current_parcelle(state) {
      return state.current_parcelle
    },
    current_batiments(state) {
      return state.current_batiments
    },
    is_parcelle_changing(state) {
      return state.is_parcelle_changing
    },
    is_parcelle_aggregating(state) {
      return state.is_parcelle_aggregating
    },
    parcelles_voisines(state) {
      return state.parcelles_voisines
    },
    features_autour(state) {
      return state.features_autour
    },
    cancel_save_selection_parcelles(state) {
      return state.cancel_save_selection_parcelles
    },
    batiment_selected(state) {
      return state.batiment_selected
    },
    altimetries(state) {
      return state.altimetries
    },
    is_altimetries_loading(state) {
      return state.is_altimetries_loading
    },
    ids_parcelles_selected(state) {
      return state.ids_parcelles_selected
    },
    parcelles_selected(state) {
      return state.current_parcelle &&
        Object.keys(state.current_parcelle).length !== 0
        ? [state.current_parcelle, ...state.parcelles_voisines]
        : []
    },
    parcelle_principale(state) {
      return state.parcelle_principale
    },
  },
  mutations: {
    RestStoreGUParcelle(state) {
      Object.assign(state, getDefaultState())
    },
    FEATURES_AUTOUR(state, payload) {
      state.features_autour = Object.freeze(payload)
    },
    PARCELLES_AUTOUR(state, payload) {
      state.parcelles_autour = Object.freeze(payload)
    },
    CURRENT_PARCELLE(state, payload) {
      state.current_parcelle = payload
    },
    CURRENT_BATIMENTS(state, payload) {
      state.current_batiments = payload
    },

    IS_PARCELLE_CHANGING(state, payload) {
      state.is_parcelle_changing = payload
    },
    IS_PARCELLE_AGGREGATING(state, payload) {
      state.is_parcelle_aggregating = payload
    },
    PARCELLES_VOISINES(state, payload) {
      if (Array.isArray(payload) === false)
        state.parcelles_voisines.push(payload)
      else state.parcelles_voisines = payload
    },
    CANCEL_SAVE_SELECTION_PARCELLES(state, payload) {
      state.cancel_save_selection_parcelles = payload
    },

    BATIMENT_SELECTED(state, payload) {
      state.batiment_selected = payload
    },
    ALTIMETRIES(state, payload) {
      state.altimetries = payload
    },
    IS_ALTIMETRIES_LOADING(state, payload) {
      state.is_altimetries_loading = payload
    },
    SOURCE_CANCEL_TOKEN(state, payload) {
      state.source_cancel_token = payload
    },
    IDS_PARCELLES_SELECTED(state, payload) {
      state.ids_parcelles_selected = payload
    },
    PARCELLE_PARINCIPALE(state, payload) {
      state.parcelle_principale = payload
    },
  },
  actions: {
    getAltimetries({ state, commit }, center) {
      state.source_cancel_token && state.source_cancel_token.cancel()
      const CancelToken = axios.CancelToken
      commit('SOURCE_CANCEL_TOKEN', CancelToken.source())
      commit('IS_ALTIMETRIES_LOADING', true)
      transport
        .get('grand-publics/get-altimetrie', {
          cancelToken: state.source_cancel_token.token,
          params: {
            ids_parcelle: [
              state.current_parcelle,
              ...state.parcelles_voisines,
            ].map((item) => item.id),
            lon: center[0],
            lat: center[1],
          },
        })
        .then((response) => {
          commit('ALTIMETRIES', response.data.features)
          commit('IS_ALTIMETRIES_LOADING', false)
        })
        .catch((error) => {
          commit('ALTIMETRIES', [])
          commit('IS_ALTIMETRIES_LOADING', false)
        })
    },
  },
}
