import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import '@/mixins/ToastMixins'
import './plugins'
import './filters'
import './utilities'

import _ from 'lodash'
Vue.config.productionTip = false
Vue.prototype.$base_file = process.env.VUE_APP_BASE_STORAGE
Vue.prototype.$mapbox_directions = process.env.VUE_APP_MAPBOX_DIRECTIONS_API

store.dispatch('auth/me').then(() => {
  new Vue({
    render: (h) => h(App),
    router,
    store,
  }).$mount('#app')
})
