<template>
  <svg
    width="30"
    height="29"
    viewBox="0 0 30 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0138 10.8951L15.9431 14.9777C14.9835 15.7301 13.6383 15.7301 12.6787 14.9777L7.56445 10.8951"
      :stroke="color"
      stroke-width="2.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.67392 4.28415H19.8817C21.4977 4.30227 23.0358 4.98545 24.1378 6.17456C25.2398 7.36367 25.8094 8.95493 25.7141 10.5777V18.338C25.8094 19.9608 25.2398 21.552 24.1378 22.7411C23.0358 23.9303 21.4977 24.6134 19.8817 24.6315H8.67392C5.20277 24.6315 2.86328 21.8076 2.86328 18.338V10.5777C2.86328 7.10805 5.20277 4.28415 8.67392 4.28415Z"
      :stroke="color"
      stroke-width="2.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>

<style></style>
