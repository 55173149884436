import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import Tunnel from './modules/Tunnel.js'
import sidebar from './modules/sidebar'
import auth from './modules/auth'
import address from './modules/GU/address'
import parcelle from './modules/GU/parcelle'
import etudeCommerciale from './modules/GU/etudeCommerciale'
import map from './modules/map'

export default new Vuex.Store({
  modules: {
    sidebar,
    Tunnel,
    auth,
    address,
    parcelle,
    etudeCommerciale,
    map,
  },
})
