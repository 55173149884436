<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9118 19.3155L15.9119 19.3155C16.1279 19.1377 16.1629 18.8204 15.9915 18.5995L15.9914 18.5994L10.2667 11.2398V2.24993C10.2667 1.9647 10.0352 1.73325 9.75 1.73325C4.8614 1.73325 0.9 6.08596 0.9 11.4166C0.9 16.7559 5.24395 21.0999 10.5833 21.0999C11.771 21.0999 12.6938 20.9924 13.526 20.7139C14.359 20.4352 15.0948 19.9873 15.9118 19.3155ZM10.5833 20.0666C5.81355 20.0666 1.93332 16.1864 1.93332 11.4166C1.93332 6.83082 5.17123 3.08035 9.23332 2.78502V11.4166C9.23332 11.5313 9.27152 11.643 9.34279 11.7339C9.34284 11.734 9.3429 11.7341 9.34295 11.7341L14.8604 18.828C14.2411 19.2907 13.6698 19.5957 13.0225 19.7876C12.346 19.9881 11.5811 20.0666 10.5833 20.0666Z"
      :fill="current_color"
      :stroke="current_color"
      stroke-width="0.2"
    />
    <path
      d="M11.4167 11.1H20.5834C20.8686 11.1 21.1 10.8685 21.1 10.5833C21.1 5.24395 16.756 0.9 11.4167 0.9C11.1315 0.9 10.9 1.13145 10.9 1.41668V10.5834C10.9 10.8686 11.1315 11.1 11.4167 11.1ZM11.9333 10.0666V1.94877C16.2925 2.20673 19.7933 5.70751 20.0512 10.0666H11.9333Z"
      :fill="current_color"
      :stroke="current_color"
      stroke-width="0.2"
    />
    <path
      d="M18.019 19.3338L18.0187 19.3341C17.9228 19.4228 17.7969 19.4724 17.6666 19.4724H17.6655L18.019 19.3338ZM18.019 19.3338C19.9767 17.5075 21.0999 14.926 21.0999 12.2499C21.0999 11.9647 20.8685 11.7333 20.5832 11.7333H12.2499M18.019 19.3338L12.2499 11.7333M12.2499 11.7333C12.0515 11.7333 11.8688 11.8478 11.783 12.0277M12.2499 11.7333L11.783 12.0277M11.783 12.0277C11.6971 12.2076 11.723 12.4205 11.848 12.5745M11.783 12.0277L11.848 12.5745M11.848 12.5745C11.848 12.5745 11.8481 12.5746 11.8481 12.5746M11.848 12.5745L11.8481 12.5746M11.8481 12.5746L17.2643 19.2798C17.2643 19.2798 17.2644 19.2799 17.2644 19.28C17.3546 19.3929 17.487 19.4608 17.6297 19.4713C17.6296 19.4713 17.6294 19.4713 17.6292 19.4712L11.8481 12.5746ZM20.051 12.7666C19.9285 14.785 19.0956 16.7121 17.7086 18.1852L13.3317 12.7666H20.051Z"
      :fill="current_color"
      :stroke="current_color"
      stroke-width="0.2"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
    hoverColor: {
      type: String,
      default: 'white',
    },
    hovering: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      current_color: this.color,
    }
  },

  watch: {
    color(value) {
      this.current_color = value
    },
    hovering(value) {
      this.current_color = this.color
      if (value) this.current_color = this.hoverColor
    },
  },
}
</script>
