<template>
  <div class="flex flex-col w-full">
    <!-- by slot  -->

    <div class="flex flex-col">
      <div class="container-tabs" :class="classTabs">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          class="flex flex-col justify-between"
        >
          <div class="flex">
            <div
              :id="tab.label + '_' + index"
              class="label-tab"
              @click="changeTab(tab)"
              :class="[
                component.label === tab.label
                  ? 'text-promy-primary-100'
                  : ' text-promy-gray-250',
                classHeaders,
              ]"
            >
              {{ tab.label }}
            </div>
          </div>
          <hr
            class="hr-active"
            :class="{
              'opacity-0': component.label !== tab.label,
            }"
          />
        </div>
      </div>
      <hr class="hr-tabs" />
    </div>
    <div :class="classes_slot">
      <slot v-bind:current_tab="component"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      require: true,
    },
    classes_slot: {
      type: String,
      default: 'mt-8',
    },
    classHeaders: {
      type: String,
      default: 'text-base',
    },
    classTabs: {
      type: String,
      default: 'space-x-8',
    },
  },
  data() {
    return {
      component: {},
    }
  },
  created() {
    this.component = this.tabs.find((tab) => tab.is_active)
  },
  methods: {
    changeTab(tab) {
      this.component = tab
      this.$emit('changeTab', tab)
    },
  },
}
</script>

<style lng="scss" scoped>
.hr-active {
  @apply bg-promy-blue-270 h-0.5 border-transparent rounded-full z-10;
}
.container-tabs {
  @apply flex relative sm:space-x-6;
}
.hr-tabs {
  @apply text-promy-gray-50 -m-px;
}
.label-tab {
  @apply cursor-pointer  first-letter:uppercase flex-1 text-sm font-medium leading-5 mx-1 mb-2;
}
</style>
