<template>
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3588 20.9336L11.3588 20.9335L0.991839 22.3935L0.918733 22.4618C0.918735 22.4618 0.918736 22.4618 0.918738 22.4618C1.30325 22.8738 1.83372 23.1 2.41011 23.1H9.33375C9.91032 23.1 10.4408 22.8737 10.8255 22.4617C11.2093 22.0505 11.3983 21.507 11.3588 20.9336ZM1.66053 21.7693L1.58743 21.8376L1.66053 21.7693C1.47159 21.5669 1.37739 21.2959 1.39754 21.0034L2.06775 11.2852H9.67611L10.3463 21.0033C10.3665 21.296 10.2723 21.5671 10.0837 21.7692C9.89418 21.9721 9.62887 22.0852 9.33375 22.0852H2.41011C2.11511 22.0852 1.84989 21.9722 1.66053 21.7693Z"
      :fill="current_color"
      :stroke="current_color"
      stroke-width="0.2"
    />
    <path
      d="M19.5907 20.9351L19.5907 20.9351L12.7349 22.393L12.6618 22.4613C12.6618 22.4613 12.6618 22.4613 12.6618 22.4613C13.0466 22.8736 13.5781 23.1001 14.1558 23.1001H17.564C18.1429 23.1001 18.6746 22.8742 19.0589 22.4626L18.9858 22.3943L19.0589 22.4626C19.4419 22.0522 19.6302 21.509 19.5907 20.9351ZM18.317 21.7701L18.317 21.7701C18.1283 21.9723 17.862 22.0852 17.5639 22.0852H14.1557C13.8593 22.0852 13.5932 21.972 13.4036 21.7688L13.4036 21.7688C13.2152 21.567 13.1212 21.2966 13.1414 21.0049L13.1414 21.0049L13.5307 15.3593H18.1889L18.5782 21.0048C18.5985 21.2983 18.5047 21.569 18.317 21.7701Z"
      :fill="current_color"
      :stroke="current_color"
      stroke-width="0.2"
    />
    <path
      d="M9.46361 5.17088L9.4636 5.17087C9.35567 4.96833 9.39708 4.71852 9.56471 4.5617M9.46361 5.17088L9.56471 4.5617M9.46361 5.17088C9.5803 5.3898 9.64212 5.63815 9.64212 5.88886C9.64212 6.73219 8.95582 7.41849 8.11249 7.41849C8.07011 7.41849 8.02819 7.41675 7.98665 7.4134L7.9863 7.41337M9.46361 5.17088L7.9863 7.41337M9.56471 4.5617C9.87756 4.26894 10.0496 3.87265 10.0496 3.44445C10.0496 2.60112 9.36325 1.91482 8.51992 1.91482C8.03399 1.91482 7.58835 2.13759 7.29599 2.5268C7.12769 2.7509 6.80959 2.79614 6.58555 2.62777L9.56471 4.5617ZM7.9863 7.41337C7.76871 7.39656 7.56642 7.51825 7.48016 7.71774L7.9863 7.41337ZM4.92324 5.18827L4.92325 5.18828C5.06414 5.26705 5.15918 5.4082 5.17915 5.56837C5.17916 5.56838 5.17916 5.56839 5.17916 5.5684L5.07993 5.58077C5.096 5.70942 5.04985 5.83802 4.9557 5.9271C4.62691 6.23819 4.44584 6.65868 4.44584 7.11106C4.44584 8.00962 5.17691 8.74069 6.07547 8.74069C6.72615 8.74069 7.31357 8.35475 7.57194 7.75744L4.92324 5.18827ZM4.92324 5.18827C4.43914 4.91768 4.13845 4.40551 4.13845 3.85184M4.92324 5.18827L4.13845 3.85184M4.13845 3.85184C4.13845 3.0085 4.82475 2.3222 5.66808 2.3222M4.13845 3.85184L5.66808 2.3222M5.66808 2.3222C6.00246 2.3222 6.31939 2.4278 6.58554 2.62776L5.66808 2.3222ZM11.0644 3.44445C11.0644 2.04138 9.92299 0.9 8.51992 0.9C7.87046 0.9 7.26287 1.14006 6.79724 1.57069C6.4487 1.39746 6.06519 1.30743 5.66808 1.30743C4.26501 1.30743 3.12363 2.44881 3.12363 3.85188C3.12363 4.57059 3.42792 5.24681 3.94381 5.72297C3.67752 6.1329 3.53102 6.61618 3.53102 7.11115C3.53102 8.51422 4.6724 9.65559 6.07547 9.65559C6.97419 9.65559 7.79554 9.18407 8.25231 8.42956C9.59072 8.35681 10.657 7.2452 10.657 5.8889C10.657 5.59633 10.6062 5.30537 10.5085 5.03152C10.865 4.58361 11.0644 4.02236 11.0644 3.44445Z"
      :fill="current_color"
      :stroke="current_color"
      stroke-width="0.2"
    />
    <path
      d="M16.8311 11.7366L16.8311 11.7366C16.9326 11.5573 17.1322 11.4566 17.3375 11.4832M16.8311 11.7366L18.3593 9.03438C18.2106 9.21985 18.2106 9.48373 18.3593 9.66917C18.5203 9.87 18.6052 10.112 18.6052 10.3703C18.6052 10.989 18.1017 11.4925 17.483 11.4925C17.4343 11.4925 17.3854 11.4894 17.3375 11.4832M16.8311 11.7366C16.6319 12.0886 16.2571 12.3073 15.8534 12.3073C15.2347 12.3073 14.7312 11.8038 14.7312 11.1851C14.7312 10.9213 14.8245 10.665 14.9939 10.4635L14.9939 10.4635M16.8311 11.7366L14.9939 10.4635M17.3375 11.4832L17.3247 11.5824L17.3374 11.4832C17.3375 11.4832 17.3375 11.4832 17.3375 11.4832ZM14.9939 10.4635C15.0893 10.3499 15.1303 10.2003 15.106 10.0539L14.9939 10.4635ZM18.6053 8.33324C18.6053 8.59154 18.5203 8.83355 18.3593 9.03437L15.106 10.0539C15.0817 9.90754 14.9945 9.77917 14.8675 9.70254C14.5268 9.49699 14.3238 9.13787 14.3238 8.74063C14.3238 8.12194 14.8273 7.61843 15.446 7.61843C15.6347 7.61843 15.8132 7.66332 15.9775 7.75197L15.9776 7.75199C16.1918 7.86746 16.4582 7.81462 16.612 7.62568L16.612 7.62567C16.8265 7.36202 17.1438 7.21104 17.4831 7.21104C18.1018 7.21104 18.6053 7.71455 18.6053 8.33324ZM19.62 8.33329C19.62 7.15485 18.6614 6.19626 17.483 6.19626C16.9693 6.19626 16.4814 6.37835 16.098 6.70545C15.8881 6.63839 15.6677 6.60369 15.446 6.60369C14.2675 6.60369 13.3089 7.56228 13.3089 8.74072C13.3089 9.30925 13.5399 9.85219 13.9326 10.2485C13.7915 10.5382 13.7163 10.8587 13.7163 11.1852C13.7163 12.3636 14.6749 13.3222 15.8533 13.3222C16.5128 13.3222 17.131 13.0163 17.5326 12.5068C18.6882 12.4803 19.62 11.5322 19.62 10.3703C19.62 10.0138 19.5298 9.66273 19.3615 9.35182C19.5298 9.04091 19.62 8.68981 19.62 8.33329Z"
      :fill="current_color"
      :stroke="current_color"
      stroke-width="0.2"
    />
    <path
      d="M9.0275 19.7407V19.6407H8.9275H2.81641H2.71641V19.7407V20.5555V20.6555H2.81641H8.9275H9.0275V20.5555V19.7407Z"
      :fill="current_color"
      :stroke="current_color"
      stroke-width="0.2"
    />
    <path
      d="M17.1757 20.5555V20.4555H17.0757H14.6312H14.5312V20.5555V21.3703V21.4703H14.6312H17.0757H17.1757V21.3703V20.5555Z"
      :fill="current_color"
      :stroke="current_color"
      stroke-width="0.2"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
    hoverColor: {
      type: String,
      default: 'white',
    },
    hovering: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      current_color: this.color,
    }
  },

  watch: {
    color(value) {
      this.current_color = value
    },
    hovering(value) {
      this.current_color = this.color
      if (value) this.current_color = this.hoverColor
    },
  },
}
</script>
