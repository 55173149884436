<template>
  <Popup
    v-show="show_modal_pdf"
    @close="$emit('closeModalPDF')"
    max_width_model=" w-full h-[90vh]"
    max_height_body="h-full"
  >
    <template #body>
      <template v-if="isValid">
        <iframe
          id="myframe"
          class="w-full h-full"
          :src="url"
          type="application/pdf"
        >
        </iframe>
      </template>
      <template v-else>
        <p
          class="flex items-center justify-center w-full h-full text-lg font-semibold"
        >
          Ce pdf n'est pas disponible
        </p>
      </template>
    </template>
  </Popup>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
    },
    show_modal_pdf: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isValid() {
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      ) // fragment locator
      return !!pattern.test(this.url)
    },
  },
}
</script>

<style lang="scss" scoped></style>
