<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6722 5.55233H11.2904C11.164 5.55233 11.0606 5.65698 11.0606 5.78489V13.7878C11.0606 13.8634 11.0951 13.9331 11.1554 13.9767L15.904 17.4855C16.0075 17.561 16.1511 17.5407 16.2258 17.436L17.0474 16.3023C17.1249 16.1948 17.102 16.0494 16.9985 15.9767L12.902 12.9797V5.78489C12.902 5.65698 12.7986 5.55233 12.6722 5.55233ZM18.9865 7.73837L23.4909 8.85175C23.6345 8.88663 23.7753 8.77617 23.7753 8.62791L23.7983 3.93314C23.7983 3.73838 23.5771 3.62791 23.4277 3.75L18.9003 7.32849C18.8661 7.35528 18.84 7.39129 18.8251 7.43239C18.8102 7.47349 18.8071 7.51801 18.8161 7.56083C18.8251 7.60365 18.8459 7.64303 18.876 7.67443C18.9062 7.70584 18.9444 7.72801 18.9865 7.73837ZM23.804 16.4913L22.1752 15.9244C22.1184 15.9047 22.0563 15.9083 22.002 15.9344C21.9478 15.9604 21.9058 16.007 21.8851 16.064C21.8305 16.2122 21.773 16.3576 21.7127 16.5029C21.2013 17.7267 20.4688 18.8285 19.5323 19.7733C18.6062 20.7133 17.5086 21.4626 16.3005 21.9797C15.0489 22.5151 13.7036 22.79 12.3447 22.7878C10.9716 22.7878 9.6415 22.5174 8.38899 21.9797C7.18084 21.4626 6.08329 20.7133 5.15718 19.7733C4.22354 18.8285 3.491 17.7267 2.97678 16.5029C2.45055 15.2357 2.18093 13.8745 2.18391 12.5C2.18391 11.1105 2.45107 9.76163 2.98253 8.49419C3.49387 7.27035 4.22642 6.16861 5.16292 5.22384C6.08903 4.28378 7.18658 3.53447 8.39474 3.01744C9.6415 2.47965 10.9744 2.20931 12.3476 2.20931C13.7208 2.20931 15.0508 2.47965 16.3033 3.01744C17.5115 3.53447 18.6091 4.28378 19.5352 5.22384C19.8282 5.52326 20.104 5.8343 20.3568 6.16279L22.0747 4.80233C19.8138 1.87791 16.2947 -0.00290361 12.3419 3.36494e-06C5.45881 0.00291034 -0.0683073 5.65989 0.000638067 12.6279C0.0695835 19.4738 5.57085 25 12.3476 25C17.6765 25 22.2154 21.5814 23.9448 16.7936C23.9879 16.6715 23.9247 16.5349 23.804 16.4913Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
