<template>
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M17.2369 24.3521C16.4193 23.3399 10.1122 15.5302 10.1073 15.5242C12.9633 16.4021 15.8323 14.2528 15.8323 11.2992C15.8323 7.2814 10.8999 5.36431 8.17925 8.26717C8.03539 8.42073 8.04316 8.66189 8.19672 8.8058C8.35028 8.94967 8.59144 8.94185 8.73536 8.78834C10.9697 6.40416 15.0702 7.92891 15.0702 11.2992C15.0702 13.1876 13.6508 14.7526 11.7687 14.9393C8.98525 15.216 7.00884 12.4472 7.99984 9.94726C8.07733 9.7516 7.98166 9.53015 7.786 9.4526C7.59034 9.37511 7.36888 9.47078 7.29134 9.66644C7.04576 10.2862 6.9376 10.9609 6.9995 11.6759L4.64701 8.76289C6.15679 8.68342 7.12843 7.09148 6.48661 5.70586C6.26317 5.22303 5.87104 4.84157 5.38257 4.63174C5.18925 4.54851 4.9651 4.63809 4.88207 4.83146C4.79895 5.02484 4.88837 5.24888 5.0818 5.33196C6.17999 5.80377 6.20843 7.3689 5.11658 7.87453C4.19125 8.30287 3.1388 7.61945 3.1388 6.61221C3.1388 6.08251 3.44618 5.59074 3.92185 5.35928C4.11111 5.26716 4.18988 5.03916 4.09781 4.84994C4.00574 4.66073 3.77773 4.58197 3.58847 4.67399C2.55203 5.1783 2.07809 6.43478 2.57503 7.51276C2.33382 7.50291 2.10114 7.52241 1.86262 7.57553C1.36217 7.68689 1.0127 8.12107 1.0127 8.63147C1.0127 9.39187 1.0127 24.8854 1.0127 25.6088C1.0127 25.8193 1.18327 25.9899 1.39376 25.9899H16.4534L16.4741 25.9897C17.3273 25.9788 17.7544 24.9927 17.2369 24.3521ZM1.77467 8.63162C1.77472 8.48111 1.87892 8.35278 2.02812 8.31952C2.66105 8.1786 3.3723 8.34512 3.75757 8.874C3.76763 8.88782 3.59888 8.67804 6.92912 12.8018C6.60016 12.6467 6.29577 12.4585 5.62059 12.4585C4.50182 12.4585 4.43754 12.9666 3.54724 12.9666C2.72666 12.9666 2.63805 12.5579 1.77462 12.4728V8.63162H1.77467ZM16.4655 25.2278L1.77472 25.2279C1.77472 24.6372 1.77472 13.8029 1.77472 13.24C2.37307 13.3249 2.55325 13.7287 3.54734 13.7287C4.66803 13.7287 4.72653 13.2207 5.62069 13.2207C6.52134 13.2207 6.55968 13.7232 7.67758 13.7285C7.6818 13.7337 16.6993 24.8648 16.6993 24.9822C16.6992 25.0829 16.6262 25.2257 16.4655 25.2278Z"
        :fill="color"
      />
      <path
        d="M21.4366 8.74954C20.7319 8.74954 20.1586 9.32286 20.1586 10.0276C20.1586 10.7323 20.7319 11.3057 21.4366 11.3057C22.1414 11.3057 22.7147 10.7323 22.7147 10.0276C22.7147 9.32286 22.1414 8.74954 21.4366 8.74954ZM21.4366 10.5435C21.1522 10.5435 20.9207 10.3121 20.9207 10.0276C20.9207 9.74313 21.1522 9.51167 21.4366 9.51167C21.7212 9.51167 21.9526 9.74313 21.9526 10.0276C21.9526 10.312 21.7211 10.5435 21.4366 10.5435Z"
        :fill="color"
      />
      <path
        d="M25.6902 8.69316C24.9854 8.69316 24.4121 9.26648 24.4121 9.97122C24.4121 10.676 24.9854 11.2493 25.6902 11.2493C26.3949 11.2493 26.9682 10.676 26.9682 9.97122C26.9682 9.26648 26.3949 8.69316 25.6902 8.69316ZM25.6902 10.4872C25.4057 10.4872 25.1742 10.2557 25.1742 9.97122C25.1742 9.68675 25.4056 9.45529 25.6902 9.45529C25.9747 9.45529 26.2061 9.68675 26.2061 9.97122C26.2061 10.2557 25.9746 10.4872 25.6902 10.4872Z"
        :fill="color"
      />
      <path
        d="M13.433 0C12.7282 0 12.1549 0.57332 12.1549 1.27806C12.1549 1.9828 12.7282 2.55618 13.433 2.55618C14.1377 2.55618 14.711 1.98286 14.711 1.27811C14.711 0.573371 14.1377 0 13.433 0ZM13.433 1.794C13.1485 1.794 12.917 1.56254 12.917 1.27806C12.917 0.993586 13.1484 0.762125 13.433 0.762125C13.7175 0.762125 13.9489 0.993586 13.9489 1.27806C13.9489 1.56254 13.7174 1.794 13.433 1.794Z"
        :fill="color"
      />
      <path
        d="M25.0954 1.3033C24.1548 0.236234 22.3918 0.909239 22.3918 2.32522C22.3918 2.70136 22.5289 3.06399 22.7778 3.34639C23.071 3.6789 23.4935 3.86964 23.937 3.86964C25.2608 3.86964 25.9709 2.29658 25.0954 1.3033ZM23.9371 3.10756C23.5055 3.10756 23.154 2.75788 23.154 2.32528C23.154 1.60779 24.0469 1.26638 24.5238 1.80731C24.9679 2.31121 24.6064 3.10756 23.9371 3.10756Z"
        :fill="color"
      />
      <path
        d="M2.76788 0.0191193C2.0282 0.0191193 1.42639 0.620928 1.42639 1.36061C1.42639 2.10029 2.02815 2.7022 2.76788 2.7022C3.50761 2.7022 4.10942 2.10034 4.10942 1.36066C4.10942 0.620979 3.50756 0.0191193 2.76788 0.0191193ZM2.76788 1.94007C2.44842 1.94007 2.18847 1.68012 2.18847 1.36061C2.18847 1.04114 2.44842 0.781193 2.76788 0.781193C3.0874 0.781193 3.34734 1.04114 3.34734 1.36061C3.34729 1.68017 3.0874 1.94007 2.76788 1.94007Z"
        :fill="color"
      />
      <path
        d="M26.374 17.9236L22.5864 15.6409C22.4212 15.5413 22.2317 15.4879 22.0372 15.4859H22.0194C21.8243 15.4869 21.6327 15.5403 21.4661 15.6409L17.6784 17.9236C16.795 18.4547 17.0048 19.7945 18.0031 20.0362C18.0031 20.6975 18.0031 24.477 18.0031 25.2728C18.0031 25.6803 18.3958 25.9999 18.8973 25.9999C19.5226 25.9999 24.5452 25.9999 25.1551 25.9999C25.6566 25.9999 26.0493 25.6803 26.0493 25.2728V20.0357C27.0558 19.792 27.2478 18.4489 26.374 17.9236ZM25.2873 25.2113H25.2873C25.18 25.2619 25.1103 25.2255 23.855 25.2378V24.7709C23.855 24.5604 23.6845 24.3898 23.474 24.3898C23.2635 24.3898 23.0929 24.5604 23.0929 24.7709V25.2378H20.9595V23.4686C20.9595 22.8827 21.4397 22.4022 22.0264 22.4022C22.5476 22.4022 23.0007 22.7908 23.0805 23.306C23.1127 23.5139 23.3069 23.6567 23.5154 23.6242C23.7234 23.592 23.8658 23.3973 23.8336 23.1893C23.6969 22.3062 22.92 21.6402 22.0265 21.6401C21.0196 21.6401 20.1975 22.46 20.1975 23.4686V25.2378C18.9412 25.2255 18.873 25.2621 18.7652 25.2113C18.7652 24.5463 18.7652 20.6164 18.7652 19.96C18.874 19.9079 18.6964 20.0058 22.026 18.0019C25.3544 20.0053 25.1934 19.9163 25.2873 19.96V25.2113H25.2873ZM25.8721 19.2944C25.588 19.3647 25.7469 19.3065 22.2597 17.2525C22.1154 17.1661 21.9366 17.1661 21.7928 17.253L18.4781 19.2502C18.3226 19.3442 18.1281 19.3173 18.0031 19.1978C17.8146 19.0172 17.8517 18.7091 18.0717 18.576C22.0328 16.2068 21.8611 16.248 22.0209 16.248H22.03C22.1936 16.248 22.0238 16.2094 25.9802 18.576C26.28 18.757 26.2113 19.2109 25.8721 19.2944Z"
        :fill="color"
      />
      <path
        d="M17.8185 3.54207C17.1137 3.54207 16.5404 4.11539 16.5404 4.82013C16.5404 5.52487 17.1137 6.09819 17.8185 6.09819C18.5232 6.09819 19.0965 5.52487 19.0965 4.82013C19.0965 4.11539 18.5232 3.54207 17.8185 3.54207ZM17.8185 5.33607C17.534 5.33607 17.3025 5.10461 17.3025 4.82013C17.3025 4.53565 17.5339 4.30419 17.8185 4.30419C18.103 4.30419 18.3344 4.53565 18.3344 4.82013C18.3344 5.10461 18.1029 5.33607 17.8185 5.33607Z"
        :fill="color"
      />
      <path
        d="M19.0643 13.7746C18.4986 13.1328 17.5233 13.0738 16.8839 13.6372C16.2449 14.2006 16.1833 15.1786 16.7466 15.8175C17.3106 16.4574 18.2865 16.5195 18.927 15.9549C19.5659 15.3916 19.6275 14.4135 19.0643 13.7746ZM18.423 15.3832C18.1001 15.6679 17.6056 15.6395 17.3183 15.3136V15.3135C17.0329 14.9899 17.0641 14.4943 17.3879 14.2089C17.7114 13.9237 18.2059 13.9532 18.4926 14.2785C18.778 14.6022 18.7467 15.0978 18.423 15.3832Z"
        :fill="color"
      />
      <path
        d="M24.3626 12.7771C23.5108 12.7771 22.8179 13.4701 22.8179 14.3219C22.8179 15.1736 23.5109 15.8666 24.3626 15.8666C25.2144 15.8666 25.9074 15.1736 25.9074 14.3219C25.9074 13.4701 25.2144 12.7771 24.3626 12.7771ZM24.3626 15.1046C23.9311 15.1046 23.5799 14.7535 23.5799 14.3219C23.5799 13.8903 23.931 13.5392 24.3626 13.5392C24.7942 13.5392 25.1453 13.8903 25.1453 14.3219C25.1453 14.7535 24.7942 15.1046 24.3626 15.1046Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="26"
          height="26"
          fill="white"
          transform="translate(0.990479)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
