import transport from '@/plugins/axios'
import router from '@/router'
import parcelle from './parcelle'

const getDefaultState = () => {
  return {
    address: null,
    informations: null,
    is_data_loaded: false,
    is_data_loading: false,
    is_parcelle_principale_changed: false,
    adresse_historique: [],
    adresse_historique_by_bbox: [],
    Details_risques: [],
    risques_is_loading: false,
    refs_cadastrale_parcelles_voisines: [],
    long: '',
    lat: '',
    risques: null,
    remaining_counter: 0,
    counter_renewal_date: null,
    is_address_changing: false,
    error_show_address: null,
    isLoading: true,
    isAddressStoredAndRetrieved: null,
    show_poup_etre_rappele: false,
    id_time_out_popup_etre_rappele: null,
    poi: {
      data: null,
      is_loading: true,
    },
    risque: {
      data: null,
      is_loading: true,
    },
    infos_urbanisme: {
      data: null,
      is_loading: true,
    },
    infos_parcelles: {},
    infos_commune: {},
    zones_urbanisme: [],
    image_street_view: null,
    dvf: [],
    permis_construire: [],
    mairie: null,
    is_etude_commerciale_loading: true,
  }
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    address(state) {
      return state.address
    },
    informations(state) {
      return state.informations
    },
    is_data_loaded(state) {
      return state.is_data_loaded
    },
    is_data_loading(state) {
      return state.is_data_loading
    },
    is_parcelle_principale_changed(state) {
      return state.is_parcelle_principale_changed
    },
    poi(state) {
      return state.informations !== null ? state.informations.poi : null
    },
    commune_stats(state) {
      return state.informations !== null
        ? state.informations.cadastre.informations[0]
        : null
    },
    addressCadastre(state) {
      return state.informations !== null
        ? state.informations.cadastre.informations[0]
        : null
    },
    getAdresseHistorique(state) {
      return state.adresse_historique
    },
    getDetailsRisques: (state) => state.Details_risques,
    getRefsCadastraleParcellesVoisines: (state) =>
      state.refs_cadastrale_parcelles_voisines,
    risques_is_loading: (state) => state.risques_is_loading,
    long: (state) => state.long,
    lat: (state) => state.lat,
    risques: (state) => state.risques,
    getRemainingCounter: (state) => state.remaining_counter,
    getCounterRenewalDate: (state) => state.counter_renewal_date,
    error_show_address: (state) => state.error_show_address,
    adresse_historique_by_bbox: (state) => state.adresse_historique_by_bbox,
    isHistoriqueLoading: (state) => state.isLoading,
    isAddressStoredAndRetrieved: (state) => state.isAddressStoredAndRetrieved,
    show_popup_etre_rappele: (state) => state.show_poup_etre_rappele,
    id_time_out_popup_etre_rappele: (state) =>
      state.id_time_out_popup_etre_rappele,
    is_address_changing: (state) => state.is_address_changing,
    getPOI: (state) => state.poi,
    getRisque: (state) => state.risque,

    infos_parcelles(state) {
      return state.infos_parcelles
    },
    infos_commune(state) {
      return state.infos_commune
    },
    infos_urbanisme(state) {
      return state.infos_urbanisme
    },
    zones_urbanisme(state) {
      return state.zones_urbanisme
    },
    image_street_view(state) {
      return state.image_street_view
    },
    dvf(state) {
      return state.dvf
    },
    permis_construire(state) {
      return state.permis_construire
    },
    mairie(state) {
      return state.mairie
    },
    is_etude_commerciale_loading(state) {
      return state.is_etude_commerciale_loading
    },
  },
  mutations: {
    ResetStoreGUAddress(state) {
      Object.assign(state, getDefaultState())
    },
    SET_IS_ADDRESS_CHANGING(state, payload) {
      state.is_address_changing = payload
    },
    SET_POI(state, payload) {
      state.poi = Object.freeze(payload)
    },
    SET_RISQUE(state, payload) {
      state.risque = Object.freeze(payload)
    },
    SET_ADDRESS(state, payload) {
      state.address = payload
    },
    SET_SHOW_POPUP_ETRE_RAPPELE(state, payload) {
      state.show_poup_etre_rappele = payload
    },
    SET_ID_TIME_OUT_POPUP_ETRE_RAPPELE(state, payload) {
      state.id_time_out_popup_etre_rappele = payload
    },
    SET_INFORMATIONS(state, payload) {
      state.informations = payload
    },
    SET_IS_DATA_LOADED(state, payload) {
      state.is_data_loaded = payload
    },
    SET_IS_DATA_LOADING(state, payload) {
      state.is_data_loading = payload
    },
    IS_PARCELLE_PRINCIPALE_CHANGED(state, payload) {
      state.is_parcelle_principale_changed = payload
    },
    SET_ADDRESS_HISTORIQUE(state, payload) {
      state.adresse_historique = payload
    },
    SET_DETAILS_RISQUES(state, payload) {
      if (!Array.isArray(payload)) state.Details_risques.push(payload)
      else state.Details_risques = payload
    },
    SET_REFS_CADASTRALE_PARCELLES_VOISINES(state, payload) {
      state.refs_cadastrale_parcelles_voisines = payload ? payload : []
    },
    RISQUES_IS_LOADING(state, payload) {
      state.risques_is_loading = payload
    },
    SET_LONG_LAT(state, payload) {
      state.long = payload.long
      state.lat = payload.lat
    },
    RISQUES(state, payload) {
      state.risques = payload
    },
    SET_REMAINING_COUNTER(state, payload) {
      state.remaining_counter = payload
    },
    SET_COUNTER_RENEWAL_DATE(state, payload) {
      state.counter_renewal_date = payload
    },
    ERROR_SHOW_ADDRESS(state, payload) {
      state.error_show_address = payload
    },
    SET_ADRESSE_HISTORIQUE_BY_BBOX(state, payload) {
      state.adresse_historique_by_bbox = payload
    },
    SET_HISTORIQUE_LOADING(state, payload) {
      state.isLoading = payload
    },
    SET_ADDRESS_STORED_AND_RETRIEVED(state, payload) {
      state.isAddressStoredAndRetrieved = payload
    },
    INFOS_PARCELLES(state, payload) {
      state.infos_parcelles = payload
    },
    INFOS_COMMUNE(state, payload) {
      state.infos_commune = payload
    },
    INFOS_URBANISME(state, payload) {
      state.infos_urbanisme = payload
    },
    ZONE_URBANISME(state, payload) {
      state.zones_urbanisme = payload
    },
    SET_IMAGE_STREET_VIEW(state, payload) {
      state.image_street_view = payload
    },
    DVF(state, payload) {
      state.dvf = payload
    },
    PERMIS_CONSTRUIRE(state, payload) {
      state.permis_construire = payload
    },

    MAIRIE(state, payload) {
      state.mairie = payload
    },
    IS_ETUDE_COMMERCIALE_LOADING(state, payload) {
      state.is_etude_commerciale_loading = payload
    },
  },
  actions: {
    async storeAddress({ commit, dispatch, state }, information) {
      return new Promise(async (resolve, reject) => {
        commit('SET_ADDRESS_STORED_AND_RETRIEVED', true)
        let address = JSON.parse(JSON.stringify(information))
        address.geometry.coordinates = address.geometry.coordinates.map(
          (coord) => coord.toString(),
        )

        try {
          let response = await transport.post('/grand-publics/adresses', {
            information: address,
          })

          var resp = await dispatch('showAddress', response.data.adress.uuid)

          if (state.error_show_address) {
            commit('SET_ADDRESS_STORED_AND_RETRIEVED', false)
            return state.error_show_address
          }
          commit('IS_PARCELLE_PRINCIPALE_CHANGED', false)

          if (!information.hasOwnProperty('prevent_parcelle_redirection')) {
            router.push(`/sections-gu/parcelle/${resp.adresse.uuid}`)
          }
        } catch (error) {
          reject(error)
        } finally {
          commit('SET_ADDRESS_STORED_AND_RETRIEVED', false)
        }
        resolve(resp)
      })
    },

    async showAddressWithoutAuth(
      { commit, dispatch, rootGetters, state },
      information,
    ) {
      let ids_parcelles_selected = rootGetters[
        'parcelle/parcelles_selected'
      ].map((parcelle) => parcelle.properties.id)
      let address = JSON.parse(JSON.stringify(information))
      address.geometry.coordinates = address.geometry.coordinates.map((coord) =>
        coord.toString(),
      )
      commit('IS_ETUDE_COMMERCIALE_LOADING', true)
      commit('INFOS_URBANISME', {
        data: null,
        is_loading: true,
      })
      commit('SET_POI', {
        data: null,
        is_loading: true,
      })
      commit('SET_RISQUE', {
        data: null,
        is_loading: true,
      })
      commit('SET_IS_DATA_LOADED', false)
      commit('SET_IS_DATA_LOADING', true)
      commit('ERROR_SHOW_ADDRESS', null)

      try {
        commit('parcelle/IS_ALTIMETRIES_LOADING', true, { root: true })
        commit('SET_ADDRESS_STORED_AND_RETRIEVED', true)
        commit('RISQUES_IS_LOADING', true)

        return transport
          .post('/grand-publics/adresses/no-auth', {
            information: address,
            ids_parcelles_selected: ids_parcelles_selected.length
              ? ids_parcelles_selected
              : null,
          })
          .then((response) => {
            let informations = response.data

            commit(
              'parcelle/ALTIMETRIES',
              informations.parcelles.altimetrie.features,
              { root: true },
            )
            commit('parcelle/IS_ALTIMETRIES_LOADING', false, { root: true })
            if (!Array.isArray(informations.parcelles.cadastre_principal)) {
              commit('INFOS_PARCELLES', [
                informations.parcelles.cadastre_principal,
              ])
            } else {
              commit(
                'INFOS_PARCELLES',
                informations.parcelles.cadastre_principal,
              )
            }
            commit('INFOS_COMMUNE', informations.statistiques_commune)

            commit(
              'SET_REFS_CADASTRALE_PARCELLES_VOISINES',
              response.data.adresse.refs_cadastrale_parcelles_voisines,
            )
            commit('SET_ADDRESS', response.data.adresse)
            commit('SET_LONG_LAT', {
              long: response.data.adresse.long,
              lat: response.data.adresse.lat,
            })
            commit('SET_IMAGE_STREET_VIEW', response.data.image_street_view)
            commit('SET_IS_DATA_LOADED', true)
            commit('SET_IS_DATA_LOADING', false)
            commit('SET_ADDRESS_STORED_AND_RETRIEVED', false)
            if (state.error_show_address) {
              commit('SET_ADDRESS_STORED_AND_RETRIEVED', false)
              return state.error_show_address
            }
            commit('IS_PARCELLE_PRINCIPALE_CHANGED', false)
            if (!information.hasOwnProperty('prevent_parcelle_redirection')) {
              router.push(`/sections-gu/parcelle`)
            }

            transport
              .post('/grand-publics/adresses/no-auth', {
                without_cache: true,
                apis: [
                  'permis_construire_locaux',
                  'permis_construire_logements',
                  'DVF',
                ],
                information: address,
              })
              .then((response) => {
                commit(
                  'PERMIS_CONSTRUIRE',
                  response.data.information.permis_construire,
                )
                commit('DVF', response.data.information.DVF)
                commit('IS_ETUDE_COMMERCIALE_LOADING', false)
              })
            transport
              .get('/grand-publics/get-data-urbanisme-from-parcelles', {
                params: {
                  ids_parcelles: state.infos_parcelles.map(
                    (item) => item.parcelle.id,
                  ),
                },
              })
              .then((response) => {
                commit('INFOS_URBANISME', {
                  data: response.data,
                  is_loading: false,
                })
              })

            transport
              .post('/grand-publics/adresses/no-auth', {
                without_cache: true,
                apis: ['poi', 'mairie', 'risques'],
                information: address,
              })
              .then((resp) => {
                commit('MAIRIE', resp.data.information.mairie)
                commit('SET_POI', {
                  data: resp.data.information.poi,
                  is_loading: false,
                })
                commit('SET_RISQUE', {
                  data: resp.data.information.risque,
                  is_loading: false,
                })
              })
            return informations
          })
      } catch (error) {
        commit('SET_IS_DATA_LOADING', false)
        commit('ERROR_SHOW_ADDRESS', error.response.data.message)
        return error.response.data.message
      } finally {
        commit('SET_ADDRESS_STORED_AND_RETRIEVED', false)
      }
    },

    async showAddress({ commit, state }, id) {
      commit('IS_ETUDE_COMMERCIALE_LOADING', true)
      commit('INFOS_URBANISME', {
        data: null,
        is_loading: true,
      })
      commit('SET_POI', {
        data: null,
        is_loading: true,
      })
      commit('SET_RISQUE', {
        data: null,
        is_loading: true,
      })
      commit('SET_IS_DATA_LOADED', false)
      commit('SET_IS_DATA_LOADING', true)
      commit('ERROR_SHOW_ADDRESS', null)
      try {
        commit('parcelle/IS_ALTIMETRIES_LOADING', true, { root: true })
        commit('SET_ADDRESS_STORED_AND_RETRIEVED', true)
        commit('RISQUES_IS_LOADING', true)

        return transport
          .get('/grand-publics/get-data-from-address/' + id)
          .then((response) => {
            let informations = response.data

            commit(
              'parcelle/ALTIMETRIES',
              informations.parcelles.altimetrie.features,
              { root: true },
            )
            commit('parcelle/IS_ALTIMETRIES_LOADING', false, { root: true })
            if (!Array.isArray(informations.parcelles.cadastre_principal)) {
              commit('INFOS_PARCELLES', [
                informations.parcelles.cadastre_principal,
              ])
            } else {
              commit(
                'INFOS_PARCELLES',
                informations.parcelles.cadastre_principal,
              )
            }
            commit('INFOS_COMMUNE', informations.statistiques_commune)

            commit(
              'SET_REFS_CADASTRALE_PARCELLES_VOISINES',
              response.data.adresse.refs_cadastrale_parcelles_voisines,
            )
            commit('SET_ADDRESS', response.data.adresse)
            commit('SET_LONG_LAT', {
              long: response.data.adresse.long,
              lat: response.data.adresse.lat,
            })
            commit('SET_IMAGE_STREET_VIEW', response.data.image_street_view)
            commit('SET_IS_DATA_LOADED', true)
            commit('SET_IS_DATA_LOADING', false)
            commit('SET_ADDRESS_STORED_AND_RETRIEVED', false)

            transport
              .get('/grand-publics/adresses/' + id, {
                params: {
                  without_cache: true,
                  apis: [
                    'permis_construire_locaux',
                    'permis_construire_logements',
                    'DVF',
                  ],
                },
              })
              .then((response) => {
                commit(
                  'PERMIS_CONSTRUIRE',
                  response.data.information.permis_construire,
                )
                commit('DVF', response.data.information.DVF)
                commit('IS_ETUDE_COMMERCIALE_LOADING', false)
              })
            transport
              .get('/grand-publics/get-data-urbanisme-from-parcelles', {
                params: {
                  ids_parcelles: state.infos_parcelles.map(
                    (item) => item.parcelle.id,
                  ),
                },
              })
              .then((response) => {
                commit('INFOS_URBANISME', {
                  data: response.data,
                  is_loading: false,
                })
              })
            transport
              .get('/grand-publics/adresses/' + id, {
                params: {
                  apis: ['poi', 'mairie', 'risques'],
                },
              })
              .then((resp) => {
                commit('MAIRIE', resp.data.information.mairie)
                commit('SET_POI', {
                  data: resp.data.information.poi,
                  is_loading: false,
                })
                commit('SET_RISQUE', {
                  data: resp.data.information.risque,
                  is_loading: false,
                })
              })
            return informations
          })
      } catch (error) {
        commit('SET_IS_DATA_LOADING', false)
        commit('ERROR_SHOW_ADDRESS', error.response.data.message)
        return error.response.data.message
      }
    },

    getReverseAddress({ dispatch, rootGetters, commit }, coords) {
      transport
        .get('/grand-publics/gouv-url/reverse', {
          params: {
            lat: coords.latitude,
            lon: coords.longitude,
          },
        })
        .then((response) => {
          let isLoggedIn = rootGetters['auth/isLoggedIn']
          if (response.data.features.length) {
            if (isLoggedIn) {
              dispatch('storeAddress', response.data.features[0])
            } else {
              commit(
                'auth/SET_GUEST_INTENDED_ADDRESS',
                response.data.features[0],
                { root: true },
              )
              dispatch(
                'showAddressWithoutAuth',
                null,
                response.data.features[0],
              )
            }
          }
        })
    },

    async getHistorique({ commit, state }) {
      commit('SET_HISTORIQUE_LOADING', true)
      let response = await transport.get('/grand-publics/adresses')
      commit('SET_ADDRESS_HISTORIQUE', response.data.data)
      commit('SET_REMAINING_COUNTER', response.data.meta.rest_counter)
      commit('SET_COUNTER_RENEWAL_DATE', response.data.meta.reset_date_counter)
      commit('SET_HISTORIQUE_LOADING', !(response.data.data.length < 1))

      return response.data
    },

    async removeAddress({ commit, state }, uuid) {
      let response = await transport.delete(`/grand-publics/adresses/${uuid}`)
      commit(
        'SET_ADDRESS_HISTORIQUE',
        state.adresse_historique.filter((adresse) => adresse.uuid !== uuid),
      )
      return response.status
    },
  },
}
