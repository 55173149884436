<template>
  <div
    class="bg-white flex items-center justify-between px-12 lg:px-36 md:px-8 sm:px-5 py-5 rounded-lg md:rounded-none md:border-b-2 text-promy-green-350 font-bold font-main"
  >
    <div class="flex items-center space-x-2">
      <TransportIcon color="#10AAAF" class="w-5 h-5 mr-1" />
      <span class="text-promy-green-350">{{ points_interet['transport'] }}</span>
    </div>
    <div class="flex items-center space-x-2">
      <EducationIcon color="#10AAAF" class="w-5 h-5 mr-1" />
      <span class="text-promy-green-350">{{ points_interet['education'] }}</span>
    </div>
    <div class="flex items-center space-x-2">
      <CommerceIcon color="#10AAAF" class="w-5 h-5 mr-1" />
      <span class="text-promy-green-350">{{ points_interet['commerce'] }}</span>
    </div>
    <div class="flex items-center space-x-2">
      <TourismIcon color="#10AAAF" class="w-5 h-5 mr-1" />
      <span class="text-promy-green-350">{{ points_interet['tourism'] }}</span>
    </div>
    <div class="flex items-center space-x-2">
      <HealthIcon color="#10AAAF" class="w-5 h-5 mr-1" />
      <span class="text-promy-green-350">{{ points_interet['sante'] }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    points_interet: {
      type: Object,
      default() {
        return {
          transport: 0,
          education: 0,
          commerce: 0,
          tourism: 0,
          sante: 0,
        }
      },
    },
  },
}
</script>

<style></style>
