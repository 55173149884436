<template>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.02778 0.958333C7.02778 0.704168 6.92681 0.460412 6.74709 0.280689C6.56737 0.100967 6.32361 0 6.06944 0C5.81528 0 5.57152 0.100967 5.3918 0.280689C5.21208 0.460412 5.11111 0.704168 5.11111 0.958333V4.15278C5.11111 4.40694 5.01014 4.6507 4.83042 4.83042C4.6507 5.01014 4.40694 5.11111 4.15278 5.11111H0.958333C0.704168 5.11111 0.460412 5.21208 0.280689 5.3918C0.100967 5.57152 0 5.81528 0 6.06944C0 6.32361 0.100967 6.56737 0.280689 6.74709C0.460412 6.92681 0.704168 7.02778 0.958333 7.02778H4.15278C4.91528 7.02778 5.64654 6.72488 6.18571 6.18571C6.72488 5.64654 7.02778 4.91528 7.02778 4.15278V0.958333Z"
      :fill="color"
    />
    <path
      d="M7.02778 22.0417C7.02778 22.2958 6.92681 22.5396 6.74709 22.7193C6.56737 22.899 6.32361 23 6.06944 23C5.81528 23 5.57152 22.899 5.3918 22.7193C5.21208 22.5396 5.11111 22.2958 5.11111 22.0417V18.8472C5.11111 18.5931 5.01014 18.3493 4.83042 18.1696C4.6507 17.9899 4.40694 17.8889 4.15278 17.8889H0.958333C0.704168 17.8889 0.460412 17.7879 0.280689 17.6082C0.100967 17.4285 0 17.1847 0 16.9306C0 16.6764 0.100967 16.4326 0.280689 16.2529C0.460412 16.0732 0.704168 15.9722 0.958333 15.9722H4.15278C4.91528 15.9722 5.64654 16.2751 6.18571 16.8143C6.72488 17.3535 7.02778 18.0847 7.02778 18.8472V22.0417Z"
      :fill="color"
    />
    <path
      d="M16.9305 0C16.6763 0 16.4326 0.100967 16.2529 0.280689C16.0731 0.460412 15.9722 0.704168 15.9722 0.958333V4.15278C15.9722 4.91528 16.2751 5.64654 16.8142 6.18571C17.3534 6.72488 18.0847 7.02778 18.8472 7.02778H22.0416C22.2958 7.02778 22.5395 6.92681 22.7193 6.74709C22.899 6.56737 22.9999 6.32361 22.9999 6.06944C22.9999 5.81528 22.899 5.57152 22.7193 5.3918C22.5395 5.21208 22.2958 5.11111 22.0416 5.11111H18.8472C18.593 5.11111 18.3492 5.01014 18.1695 4.83042C17.9898 4.6507 17.8888 4.40694 17.8888 4.15278V0.958333C17.8888 0.704168 17.7879 0.460412 17.6081 0.280689C17.4284 0.100967 17.1847 0 16.9305 0Z"
      :fill="color"
    />
    <path
      d="M15.9722 22.0417C15.9722 22.2958 16.0731 22.5396 16.2529 22.7193C16.4326 22.899 16.6763 23 16.9305 23C17.1847 23 17.4284 22.899 17.6081 22.7193C17.7879 22.5396 17.8888 22.2958 17.8888 22.0417V18.8472C17.8888 18.5931 17.9898 18.3493 18.1695 18.1696C18.3492 17.9899 18.593 17.8889 18.8472 17.8889H22.0416C22.2958 17.8889 22.5395 17.7879 22.7193 17.6082C22.899 17.4285 22.9999 17.1847 22.9999 16.9306C22.9999 16.6764 22.899 16.4326 22.7193 16.2529C22.5395 16.0732 22.2958 15.9722 22.0416 15.9722H18.8472C18.0847 15.9722 17.3534 16.2751 16.8142 16.8143C16.2751 17.3535 15.9722 18.0847 15.9722 18.8472V22.0417Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
