<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H2V18H0V0ZM14 0H3V18H14C15.1 18 16 17.1 16 16V2C16 0.9 15.1 0 14 0ZM12 6H6V5H12V6ZM12 4H6V3H12V4Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
