function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}

const LoginLayout = () =>
  import(/* webpackChunkName: '' */ '../../layouts/LoginLayout')

import guest from '../../middleware/guest'
import auth from '../../middleware/auth'
import isLocal from '../../utilities/isLocal'

export default [
  {
    path: '/auth',
    name: 'Auth',
    component: LoginLayout,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: page('Auth/Login'),
        meta: {
          middleware: [guest],
        },
      },
      {
        path: '/signup',
        name: 'Signup',
        component: page('Auth/Signup'),
        meta: {
          middleware: [guest],
        },
      },
      {
        path: '/password-reset',
        name: 'passwordReset',
        component: page('Auth/SendResetEmail'),
      },
      {
        path: '/verify-email',
        name: 'verify-email',
        meta: {
          middleware: [auth],
        },
        component: page('Auth/VerifyEmail'),
      },
      {
        path: '/forgetpassword',
        name: 'forgetPassword',
        component: page('Auth/ResetPassword'),
        meta: {
          middleware: [guest],
        },
      },
      {
        path: '/success-reset',
        name: 'SuccessReset',
        component: page('Auth/SuccessReset'),
      },
    ],
  },
  {
    path: '/search',
    name: 'search',
    component: page('Search'),
  },
  {
    path: '/empty-state',
    name: 'empty-state',
    component: page('Empty'),
  },
  ...(isLocal
    ? [
        {
          path: '/promy-design',
          name: 'promy-design',
          component: page('Uikit'),
        },
      ]
    : []),
]
