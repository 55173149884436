<template>
  <button
    @click="goToStreetView"
    class="bg-white flex items-center justify-start px-6 sm:px-4 py-5 rounded-lg lg:rounded-none text-promy-primary font-bold font-main sm:text-sm"
  >
    <MarkerIcon name="marker" class="w-7 h-7 mr-4" color="#10AAAF" />
    <div>
      <p>{{ label | truncate(35, '...') }}</p>
    </div>
    <div class="relative ml-6">
      <img
        v-if="streetViewImage"
        :src="streetViewImage"
        class="border-2 border-promy-green-350 rounded lg:hidden w-14 h-12"
      />
      <svg
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        width="23"
        height="23"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_4395_16634)">
          <path
            d="M11.8596 5.625C13.4105 5.625 14.6721 4.3634 14.6721 2.8125C14.6721 1.2616 13.4105 0 11.8596 0C10.3089 0 9.04712 1.2616 9.04712 2.8125C9.04712 4.3634 10.3089 5.625 11.8596 5.625V5.625Z"
            fill="#FFDA1A"
          />
          <path
            d="M18.8108 19.0109C18.2833 18.8927 17.7068 18.79 17.0925 18.7034C16.7124 18.6499 16.3581 18.9257 16.3079 19.3038C16.2578 19.681 16.5156 20.0428 16.8972 20.0965C17.3216 20.1562 17.7261 20.2239 18.1057 20.2994C19.7435 20.6246 20.4423 20.9977 20.6622 21.1874C20.4423 21.3771 19.7435 21.7503 18.1057 22.0757C16.4231 22.4097 14.2549 22.5937 12.0002 22.5937C9.74542 22.5937 7.57709 22.4097 5.89453 22.0755C4.25665 21.7503 3.55811 21.3771 3.3382 21.1874C3.55811 20.9977 4.25665 20.6246 5.89453 20.2994C6.19189 20.2402 6.50446 20.186 6.82983 20.1364C7.20538 20.0795 7.461 19.724 7.41357 19.3511C7.36487 18.9678 7.00873 18.6866 6.62292 18.7452C6.11462 18.8224 5.6347 18.9112 5.18958 19.0109C2.95917 19.5102 1.875 20.2221 1.875 21.1874C1.875 22.1528 2.95917 22.8647 5.18958 23.364C7.02136 23.7742 9.44019 23.9999 12.0002 23.9999C14.5602 23.9999 16.9788 23.7742 18.8108 23.364C21.0412 22.8647 22.1254 22.1528 22.1254 21.1874C22.1254 20.2221 21.0412 19.5102 18.8108 19.0109V19.0109Z"
            fill="#FFDA1A"
          />
          <path
            d="M8.78882 14.3115V19.2188C8.78882 20.3818 9.73511 21.3281 10.8982 21.3281H12.8212C13.9843 21.3281 14.9305 20.3818 14.9305 19.2188V14.3115C15.6333 13.9634 16.1002 13.2424 16.1002 12.4219V9.14062C16.1002 7.97754 15.1539 7.03125 13.9908 7.03125H9.72852C8.56543 7.03125 7.61914 7.97754 7.61914 9.14062V12.4219C7.61914 13.2424 8.08624 13.9634 8.78882 14.3115Z"
            fill="#FFDA1A"
          />
        </g>
        <defs>
          <clipPath id="clip0_4395_16634">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </button>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    uuid: {
      label: {
        type: String,
        require: true,
      },
    },
    label: {
      type: String,
      require: true,
    },
    dept_region: {
      type: String,
      default: '',
    },
    canSeeIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      streetViewImage: null,
    }
  },
  computed: {
    ...mapGetters({
      image_street_view: 'address/image_street_view',
    }),
  },
  methods: {
    goToStreetView() {
      this.$router.push(`/sections-gu/streetView/${this.uuid}`)
    },
  },
  mounted() {
    this.streetViewImage = this.image_street_view
      ? `data:image/jpg;base64,${this.image_street_view}`
      : '/images/image.png'
  },
}
</script>
