import axios from 'axios'
import store from '@/store'
import router from '@/router'
import Vue from 'vue'

const transport = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: true,
})
Vue.prototype.$http = transport
// Request interceptor
transport.interceptors.request.use((request) => {
  request.headers.common['Accept'] = 'application/json'
  request.headers.common['Content-Type'] = 'application/json'

  return request
})
// Response interceptor
transport.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response
    if (status === 401) {
      store.commit('auth/SET_LOGOUT')
      router.push('/auth').catch(() => {})
    }
    return Promise.reject(error)
  },
)
export default transport
