<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden shadow-box w-full px-8 sm:px-3 py-5 bg-white"
  >
    <div
      class="flex justify-between items-start text-promy-green-350 font-main font-bold sm:text-sm"
    >
      <p>{{ title }}</p>
      <div
        class="text-2xl sm:text-xl text-promy-primary text-right leading-none"
      >
        <p v-if="!without_format_euro">
          {{ totalDataset | formatEUR }} 
        </p>
        <p v-if="without_format_euro">{{ totalDataset | formatNumber}}</p>
        <span class="font-normal text-base sm:text-sm">{{ libelle_total }}</span>
      </div>
    </div>
    <div
      class="flex md:flex-col justify-between items-center sm-ws:py-0 sm-ws:pl-0 sm-ws:m-auto py-8 pl-6 md:px-2 sm:px-0 full-hd:m-auto autoMarginInMidScreen"
      v-if="dataset.length"
    >
      <div class="w-1/2 md:w-[99%] md:mb-6 sm:mb-0 sm-ws:relative">
        <canvas :id="chartId" :class="classes_canvas" />
      </div>
      <div
        class="w-1/2 md:w-full flex flex-wrap font-main text-promy-gray-200 ml-12 md:mx-2 sm:mx-0 md:mt-6 sm:text-sm"
      >
        <p
          class="flex items-center w-full md:w-1/2 sm:w-full space-y-2"
          v-for="(item, idx) in labels"
          :key="idx"
        >
          <span
            class="w-3 h-3 rounded-full mx-3"
            :style="`background: ${colors[idx]}`"
          ></span>
          <span class=" flex justify-between w-full">
            <span class=" flex flex-1"> {{ item }}</span>  <strong>{{ dataset[idx] | formatNumber }}</strong>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    libelle_total: {
        type: String,
        default: 'Total',
    },
    chartId: {
      type: String,
    },
    title: {
      type: String,
    },
    labels: {
      type: Array,
    },
    dataset: {
      type: Array,
    },
    classes_canvas: {
      type: String,
      default: '',
    },
    without_format_euro: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      colors: [
        '#1D63EB',
        '#1DEBEB',
        '#1DEBAD',
        '#CE1DEB',
        '#EB421D',
        '#3AEB1D',
        '#EB1DBE',
        '#9DEB1D',
      ],
      totalDataset: 0,
      dataset_value: [],
      dataset_labels: [],
      dataset_value_rest: [],
    }
  },

  mounted() {
    if (this.dataset.length) {
      this.calculePercentage()
      this.renderPie()
    }
  },
  methods: {
    calculePercentage() {
      this.totalDataset = this.dataset.reduce((a, b) => a + b, 0)
      this.dataset.map((el, idx) => {
        let montant = this.montant_brut(el, this.totalDataset)
        this.dataset_value[idx] = montant
        this.dataset_labels[idx] = montant + '%'
        this.dataset_value_rest[idx] =
          100 - this.montant_brut(el, this.totalDataset)
      })
    },
    montant_brut(y1, y2) {
      var resultat = (y1 / y2) * 100
      return Math.round(resultat)
    },
    renderPie() {
      var doghnut1 = document.getElementById(this.chartId)

      new this.$chart(doghnut1, {
        type: 'bar',
        data: {
          labels: this.dataset_labels,
          datasets: [
            {
              label: this.title,
              backgroundColor: this.colors,
              stack: 'Stack 0',
              data: this.dataset_value,
              barThickness: 6,
            },
            {
              label: this.title,
              backgroundColor: '#F6F6F6',
              stack: 'Stack 0',
              data: this.dataset_value_rest,
              barThickness: 6,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
                borderColor: 'white',
              },
              ticks: {
                font: {
                  family: 'Poppins',
                  size: this.$mq === 'sm' ? 12 : 16,
                  weight: 700,
                },
              },
            },
            y: {
              grid: {
                display: false,
                borderColor: 'white',
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      })
    },
  },
}
</script>

<style>
@media (min-width: 1922px) {
  .responsiveWidth {
    width: 50vw;
  }
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .responsiveWidth {
    width: 40vw;
  }
}
@media (min-width: 1680px) and (max-width: 1921px) {
  .responsiveWidth {
    width: 40vw;
  }
  .autoMarginInMidScreen {
    margin: auto;
  }
}
</style>
