import sourcesAndLayers from '@/mixins/map/sourcesAndLayers'

export default {
  mixins: [sourcesAndLayers],
  data() {
    return {
      palettes_color: [
        '#356390',
        '#00b7ab',
        '#FFB624',
        '#414141',
        '#321E6A',
        '#E53636',
        '#B09AE9',
        '#0BE096',
        '#ACACAC',
        '#222222',
      ],
      dark_green: '#90c05b',
      red: '#e7605e',
      yellow: '#f5f45e',
      green: '#00C9BC',
      green_light: '#0BE096',
      orange: '#FFB624',
      grey: '#A0A0A0',
      blue: '#356390',
      purple: '#321E6A',
      green_parcelle_selected: '#50F0CA',
      white_parcelle_not_selected: '#E5E7EB',
      options_polygone: {
        color: '#321E6A',
        is_dash: false,
        is_multi_geojson: false,
        opacity: 0.2,
        opacity_parcelle_selected: 0.6,
        is_3d: false,
        opacity_3d: 0.87,
        color_3d: '#356390',
      },
      stop_set_time_out: false,
      id_set_timeout: null,
    }
  },
  methods: {
    animateLayers(
      animate,
      layers,
      id_set_interval_opacity_layer,
      layer_to_remove = this.source_and_layers_parcelles_voisines,
    ) {
      this.removeSourceAndLayers(...layer_to_remove)
      if (animate) {
        let opacity = this.options_polygone.opacity
        return setInterval(() => {
          opacity = opacity === this.options_polygone.opacity ? 0 : this.options_polygone.opacity_parcelle_selected
          layers.forEach((layer) => {
            if (this.isExist(this.map.getLayer(layer))) {
              this.map.setPaintProperty(layer, 'fill-opacity', opacity)
            }
          })
        }, 500)
      } else {
        clearInterval(id_set_interval_opacity_layer)
        layers.forEach((layer) => {
          if (this.isExist(this.map.getLayer(layer))) {
            this.map.setPaintProperty(layer, 'fill-opacity', this.options_polygone.opacity_parcelle_selected)
          }
        })

        return null
      }
    },
    isExist(valeur) {
      return typeof valeur !== 'undefined' && valeur !== null
    },
    getCoordinates(coordinates) {
      var new_coordinates = []
      if (coordinates) {
        coordinates.forEach(function iter(element) {
          if (Array.isArray(element[0][0])) {
            element.forEach(iter)
          } else {
            new_coordinates.push(element)
            return
          }
        })
      }

      return new_coordinates
    },
    copyObject(obj) {
      return Object.assign({}, obj)
    },
    getNestedObject(obj, ...args) {
      return args.reduce((obj, level) => obj && obj[level], obj)
    },
    createMap(coordinates = null, zoom = 18, id = 'mapbox', minZoom = 0) {
      let active_layer = this.$store.getters['map/active_layer']
      let map = {}
      map = new this.$mapboxgl.Map({
        container: id,
        style: `mapbox://styles/mapbox/${active_layer}?optimize=true`,
        center: coordinates ? coordinates : [4.837523, 45.761885],
        zoom: zoom,
        minZoom: minZoom,
      })
      map.addControl(
        new this.$mapboxgl.NavigationControl({
          showZoom: false,
          showCompass: false,
          visualizePitch: false,
        }),
        'bottom-right',
      )
      return map
    },
    fitBoundsGeojsonInMap(geojson, padding = 40, maxZoom = 18) {
      var bbox = this.$turf.bbox(geojson)
      this.map.fitBounds(bbox, {
        padding: padding,
        maxZoom: maxZoom,
      })
    },
    addGeojsonToMap(name_source, geojson) {
      this.map.addSource(name_source, {
        type: 'geojson',

        data: geojson,
      })
    },
    addMultiGeojsonToMap(name_source, geojson) {
      this.map.addSource(name_source, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: geojson,
        },
        generateId: true,
      })
    },
    addSourceLayersMultiGeojsonToMap(
      multi_geojson,
      color,
      source_layers,
      is_3d = false,
      opacity_3d = 0.87,
    ) {
      let options = this.copyObject(this.options_polygone)
      options.color = color
      options.is_multi_geojson = true
      options.is_3d = is_3d
      options.opacity_3d = opacity_3d
      options.color_3d = color
      this.addPolygonsToMap(multi_geojson, source_layers, options)
    },
    addBackgroundLayerToGeojson(id_layer, source_layer, color, opacity = 0.3) {
      this.map.addLayer({
        id: id_layer,
        type: 'fill',
        source: source_layer,
        paint: {
          'fill-color': color,
          'fill-opacity': opacity,
          'fill-opacity-transition': {
            duration: 300,
          },
        },
      })
    },
    addOutlineLayerToGeojson(id_layer, source_layer, color, is_dash = false) {
      let layout = {}
      let line_dasharray = [1]
      if (is_dash) {
        layout = {
          'line-join': 'round',
          'line-cap': 'round',
        }
        line_dasharray = [0.1, 2]
      }
      this.map.addLayer({
        id: id_layer,
        type: 'line',
        source: source_layer,
        layout: layout,
        paint: {
          'line-color': color,
          'line-width': 3,
          'line-dasharray': line_dasharray,
          'line-opacity': 1,
        },
      })
    },
    removeSourceAndLayers(
      id_source,
      id_background_layer,
      id_outline_layer = null,
    ) {
      if (this.isExist(this.map.getLayer(id_background_layer)))
        this.map.removeLayer(id_background_layer)
      if (this.isExist(this.map.getLayer(id_outline_layer))) {
        this.map.removeLayer(id_outline_layer)
      }
      if (this.map.getSource(id_source)) {
        this.map.removeSource(id_source)
      }
    },
    layerExist(id_layer) {
      return typeof this.map.getLayer(id_layer) !== 'undefined'
    },
    concatSourceAndLayersWithRef(
      id_source,
      id_background_layer,
      id_outline_layer,
      ref,
    ) {
      return [
        id_source + '-' + ref,
        id_background_layer + '-' + ref,
        id_outline_layer + '-' + ref,
      ]
    },
    addPolygonsToMap(
      polygons,
      id_source_and_layers,
      options = this.options_polygone,
    ) {
      let source_and_layers = {
        id_source: id_source_and_layers[0],
        id_background_layer: id_source_and_layers[1],
        id_outline_layer: id_source_and_layers[2],
      }
      if (options.is_multi_geojson)
        this.addMultiGeojsonToMap(source_and_layers.id_source, polygons)
      else this.addGeojsonToMap(source_and_layers.id_source, polygons)
      if (!options.is_3d) {
        let color_boder = options.color;
        switch (options.color) {
          case this.green_parcelle_selected:
            color_boder = '#57FFB8';
            options.opacity = options.opacity_parcelle_selected
            break;
          case this.white_parcelle_not_selected:
            color_boder = '#FFFFFF';
            break;
        }
        this.addBackgroundLayerToGeojson(
          source_and_layers.id_background_layer,
          source_and_layers.id_source,
          options.color,
          options.opacity,
        )

        this.addOutlineLayerToGeojson(
          source_and_layers.id_outline_layer,
          source_and_layers.id_source,
          color_boder,
          options.is_dash,
        )
      } else this.addLayer3D(id_source_and_layers, options)
    },
    addLayer3D(
      source_and_layer,
      options,
      property_height = 'hauteur',
      property_min_height = 0,
    ) {
      this.map.addLayer({
        id: source_and_layer[1],
        type: 'fill-extrusion',

        source: source_and_layer[0],
        paint: {
          'fill-extrusion-color': options.color_3d,
          'fill-extrusion-height': ['get', property_height],

          // Get fill-extrusion-base from the source 'base_height' property.
          'fill-extrusion-base': property_min_height,

          // Make extrusions slightly opaque for see through indoor walls.
          'fill-extrusion-opacity': options.opacity_3d,
        },
      })
    },
    addSourceLayerToSelectedPolygone(
      parcelle,
      source_and_layers_parcelles_voisines,
    ) {
      let options = this.copyObject(this.options_polygone)
      options.color = this.green
      this.addPolygonsToMap(
        parcelle,
        source_and_layers_parcelles_voisines,
        options,
      )
    },

    switchLayer(style, layers, is_street) {
      this.is_style_changing = true
      const savedLayers = []
      const savedSources = {}
      const waiting = () => {
        if (!this.map.loaded()) {
          this.id_set_timeout = setTimeout(waiting, 200)
        } else if (!this.stop_set_time_out) {
          layers.forEach((layerGroup) => {
            this.forEachLayer(layerGroup, (layer) => {
              savedSources[layer.source] = this.map
                .getSource(layer.source)
                .serialize()
              savedLayers.push(layer)
            })
          })
          this.$store.commit('initMap/SAVED_SOURCES', savedSources)
          this.$store.commit('initMap/SAVED_LAYERS', savedLayers)
          this.$store.commit('initMap/IS_STREET', is_street)
          this.map.setStyle(`mapbox://styles/mapbox/${style}?optimize=true`)
          this.is_style_changing = false
        }
      }
      waiting()
    },
    forEachLayer(text, cb) {
      this.map.getStyle().layers.forEach((layer) => {
        if (layer.id.includes(text)) cb(layer)
      })
    },
    preserveLayers() {
      Object.entries(this.saved_sources).forEach(([id, source]) => {
        if (!this.isExist(this.map.getSource(id)))
          this.map.addSource(id, source)
      })
      this.saved_layers.forEach((layer) => {
        if (!this.isExist(this.map.getLayer(layer.id))) this.map.addLayer(layer)
      })
    },

    setLayoutVisibility(layers, visibility) {
      this.map.setLayoutProperty(layers[1], 'visibility', visibility)
      this.map.setLayoutProperty(layers[2], 'visibility', visibility)
    },

    removeAdditionalLayers() {
      let all_layers = this.map.getStyle().layers.map((layer) => {
        return {
          id: layer.id,
          source: layer.source,
        }
      })
      let additional_layer = all_layers.filter((layer) => {
        return layer.id.split('-')[0] === 'promy'
      })
      additional_layer.forEach((layer) => {
        if (this.map.getLayer(layer.id)) {
          this.map.removeLayer(layer.id)
        }
      })
      additional_layer.forEach((layer) => {
        if (this.map.getSource(layer.source)) {
          this.map.removeSource(layer.source)
        }
      })
    },

    loadMap(coords = null) {
      if (!this.map) {
        this.$store.commit('map/MAP', this.createMap(coords))
        return false
      } else {
        this.removeAdditionalLayers()
        document.querySelector('#mapbox').replaceWith(this.map.getContainer())
        this.map.resize()
        this.map.setPitch(0)
        return true
      }
    },

    addSkyLayer() {
      this.map.addLayer({
        id: this.sky_layers,
        type: 'sky',
        paint: {
          // set up the sky layer to use a color gradient
          'sky-type': 'gradient',
          // the sky will be lightest in the center and get darker moving radially outward
          // this simulates the look of the sun just below the horizon
          'sky-gradient': [
            'interpolate',
            ['linear'],
            ['sky-radial-progress'],
            0.8,
            'rgba(135, 206, 235, 1.0)',
            1,
            'rgba(0,0,0,0.1)',
          ],
          'sky-gradient-center': [0, 0],
          'sky-gradient-radius': 90,
          'sky-opacity': [
            'interpolate',
            ['exponential', 0.1],
            ['zoom'],
            5,
            0,
            22,
            1,
          ],
        },
      })
    },
    addCustomMarkerWithPopupInMap(template, latLong, custom_marker, popup) {
      popup.setHTML(template)
      let marker = new this.$mapboxgl.Marker(custom_marker, {
        offset: [0, -15],
      })
        .setLngLat(latLong)
        .setPopup(popup)
        .addTo(this.map)
      return {
        marker: marker,
        popup: popup,
      }
    },
    templatePopup(
      title,
      descriptions,
      with_link = false,
      is_list_order = false,
      label_link = 'Documents urbanisme',
      id_link = 'popup-marker',
    ) {
      let template = `
        <div class='flex flex-col w-62 break-words bg-white sm:w-50 sm-ws:w-55 rounded-lg'> 
          <div 
            class='pl-4 pr-5 flex w-full bg-promy-white rounded-t-lg border-b border-promy-gray-150 h-12'
          >
            <span 
              class='text-base text-promy-green-350 font-bold font-main flex items-center text-base'
            >
              ${this.truncateText(title.join(' , '), 23)}
            </span> 
          </div> 
          <div class='flex flex-col flex-1 bg-promy-gray-50 rounded-b-lg'> 
            <div class='flex w-full flex-1 items-center font-bold py-4'> 
              <span 
                class='overflow-y-auto pl-4 pr-8 w-full mr-2 text-xs'
                style='max-height:82px;'
              > 
      `

      descriptions.forEach((description, index) => {
        if (index > 0 && index < descriptions.length) {
          template += `<hr class='h-1/2 bg-promy-gray-300 border-0 my-1'>`
        }
        if (descriptions.length > 1 && is_list_order) {
          template += `<span class='text-promy-purple-400'>${index + 1})</span>`
        }
        template += `- ${description}`
      })

      template += `</span></div>`

      if (with_link) {
        template += `
          <div class='flex w-full text-xs p-3'> 
            <button 
              class=' 
              bg-promy-green-350 hover:bg-promy-green-300 
              border-promy-green-350 
              text-white 
                leading-relaxed
                font-medium
                flex
                items-center
                justify-center
                cursor-pointer
                border
                hover:shadow-lg
                focus:outline-none
                rounded-lg
                gap-x-2
                py-2
                px-3
              ' 
              id='${id_link}'
            > 
              ${label_link} 
            </button>  
          </div>`
      }

      template += `</div></div>`

      return template
    },
    customPopupButtonClose(event) {
      let btn_close = event.target._container.querySelector(
        '.mapboxgl-popup-close-button',
      )
      btn_close.innerHTML = ''
      btn_close.style.backgroundImage = "url('/images/map/close.svg')"
      btn_close.classList.add('btn-close')
    },

    truncateText(text, length, suffix = '...') {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    },
    removeMarkers(markers) {
      if (Array.isArray(markers)) {
        markers.forEach((marker) => marker.remove())
      } else if (markers) {
        markers.remove()
      }
    },
    addSourceAndLayerForCluster(data, source_and_layers_cluster) {
      this.map.addSource(source_and_layers_cluster[0], {
        type: 'geojson',
        data: data,
        generateId: true,
        cluster: true,
        clusterMaxZoom: 15,
        clusterRadius: 50,
      })
      this.map.addLayer({
        id: source_and_layers_cluster[1],
        type: 'circle',
        source: source_and_layers_cluster[0],
        filter: ['==', source_and_layers_cluster[0], true],
        paint: {
          'circle-opacity': 0,
        },
      })
    },
  },
}
