const getDefaultState = () => {
  return {
    stepNumber: 1,
    adresse: '',
    residence: '',
    Superficie_terrain: '',
    surface_construite: '',
    nombre_pieces: '',
    etat_bien: '',
    type_user: '',
    vendre_souhaite: '',
    deja_estimer: true,
    description: '',
    sexe: 'male',
    prenom: '',
    nom: '',
    email: '',
    telephone_mobile: '',
  }
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    GET_ADRESSE(state) {
      return state.adresse
    },
    GET_STEP_NUMBER(state) {
      return state.stepNumber
    },
  },
  mutations: {
    SET_ADRESSE(state, payload) {
      state.adresse = payload
    },
    SET_STEP_NUMBER(state, payload) {
      state.stepNumber = payload
    },
    SET_RESET_TUNNEL_STORE(state) {
      Object.assign(state, getDefaultState())
    },
  },
}
