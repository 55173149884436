<template>
  <div
    class="bg-white text-promy-primary font-main font-bold"
    :class="{
      'border-promy-green-350': active_accordion != 1,
      'border-white': active_accordion == 1,
      'rounded-lg border': with_rounded_border,
    }"
  >
    <accordion ref="containerAccordion">
      <accordion-item
        :active_first_accordion_item="active_first_accordion_item"
      >
        <template slot="accordion-trigger">
          <a
            class="
              flex
              items-center
              justify-between
              w-full
              px-8
              py-4
              cursor-pointer
              sm:px-3
            "
            @click="activingAccordion(1)"
          >
            <div class="flex items-center">
              <div
                class="
                  w-12
                  h-12
                  sm:w-10 sm:h-10
                  rounded-full
                  bg-promy-blue-50
                  flex
                  justify-center
                  items-center
                "
              >
                <ParcelleGUIcon class="sm:w-9 sm:h-9" />
              </div>
              <div class="flex flex-col">
                <span
                  class="
                    text-xl
                    sm:text-lg
                    ml-3
                    text-promy-green-350
                    hidden
                    lg:flex
                  "
                >
                  {{ adresse | truncate(24, '...') }}
                </span>
                <span class="text-xl sm:text-lg ml-3">
                  {{ title }}
                </span>
              </div>
            </div>
            <slot name="right-side"></slot>
            <AccordionIcon :is_active="active_accordion == 1"></AccordionIcon>
          </a>
        </template>
        <template slot="accordion-content">
          <div
            class="
              border-promy-purple-400 border-t-2
              bg-white
              px-8
              rounded-b-lg
              overflow-y-auto
              max-h-[19rem]
            "
          >
            <slot name="body"></slot>
          </div>
        </template>
      </accordion-item>
    </accordion>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
    adresse: {
      type: String,
    },
    with_rounded_border: {
      type: Boolean,
      default: false,
    },
    active_first_accordion_item: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active_accordion: -1,
      parcelle: '',
    }
  },
  mounted() {
    if (window.innerWidth > 768) this.active_accordion = 1
  },
  methods: {
    activingAccordion(idx) {
      this.active_accordion = this.active_accordion === idx ? -1 : idx
    },
    activeAccordion() {
      this.$refs.containerAccordion.Accordion.active = 1
    },
  },
}
</script>

