<template>
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9841 1C8.33067 1 4.58301 3.11453 1.6327 7.34359C1.54849 7.46564 1.50234 7.6099 1.50009 7.75816C1.49783 7.90642 1.53958 8.05202 1.62004 8.17656C3.88692 11.725 7.58442 14.5 11.9841 14.5C16.336 14.5 20.1094 11.7166 22.3805 8.16016C22.4591 8.03805 22.5009 7.89591 22.5009 7.7507C22.5009 7.6055 22.4591 7.46335 22.3805 7.34125C20.1043 3.82562 16.3032 1 11.9841 1Z"
      :stroke="current_color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 11.5C14.0711 11.5 15.75 9.82107 15.75 7.75C15.75 5.67893 14.0711 4 12 4C9.92893 4 8.25 5.67893 8.25 7.75C8.25 9.82107 9.92893 11.5 12 11.5Z"
      :stroke="current_color"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
    hoverColor: {
      type: String,
      default: 'white',
    },
    hovering: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      current_color: this.color,
    }
  },

  watch: {
    color(value) {
      this.current_color = value
    },
    hovering(value) {
      this.current_color = this.color
      if (value) this.current_color = this.hoverColor
    },
  },
}
</script>
