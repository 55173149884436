<template>
  <div>
    <slot></slot>
  </div>
</template>
<script>
import sourcesAndLayers from '@/mixins/map/sourcesAndLayers'
import { mapGetters } from 'vuex'

export default {
  mixins: [sourcesAndLayers],
  props: {
    is_active: {
      type: Boolean,
      require: true,
    },
  },
  computed: {
    ...mapGetters({
      map: 'map/map',
    }),
  },
  watch: {
    is_active(is_3d) {
      if (!is_3d) {
        this.map.easeTo({ pitch: 0 })
        this.map.setPaintProperty(
          this.source_layers_building_3d[1],
          'fill-extrusion-height',
          0,
        )
        this.map.setPaintProperty(
          this.source_layers_batiments[1],
          'fill-extrusion-height',
          0,
        )
        this.map.setPaintProperty(
          this.source_layers_batiments_exterior_pieces[1],
          'fill-extrusion-height',
          0,
        )
      } else {
        this.map.easeTo({ pitch: 50 })
        this.map.setPaintProperty(
          this.source_layers_building_3d[1],
          'fill-extrusion-height',
          ['get', 'HAUTEUR'],
        )
        this.map.setPaintProperty(
          this.source_layers_batiments[1],
          'fill-extrusion-height',
          ['get', 'hauteur'],
        )
        this.map.setPaintProperty(
          this.source_layers_batiments_exterior_pieces[1],
          'fill-extrusion-height',
          ['get', 'HAUTEUR'],
        )
      }
    },
  },
}
</script>
