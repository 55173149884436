<template>
    <svg width="15" height="25" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.3362 7.442C18.3913 7.442 20.0572 5.77605 20.0572 3.721C20.0572 1.66595 18.3913 0 16.3362 0C14.2812 0 12.6152 1.66595 12.6152 3.721C12.6152 5.77605 14.2812 7.442 16.3362 7.442Z"
            :fill="color"
        />
        <path
            d="M28.6459 26.0068H27.58V22.2037C28.2469 21.7785 28.5977 20.9635 28.3812 20.1596C27.0868 15.3444 23.4929 8.59814 18.9734 9.33374C18.6107 9.27224 13.9132 9.29754 13.6994 9.33374C13.5216 9.30474 13.4974 9.30124 13.3342 9.30124C7.3861 9.30124 4.5919 19.0427 4.2917 20.1596C4.2643 20.2613 4.2516 20.363 4.2417 20.4645H2.1221V21.3947H2.5582V23.7202H1.0465C0.4685 23.7202 0 24.1887 0 24.7667V36.5103C0 37.0315 0.3878 37.4446 0.8866 37.5245V38.2254C0.8866 38.5304 1.1339 38.7777 1.4389 38.7777C1.7439 38.7777 1.9912 38.5304 1.9912 38.2254V37.5568H8.0665V38.2254C8.0665 38.5304 8.3138 38.7777 8.6188 38.7777C8.9238 38.7777 9.1711 38.5304 9.1711 38.2254V37.5245C9.6699 37.4447 10.0577 37.0315 10.0577 36.5103V24.7665C10.0577 24.1885 9.5892 23.72 9.0112 23.72H7.4996V21.8227C7.6069 21.695 7.6903 21.5515 7.7612 21.3946H7.9357V20.9497C8.6918 18.2686 10.3678 14.6682 12.1505 13.4495V24.1852L12.1506 38.1378C12.1506 39.1652 12.9836 39.9982 14.011 39.9982C15.0384 39.9982 15.8714 39.1652 15.8714 38.1378V24.1852H16.8016V38.1378C16.8016 39.1652 17.6346 39.9982 18.662 39.9982C19.6894 39.9982 20.5224 39.1652 20.5224 38.1378V22.3247L20.5223 22.3236V13.4495C22.3441 14.6948 24.0586 18.4316 24.7891 21.1279C24.9721 21.8042 25.5106 22.2737 26.1461 22.4304V26.0069H25.0802C24.5237 26.0069 24.0726 26.458 24.0726 27.0145V37.9056C24.0726 38.4621 24.5237 38.9132 25.0802 38.9132H28.6461C29.2026 38.9132 29.6537 38.4621 29.6537 37.9056V27.0144C29.6536 26.4579 29.2024 26.0068 28.6459 26.0068ZM3.343 23.72V21.3945H4.3958C4.6158 21.8882 5.0407 22.286 5.6035 22.4381C5.7656 22.4817 5.9287 22.5026 6.089 22.5026C6.306 22.5026 6.5159 22.4596 6.7148 22.3879V23.7199L3.343 23.72Z"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
