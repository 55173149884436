<template>
  <div
    v-if="position_active.key"
    class="w-full bg-promy-gray-50 p-6 font-main rounded-lg md:text-sm"
  >
    <div class="flex">
      <div class="border border-dashed border-promy-gray-250 my-3 relative">
        <div
          class="w-4 h-4 border-6 bg-white border-promy-green-350 transform rotate-45 rounded-full rounded-br-none absolute -top-3 -left-2"
        />
        <div
          class="w-4 h-4 border-6 bg-white rounded-full absolute -bottom-2 -left-2"
          :style="{ 'border-color': color[position_active.marker] }"
        />
      </div>
      <div class="pl-4">
        <p class="font-semibold text-promy-primary pb-3">
          {{ start_point }}
        </p>
        <div class="grid grid-cols-5 py-1">
          <HumanIcon class="w-3 h-6 -mt-1" />
          <p class="text-promy-gray-250 col-span-2 pr-2">
            {{ (walking.distance / 1000) | roundDouble(2) }}Km
          </p>
          <p class="text-promy-gray-250 col-span-2 pl-2">
            {{ (walking.duration / 60) | roundDouble(2) }}min
          </p>
        </div>
        <div class="grid grid-cols-5 py-1">
          <CarIcon class="my-auto" />
          <p class="text-promy-gray-250 col-span-2 pr-2">
            {{ (driving.distance / 1000) | roundDouble(2) }}Km
          </p>
          <p class="text-promy-gray-250 col-span-2 pl-2">
            {{ (driving.duration / 60) | roundDouble(2) }}min
          </p>
        </div>
        <p class="font-semibold text-promy-primary pt-3">
          {{
            parseStringtoArray(position_active.subCat)
              .toString()
              .replace(',', ', ') | truncate(24, '...')
          }}
        </p>
      </div>
    </div>
    <div class="pl-6 pt-2">
      <p
        class="text-promy-gray-250"
        v-for="(label, idx) in parseStringtoArray(position_active.label)"
        :key="idx"
      >
        {{ label }}
      </p>
    </div>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers'
export default {
  mixins: [helpers],
  props: {
    driving: {
      type: Object,
      required: true,
    },
    walking: {
      type: Object,
      required: true,
    },
    position_active: {
      type: Object,
      required: true,
    },
    start_point: {
      type: String,
      default: 'Parcelle',
    },
  },

  data() {
    return {
      color: {
        commerce: '#EB421D',
        education: '#1DEBEB',
        sante: '#9DEB1D',
        securite: '#CE1DEB',
        transport: '#0545EB',
      },
    }
  },
}
</script>

<style></style>
