export default {
  data() {
    return {
      source_layers_selected_parcelles_autour: [
        'promy-parcelles_autour',
        'promy-background-parcelle-autour',
        'promy-parcelles_autour',
      ],
      source_and_layers_parcelles_voisines: [
        'promy-selected-voisines',
        'promy-background-selected-voisines',
        'promy-outline-selected-voisines',
      ],
      source_layers_parcelle_principale: [
        'promy-parcelle-principale',
        'promy-background-parcelle-principale',
        'promy-outline-parcelle-principale',
      ],
      layer_background_parcelle_autour: 'promy-background-parcelle-autour',
      source_and_layers_cadastre: [
        'promy-wms-cadastre-officiel',
        'promy-wms-layer-cadastre-officiel',
      ],
      source_layers_all_parcelle: [
        'promy-all_parcelle',
        'promy-background-all-parcelle',
        'promy-layer-outline-all-parcelle',
      ],
      source_layers_all_parcelles_selected: [
        'promy-all_parcelles-selected',
        'promy-background-all-parcelles-selected',
        'promy-layer-outline-all-parcells-selected',
      ],
      source_layers_batisExteriorPieces: [
        'promy-batisExteriorPieces',
        'promy-background_batisExteriorPieces',
        'promy-layer-outline-batisExteriorPieces',
      ],
      source_layers_building_3d: [
        'promy-building-3d',
        'promy-layer-building-3d',
        'promy-outline-building-3d',
      ],
      source_layers_batiments: [
        'promy-batiments',
        'promy-background_batiments',
        'promy-layer-outline-bati',
      ],
      source_and_layers_urbanisme: [
        'promy-urbanisme',
        'promy-background_urbanisme',
        'promy-layer-outline-urbanisme',
      ],
      source_and_layers_route_driving: [
        'promy-route_driving',
        'promy-background_route_driving',
        'promy-layer-outline-route_driving',
      ],
      source_and_layers_route_walking: [
        'promy-route_walking',
        'promy-background_route_walking',
        'promy-layer-outline-route_walking',
      ],
      source_and_layers_cluster: [
        'promy-cluster',
        'promy-background_cluster',
        'promy-background_cluster',
      ],
      source_layers_batiments_exterior_pieces: [
        'promy-batiments_exterior_pieces',
        'promy-background_batiments_exterior_pieces',
        'promy-layer-outline-batiments_exterior_pieces',
      ],
      source_and_layers_libelle_urbanisme_autour: [
        'promy-libelle-urbanisme-autour',
        'promy-background-libelle-urbanisme-autour',
        'promy-layer-outline-libelle-urbanisme-autour',
      ],
      source_and_layers_geo_parcelles:[
        'promy-geo_parcelles',
        'promy-background-geo_parcelles',
        'promy-layer-outline-geo_parcelle',
      ]
    }
  },

  methods: {
    getAllLayers() {
      return [
        ...this.source_layers_selected_parcelles_autour,
        ...this.source_and_layers_parcelles_voisines,
        ...this.source_layers_parcelle_principale,
        this.layer_background_parcelle_autour,
        ...this.source_and_layers_cadastre,
        ...this.source_layers_all_parcelle,
        ...this.source_layers_batisExteriorPieces,
        ...this.source_layers_building_3d,
        ...this.source_layers_batiments,
        ...this.source_and_layers_urbanisme,
        ...this.source_and_layers_route_driving,
        ...this.source_and_layers_route_walking,
        ...this.source_and_layers_cluster,
        ...this.source_layers_batiments_exterior_pieces,
        ...this.source_layers_all_parcelles_selected,
        ...this.source_and_layers_libelle_urbanisme_autour,
        ...this.source_and_layers_geo_parcelles
      ]
    },
  },
}
