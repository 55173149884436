<template>
  <div class="flex justify-end">
    <svg
      width="262"
      height="173"
      viewBox="0 0 166 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="82.9596"
        cy="83.316"
        r="82.482"
        fill="#39B7ED"
        fill-opacity="0.12"
      />
      <circle
        cx="82.9599"
        cy="82.3681"
        r="34.4228"
        stroke="#10AAAF"
        stroke-width="7"
      />
      <path
        d="M69.6875 92.1074C69.6875 89.346 71.9261 87.1074 74.6875 87.1074H91.2334C93.9949 87.1074 96.2334 89.346 96.2334 92.1074V116.498H69.6875V92.1074Z"
        fill="#10AAAF"
      />
      <path
        d="M72.5312 81.5762C72.5312 79.9193 73.8744 78.5762 75.5312 78.5762H90.3888C92.0456 78.5762 93.3888 79.9193 93.3888 81.5762V86.1607H72.5312V81.5762Z"
        fill="#10AAAF"
      />
      <rect
        x="81.9072"
        y="66.0137"
        width="1.15875"
        height="11.5875"
        fill="#10AAAF"
      />
      <circle cx="82.4865" cy="64.8547" r="0.948437" stroke="#10AAAF" />
      <rect
        x="85.6729"
        y="69.4922"
        width="1.15875"
        height="8.11125"
        fill="#10AAAF"
      />
      <rect
        x="89.5967"
        y="71.0078"
        width="0.948069"
        height="6.63648"
        fill="#10AAAF"
      />
      <rect
        x="74.4277"
        y="71.0078"
        width="0.948069"
        height="6.63648"
        fill="#10AAAF"
      />
      <circle cx="86.2522" cy="68.3332" r="0.948437" stroke="#10AAAF" />
      <circle cx="90.0969" cy="70.5617" r="0.948437" stroke="#10AAAF" />
      <circle cx="74.9279" cy="70.5617" r="0.948437" stroke="#10AAAF" />
      <rect
        x="78.1406"
        y="69.4902"
        width="1.15875"
        height="8.11125"
        fill="#10AAAF"
      />
      <circle cx="78.7199" cy="68.3313" r="0.948437" stroke="#10AAAF" />
    </svg>
  </div>
</template>

<script>
export default {}
</script>
