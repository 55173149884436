export default {
  namespaced: true,
  state: {
    activated: false,
  },
  getters: {
    activated_sibeBar(state) {
      return state.activated
    },
  },
  mutations: {
    SET_ACTIVATED_SIDEBAR(state, value) {
      state.activated = value
    },
  },
}
