var convert = require('xml-js')

export default {
  data() {
    return {}
  },
  methods: {
    parseStringtoArray(value) {
      return this.uniqueValues([...JSON.parse(value)])
    },
    uniqueValues(value) {
      return [...new Set(value)]
    },
    getNestedObject(obj, ...args) {
      return args.reduce((obj, level) => obj && obj[level], obj)
    },
    isExist(valeur) {
      return typeof valeur !== 'undefined' && valeur !== null
    },
    round(value) {
      return Math.round(value)
    },
    notEmptyObject(someObject) {
      return someObject == null || !Object.keys(someObject).length
        ? false
        : true
    },
    xmlToJson(data_xml) {
      let xmlDoc = data_xml
      let data = convert.xml2json(xmlDoc, {
        compact: true,
        spaces: 4,
      })
      let data_json = JSON.parse(data)
      return data_json
    },
    getCoordinates(coordinates) {
      var new_coordinates = []
      if (coordinates) {
        coordinates.forEach(function iter(element) {
          if (Array.isArray(element[0][0])) {
            element.forEach(iter)
          } else {
            new_coordinates.push(element)
            return
          }
        })
      }

      return new_coordinates
    },
    formatEuro(value) {
      const euroformatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })

      return value ? euroformatter.format(Math.round(value)) : 'N.C'
    },
    calculatePriceByM2(item) {
      return item.surface_reelle_bati
        ? `${this.formatEuro(
            this.round(item.valeur_fonciere) /
              parseInt(item.surface_reelle_bati),
          )}/m²`
        : ''
    },
    formatNumberFr(value, withFractions = false) {
      let options = withFractions
        ? { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        : {}
      const frFormatter = new Intl.NumberFormat('fr-FR', options)

      return frFormatter.format(value).replace(/\s/g, '  ')
    },
    removeDuplicateFeature(features) {
      let remove_index_feature = []
      features.forEach((feature, index) => {
        features.forEach((temp_feature, child_index) => {
          if (
            _.isEqual(
              feature.geometry.coordinates,
              temp_feature.geometry.coordinates,
            ) &&
            index !== child_index &&
            remove_index_feature.indexOf(index) === -1
          ) {
            remove_index_feature.push(child_index)
          }
        })
      })
      features = features.filter((item, index) => {
        return remove_index_feature.indexOf(index) === -1
      })
      return features
    },
    convertAttributesToLowerCase(obj) {
      const newObj = {}
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[key.toLowerCase()] = obj[key]
        }
      }
      return newObj
    },
  },
}
