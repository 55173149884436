<template>
  <svg
    width="23"
    height="27"
    viewBox="0 0 23 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.6086 4.66944C21.1627 4.86345 21.533 5.38588 21.533 5.97298V14.5614C21.533 16.9618 20.6593 19.2532 19.1199 21.0284C18.3438 21.9224 17.3649 22.6185 16.3239 23.1815L11.8122 25.6187L7.29288 23.1803C6.24929 22.6173 5.26909 21.9224 4.49305 21.0271C2.95112 19.2519 2.07617 16.9593 2.07617 14.5563V5.97298C2.07617 5.38588 2.44644 4.86345 3.00057 4.66944L11.3468 1.73646C11.6435 1.63248 11.9669 1.63248 12.2623 1.73646L20.6086 4.66944Z"
      :stroke="color"
      stroke-width="2.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>

<style></style>
