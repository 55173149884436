import Vue from 'vue'
import DatePicker from 'vue2-datepicker'

import 'vue2-datepicker/locale/fr'

DatePicker.methods.displayPopup = function () {
  this.position = {
    left: 0,
    top: '100%',
  }
}
DatePicker.methods.updateNow = function () {
  this.now = this.value ? new Date(this.value) : new Date() // when the value is empty, default is new Date()
}

Vue.component('datepicker', DatePicker)
