const layoutGU = () => import(/* webpackChunkName: '' */ '../layouts/layoutGU')
import FAQ from './pages/faq'

import Guest from './pages/guest'
import Tunnel from './pages/tunnel'

import Profile from './pages/profile'
import sectionsGU from './pages/sectionsGU'
import Revelation from './pages/revelation'
import acceptCGU from './pages/acceptCGU'

export default [
  ...Guest,
  ...acceptCGU,
  ...Tunnel,
  {
    path: '/',
    name: 'home',
    component: layoutGU,
    redirect: '/sections-gu/adresse/',
    children: [...Profile, ...sectionsGU, ...FAQ, ...Revelation],
  },
]
