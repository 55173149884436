export default function log({ next, store, router, to }) {
  if (to.meta.guestModeActivated && !store.getters['auth/isLoggedIn']) {
    return next()
  }
  if (
    store.getters['auth/user'].email_verified_at &&
    (!store.getters['auth/cgu'].is_validated_cgu ||
      !store.getters['auth/cgu'].validated_cgu_at)
  ) {
    return router.push({
      name: 'accept_CGU',
    })
  }
  return next()
}
