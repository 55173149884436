<template>
  <Popup v-show="showGuestModal" @close="toggleGuestModal" max_width_model="sm:w-full  w-[580px]" max_height_body="h-full"
    :is_btn_close_outside_container="true" padding_popup="p-7">
    <template #title>
      <div class="flex">
        <ProIcon name="login" height="34px" width="27px" />
        <h6 class="ml-3 text-2xl sm:text-base font-medium text-promy-green-350">
          Cet accès nécessite d'être connecté !
        </h6>
      </div>
    </template>
    <template #body>
      <div class="flex flex-col px-10 mt-3 sm:px-0">
        <div class="flex flex-col" v-if="!is_code_sended">
          <p class="text-promy-gray-300 sm:text-sm">
            Pour accéder aux informations personnelles de votre parcelle, merci
            de renseigner votre téléphone portable afin de vous envoyer le code
            d’activation
          </p>
          <div class="flex flex-col pr-4">
            <span v-if="errors.rate_limit" class="rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2">{{
              errors.rate_limit }}</span>
            <div class="flex flex-col py-4 space-y-2">
              <div>
                <pro-input class="sm:text-sm" label="Prénom et nom " placeholder=" " :rules="'required'"
                  v-model="form.full_name" type="text" />
                <span v-if="errors.full_name && Array.isArray(errors.full_name)"
                  class="rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2">{{ errors.full_name[0]
                  }}</span>
              </div>

              <pro-input-tel label="Mobile" :rules="'required'" v-model="form.telephone_mobile"
                @is_valid="checkTelValidation" />
              <span v-if="errors.telephone_mobile &&
                Array.isArray(errors.telephone_mobile)
                " class="rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2">Le numéro de téléphone
                doit être unique</span>
            </div>
            <AcceptCGU class="mt-2 mb-3" @isAccept="(value) => {
              form.is_cgu_accepted = value
            }
              "></AcceptCGU>
            <span v-if="errors.is_cgu_accepted && Array.isArray(errors.is_cgu_accepted)
              " class="rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2">Champs obligatoire</span>
            <div class="flex mt-2 space-x-4 sm:flex-col sm:space-x-0 sm:space-y-4">
              <pro-button
                class="flex-1 sm:w-full py-4 rounded-lg border-none h-14 md:h-12 sm:h-12 xl:text-base sm:text-sm"
                btn="primary" type="button" @click="registration"
                :disabled="is_tel_valid && form.is_cgu_accepted ? false : true">
                <span>Connexion</span>
              </pro-button>
              <pro-button class="w-64 sm:w-full py-4 rounded-lg border-none h-14 md:h-12 sm:h-12 xl:text-base sm:text-sm"
                btn="secondary" type="button" @click="
                  $router.push({ name: 'Login', query: $route.query })
                toggleGuestModal()
                  ">
                <svg class="w-5 h-5 sm:w-3 sm:h-3" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18.9999 6.99997H4.41394L9.70694 1.70697L8.29294 0.292969L0.585938 7.99997L8.29294 15.707L9.70694 14.293L4.41394 8.99997H18.9999V6.99997Z"
                    fill="#10AAAF" />
                </svg>
                <span>Retournez à la connexion</span>
              </pro-button>
            </div>
          </div>
        </div>

        <div class="flex flex-col pr-4 mt-8" v-else>
          <span v-if="errors.rate_limit" class="rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2">{{
            errors.rate_limit }}</span>
          <span class="flex justify-between font-medium text-promy-gray-200 mb-2">
            Veuillez insérer le code envoyé par sms :</span>
          <div class="flex space-x-3">
            <pro-input class="sm:text-sm mb-6" label="" placeholder=" " :rules="'required'" v-model="form.code"
              type="text" />
            <a class="py-3 mt-[-4px] text-promy-green-350 cursor-pointer underline" style="height: fit-content"
              btn="primary" @click.prevent="registration">
              Renvoyez
            </a>
          </div>

          <div class="flex mt-2 space-x-4 sm:flex-col sm:space-x-0 sm:space-y-2">
            <pro-button class="flex-1 sm:w-full py-4 rounded-lg border-none h-14 md:h-12 sm:h-12 xl:text-base"
              btn="primary" type="button" @click="validatedCode" :disabled="form.code ? false : true">
              <span>Validez</span>
            </pro-button>
            <pro-button class="w-64 sm:w-full py-4 rounded-lg border-none h-14 md:h-12 sm:h-12 xl:text-base"
              btn="secondary" type="button" @click="is_code_sended = false">
              <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.9999 6.99997H4.41394L9.70694 1.70697L8.29294 0.292969L0.585938 7.99997L8.29294 15.707L9.70694 14.293L4.41394 8.99997H18.9999V6.99997Z"
                  fill="#10AAAF" />
              </svg>
              <span>Retournez à l'inscription</span>
            </pro-button>
          </div>
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      form: {
        full_name: null,
        telephone_mobile: null,
        is_cgu_accepted: false,
        code: null,
        is_register: true,
      },
      is_tel_valid: false,
      errors: {},
      is_code_sended: false,
      classes: [
        'text-promy-green-350',
        'mr-2',
        'px-3',
        'py-2',
        'z-[90]',
        'rounded-box',
        'shadow',
        'text-xs',
        'bg-white',
        'mb-4',
      ],
    }
  },
  computed: {
    ...mapGetters({
      showGuestModal: 'auth/showGuestModal',
      addressStore: 'address/address',
    }),
  },
  methods: {
    ...mapMutations({
      toggleGuestModal: 'auth/TOGGLE_GUEST_MODAL',
    }),
    async validatedCode() {
      await axios.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`)
      await this.$http.post('grand-publics/telephone/verify', this.form)
      await this.$store.dispatch('auth/me')
      this.$store.commit('auth/SET_LOGGEDIN', true)
      this.toggleGuestModal(false)

      let initialeQueryParams = this.addressStore?.label
        ? {
          lat: this.addressStore.lat,
          lon: this.addressStore.lon,
          adress: this.addressStore.label,
        }
        : this.$route.query
      this.$store.commit('address/SET_SHOW_POPUP_ETRE_RAPPELE', false)
      this.$router.push({ name: 'home', query: initialeQueryParams })
    },

    async registration() {
      await axios.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`)
      this.$http
        .post('grand-publics/telephone/send', this.form)
        .then((res) => {
          this.is_code_sended = true
        })
        .catch((error) => {
          if ([422, 429].includes(error.response.status)) {
            this.errors = error.response.data.errors
          }
        })
    },
    redirectSignup() {
      let initialeQueryParams = this.addressStore?.label
        ? {
          lat: this.addressStore.lat,
          lon: this.addressStore.lon,
          adress: this.addressStore.label,
        }
        : this.$route.query
      this.$router.push({ name: 'Signup', query: initialeQueryParams })
      this.toggleGuestModal(false)
    },
    checkTelValidation(is_valid) {
      this.is_tel_valid = is_valid
    },
  },
}
</script>
