<template>
<div class="flex flex-col absolute bottom-12 right-12 space-y-2 z-10  sm:right-4">
<button @click="map.zoomIn()" class="   cursor-pointer bg-white hover:bg-promy-green-350 justify-center items-end rounded-full h-10 w-10 hover:text-white text-promy-green-350 " > <ProIcon    name="plus"  />  </button>
<button @click="map.zoomOut()" class="  cursor-pointer bg-white hover:bg-promy-green-350 justify-center items-end rounded-full h-10 w-10 hover:text-white text-promy-green-350" > <ProIcon   name="minus"  />  </button>
</div>
 
</template>

<script>

import Mapbox from '@/mixins/map/mapbox'
import { mapGetters, mapMutations } from 'vuex'

export default {
  mixins: [Mapbox],
    computed: {
    ...mapGetters({

      map: 'map/map',

    }),
  },
}
  

</script>
