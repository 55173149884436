function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
import auth from '../../middleware/auth'

export default [
  {
    path: '/accept-CGU',
    name: 'accept_CGU',
    component: page('CGU.vue'),
    meta: {
      middleware: [auth],
    },
  },
]
