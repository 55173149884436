<template>
  <div class="bg-white rounded-lg px-5 py-4">
    <p class="font-bold text-base text-promy-green-350">{{ typeText }}</p>
    <div class="mt-6 flex justify-center mb-5">
      <ReseauxContent :type="type" :reseauxDegree="reseauxDegree" />
    </div>
    <p class="font-bold text-base text-promy-gray-300 text-center mb-7">
      {{ status }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    reseauxDegree: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ReseauxIcon() {
      return `Reseaux_${this.type}`
    },
    typeText() {
      switch (this.type) {
        case '4G':
          return 'Mobile 4G'
        case '3G':
          return 'Mobile 3G'

        case 'Fibre':
          return 'Fibre'

        default:
          return 'Internet haut débit '
      }
    },
  },
}
</script>
