<template>
  <div class="font-main">
    <portal to="navbar-title">
      <h2 class="text-2xl xl:text-xl sm:text-base capitalize">Compte</h2>
    </portal>
    <BackToProfileButton
      classes="mb-5"
      type="fake"
      @resetComponent="resetComponent"
    />
    <div class="flex justify-between space-x-6">
      <div class="w-full py-10 px-7 bg-white">
        <component
          @profileInfo="getUpdatedProfileData"
          @passwordInfo="getUpdatedPasswordData"
          :is="!updating_password ? 'UpdateProfileInfo' : 'UpdatePassword'"
        />
        <div class="flex sm:flex-col space-x-6 sm:space-x-0 sm:space-y-3 mt-12">
          <!-- (updating_password && !is_new_password_valid) -->
          <pro-button
            :disabled="
              (!isProfileInfoChanged && !updating_password) ||
              (updating_password && !is_new_password_valid)
            "
            @click="handleClick"
            class="
              px-6
              flex
              justify-center
              font-semibold
              py-3
              border-promy-green-350
              rounded-lg
            "
            btn="primary"
          >
            Sauvegardez
          </pro-button>
          <pro-button
            v-if="!updating_password"
            @click.native="updating_password = true"
            class="
              px-6
              flex
              justify-center
              font-semibold
              py-3
              border-promy-green-350
              rounded-lg
            "
            btn="secondary"
          >
            Modifiez le mot de passe
          </pro-button>
          <pro-button
            v-else
            @click.native="updating_password = false"
            class="
              px-6
              flex
              justify-center
              font-semibold
              py-3
              border-promy-green-350
              rounded-lg
            "
            btn="secondary"
          >
            Modifiez vos informations
          </pro-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      updating_password: false,
      updateProfileData: {},
      updatePasswordData: {},
      is_new_password_valid: false,
    }
  },
  methods: {
    getUpdatedProfileData(data) {
      this.updateProfileData = data
    },
    getUpdatedPasswordData(data) {
      this.is_new_password_valid = data.is_password_valid
      this.updatePasswordData = data
    },
    handleClick() {
      !this.updating_password ? this.updateProfile() : this.updatePassword()
    },
    updateProfile() {
      this.$http
        .post(
          'grand-publics/update-profile',
          this.updateProfileData,
        )
        .then(
          ({ data: { data } }) => {
            this.$store.commit('auth/SET_USER', data)
            this.toast(
              'Données de profil',
              'Vos modifications ont été appliquées avec succès',
              'info',
            )
            this.headToProfileInfo()
          },
          (error) => {
            if (error.response.status === 422) {
              let errResp = error.response.data.errors
                ? Object.values(error.response.data.errors).shift().shift()
                : 'Veuillez remplir tous les champs'

              this.toast('Données de profil', errResp, 'warning')
            }
          },
        )
    },
    updatePassword() {
      this.$http
        .post(
          'grand-publics/update-password',
          _.omit(this.updatePasswordData, ['is_password_valid']),
        )
        .then(({ data }) => {
          if (data.is_password_changed) {
            this.toast('Données de profil', data.message, 'success')
            this.updating_password = !this.updating_password
            this.headToProfileInfo()
            return
          }
          this.toast('Données de profil', data.message, 'error')
        })
    },
    headToProfileInfo() {
      this.$emit('enableUpdatingProfile', false)
    },
    resetComponent() {
      this.$emit('enableUpdatingProfile')
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    isProfileInfoChanged() {
      const profileInfo = _.omit(_.clone(this.user), [
        'has_access',
        'identifiant_client',
      ])

      return !_.isEqual(profileInfo, this.updateProfileData)
    },
  },
}
</script>

<style></style>
