<template>
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0001 3.375V3C17.0001 1.89543 16.1047 1 15.0001 1V1C13.8956 1 13.0001 1.89543 13.0001 3V3.375M17.0001 21.0938V22C17.0001 23.1046 16.1047 24 15.0001 24V24C13.8956 24 13.0001 23.1046 13.0001 22V21.0938M17.0001 13.0781V11.8125M13.0001 13.0781V11.8125"
      :stroke="current_color"
      stroke-width="1.24444"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.55566 10.389V5.05564C4.55566 4.56472 4.95363 4.16675 5.44455 4.16675H22.3796C22.6931 4.16675 22.9834 4.33183 23.1436 4.60122L24.7298 7.26788C24.8964 7.54793 24.8964 7.89668 24.7298 8.17672L23.1436 10.8434C22.9834 11.1128 22.6931 11.2779 22.3796 11.2779H5.44455C4.95363 11.2779 4.55566 10.8799 4.55566 10.389Z"
      :stroke="current_color"
      stroke-width="1.24444"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.4445 19.2779V13.9446C21.4445 13.4536 21.0465 13.0557 20.5556 13.0557H3.62048C3.30703 13.0557 3.01676 13.2207 2.85652 13.4901L1.27032 16.1568C1.10373 16.4368 1.10373 16.7856 1.27032 17.0656L2.85652 19.7323C3.01676 20.0017 3.30703 20.1668 3.62048 20.1668H20.5556C21.0465 20.1668 21.4445 19.7688 21.4445 19.2779Z"
      :stroke="current_color"
      stroke-width="1.24444"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
    hoverColor: {
      type: String,
      default: 'white',
    },
    hovering: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      current_color: this.color,
    }
  },

  watch: {
    color(value) {
      this.current_color = value
    },
    hovering(value) {
      this.current_color = this.color
      if (value) this.current_color = this.hoverColor
    },
  },
}
</script>
