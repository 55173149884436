<template>
  <div
    class="w-full h-full bg-promy-gray-100 flex flex-col items-center justify-center sm:space-y-8 space-y-6"
  >
    <ProIcon :name="main_icon" :class="classes_icon" />
    <p
      class="sm:text-sm text-xl w-1/4 sm:w-3/4 text-center font-bold text-promy-primary"
      :class="message_classes"
    >
      {{ message }}
    </p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    main_icon: {
      type: String,
      default: 'bot1',
    },
    classes_icon: {
      type: String,
      default: '!w-1/4 !h-1/4',
    },
    message_classes: {
      type: String,
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
