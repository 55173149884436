<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.32601 0.203489C2.95006 -0.0191375 3.62449 -0.060056 4.27087 0.0854901C4.91726 0.231036 5.50905 0.557068 5.97746 1.02568C6.44586 1.49429 6.77163 2.08623 6.91688 2.73269C7.06214 3.37914 7.02092 4.05354 6.79802 4.67749L17.643 15.5225L15.521 17.6435L4.67601 6.79949C4.05197 7.02211 3.37754 7.06303 2.73116 6.91749C2.08477 6.77194 1.49298 6.44591 1.02457 5.9773C0.556171 5.50869 0.230404 4.91674 0.0851467 4.27029C-0.0601104 3.62384 -0.0188904 2.94944 0.204015 2.32549L2.44001 4.56249C2.57838 4.70575 2.7439 4.82003 2.92691 4.89864C3.10991 4.97725 3.30674 5.01863 3.50591 5.02036C3.70508 5.0221 3.9026 4.98414 4.08695 4.90872C4.27129 4.8333 4.43877 4.72192 4.57961 4.58108C4.72045 4.44024 4.83183 4.27276 4.90725 4.08842C4.98267 3.90408 5.02062 3.70656 5.01889 3.50739C5.01716 3.30822 4.97578 3.11139 4.89717 2.92838C4.81855 2.74538 4.70428 2.57986 4.56102 2.44149L2.32501 0.202489L2.32601 0.203489ZM12.693 2.08749L15.875 0.319489L17.289 1.73349L15.521 4.91549L13.753 5.26949L11.633 7.39049L10.218 5.97649L12.339 3.85549L12.693 2.08749ZM5.62202 9.86549L7.74301 11.9875L2.79301 16.9375C2.52005 17.2079 2.15373 17.3632 1.7696 17.3715C1.38546 17.3798 1.0128 17.2403 0.728468 16.9819C0.444136 16.7235 0.269812 16.3658 0.241446 15.9826C0.21308 15.5994 0.332835 15.22 0.576015 14.9225L0.673015 14.8155L5.62301 9.86549H5.62202Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
