<template>
  <div>
    <Popup v-show="showModal" @close="showModal = false">
      <h2 class="text-2xl sm:text-lg font-bold py-2 font-main" slot="title">
        Conditions générales d’utilisation
      </h2>
      <template #body>
        <div
          class="overflow-y-auto max-h-[50vh] md:max-h-[40vh] scrollbar-thin scrollbar-thumb-promy-gray-180 scrollbar-track-promy-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full pr-8"
          v-html="cgu.data.context"
        ></div>
      </template>
    </Popup>
    <div class="flex space-x-2 items-center">
      <ProCheckbox
        staticLabel
        :val="is_accepted"
        id="checkbox_cgu"
        v-model="is_accepted"
      />
      <label class="text-sm text-promy-blue"
        >J’ai lu et j’accepte
        <span
          class="text-promy-green-350 cursor-pointer"
          @click="showModal = true"
        >
          les conditions générales d’utilisation
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      cgu: 'auth/cgu',
    }),
  },
  data() {
    return {
      is_accepted: false,
      showModal: false,
    }
  },
  watch: {
    is_accepted() {
      this.$emit('isAccept', this.is_accepted)
    },
  },
}
</script>
